import React from 'react';
// reactstrap components
import { Card, CardBody, Row, Col } from 'reactstrap';

class Comment extends React.Component {
  render() {
    const { comment } = this.props;
    return (
      <Card>
        <CardBody>
          <Row className="align-items-center">
            <div className="col ml--2">
              <h4 className="mb-0">
                {comment.userId && comment.userId.name ? (
                  <a href="/" onClick={e => e.preventDefault()}>
                    {comment.userId.name}
                  </a>
                ) : (
                  'User was deleted'
                )}
              </h4>
              <p className="text-sm text-muted mb-0">{comment.text}</p>
              <small>{comment.createdAt}</small>
            </div>
            <Col className="col-auto">
              {/* <Button color="danger" size="sm" type="button">
                Delete
              </Button> */}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default Comment;
