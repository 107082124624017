import React from "react";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  Card, CardImg, CardBody
} from "reactstrap";

import {
  loggedInUser
} from '../../utils/User'

class AdminNavbar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      profileModal: false
    }
    this.toggleModal = this.toggleModal.bind(this)
    this.logout = this.logout.bind(this)
  }

  toggleModal() {
    const { profileModal } = this.state
    this.setState({
      profileModal: !profileModal
    })
  }

  logout() {
    localStorage.clear()
    this.props.history.push("/")
  }

  render() {

    const ProfileModal = (
      <Modal
        isOpen={this.state.profileModal}
        toggle={e => this.toggleModal()}
      >
        <ModalHeader toggle={e => this.toggleModal()}>My Profile</ModalHeader>
        <ModalBody>
          <div>
            <Card>
              <div style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
              <CardImg style={{ 
                width: '50%',
                marginTop: '5%'
               }} top width="100%" src={require('../../assets/img/theme/user-img.png')} alt="Card image cap" />
              </div>
              <CardBody>
                <div className='row'>
                  <div className='col'>
                    <strong>Name</strong>
                  </div>
                  <div className='col'>
                    <span>{loggedInUser().name}</span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <strong>Email</strong>
                  </div>
                  <div className='col'>
                    <span>{loggedInUser().email}</span>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <strong>Mobile Number</strong>
                  </div>
                  <div className='col'>
                    <span>{loggedInUser().mobileNumber}</span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </ModalBody>
      </Modal>
    )

    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <span className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
              {this.props.brandText}
            </span>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    {/* <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require("assets/img/theme/team-4-800x800.jpg")}
                      />
                    </span> */}
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        Welcome, {
                          localStorage.getItem('user')
                          && JSON.parse(localStorage.getItem('user'))
                          && JSON.parse(localStorage.getItem('user')).name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem onClick={this.toggleModal}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="#pablo" onClick={e => localStorage.clear()}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
        {ProfileModal}
      </>
    );
  }
}

export default AdminNavbar;
