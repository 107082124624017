export const godsToDaysMap = {
  monday: [
    'Shiva',
    'Kala bairava'
  ],
  tuesday: [
    'Ganesh',
    'Kartikeya',
    'Subramanya'
  ],
  wednesday: [
    'Krishna',
    'Rama',
    'Ayyappa',
    'Tulasi'
  ],
  thursday: [
    'Sai Baba',
    'Vishnu',
    'Ragavendraswamy',
    'Dakshinamurty'
  ],
  friday: [
    'Laxmi',
    'Saraswati',
    'Kali Mata',
    'Durga',
    'Annapurnadevi',
    'Parvathi'
  ],
  saturday: [
    'Hanuman',
    'Shani Dev',
    'Balaji',
    'Narasimha swamy'
  ],
  sunday: [
    'Aditya',
    'Surya Bhagavan',
    'Common'
  ]
}