import React from 'react';
import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row
} from 'reactstrap';
import axios from 'axios';

import { AUTH_TOKEN } from '../../config'

const NotificationOptions = {
  place: "tr",
  message: (
    <div className="alert-text">
      <span data-notify="message">
        Invalid Email or Password
      </span>
    </div>
  ),
  type: 'warning',
  icon: "ni ni-bell-55",
  autoDismiss: 7
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async handleSubmit() {
    try {
      const { email, password } = this.state;
      this.setState({ isLoading: true });
      const currentLoc = this.props.location.pathname;
      const url = currentLoc.includes('superuser') ? '/super/login' : '/admin-user/otplogin';
      const data = {
        mode: 'email',
        email,
        password,
      }
      
      const response = await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        data,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });

      console.log(response);
      //  set loading to false
      this.setState({ isLoading: false });
      if (response.data.status == 200) {
        currentLoc.includes('superuser')
          ? this.props.history.push('/auth/superuser/verifyotp')
          : this.props.history.push(`/auth/verifyotp?email=${email}`);

      } else {
        this.refs.notificationAlert.notificationAlert(NotificationOptions);
      }
    } catch (error) {
      console.log(error);
      this.refs.notificationAlert.notificationAlert(NotificationOptions);
    }
  }

  render() {
    const { email, password } = this.state;
    console.log("sign IN", "Hereer");
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Sign in</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.handleChange}
                      value={email}
                      placeholder="Email"
                      type="email"
                      name="email"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.handleChange}
                      value={password}
                      placeholder="Password"
                      type="password"
                      name="password"
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={this.handleSubmit}
                  >
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <button
                className="text-light"
                style={{
                  cursor: 'pointer',
                  background: 'none',
                  border: 'none'
                }}
                onClick={() => this.props.history.push("/auth/forgot-password")}
              >
                <small>Forgot password?</small>
              </button>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default Login;
