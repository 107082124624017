import React from 'react';
import axios from 'axios';
import qs from 'qs';
import lodash from 'lodash';
import moment from 'moment';
import { Field, Form, Formik } from 'formik';

// reactstrap components
import {
  Container, Row, Col,
  Button,
  Card, CardHeader, CardBody, CardFooter,
  FormGroup, Form as FormB,
  Input, InputGroup, InputGroupAddon, InputGroupText,
  Modal, ModalBody, ModalFooter, ModalHeader,
  UncontrolledCollapse,
} from 'reactstrap';

import { Scrollbars } from 'react-custom-scrollbars';
import NotificationAlert from "react-notification-alert";
import paginationFactory,
{
  PaginationProvider, PaginationListStandalone
} from "react-bootstrap-table2-paginator";

import BootstrapTable from "react-bootstrap-table-next";

import { cleanObject } from 'utils';
// core components
import DateRangePicker from 'components/Formik/DateRangePicker';
import columns from './column';
import { AUTH_TOKEN } from '../../config'

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
const dateToAge = (date) => {
  var replaceStr = date.replace("-",",").replace("-",",")
  var splitSrt = replaceStr.split(",")
  var dateFromArray = ''+splitSrt[1]+'/'+splitSrt[0]+'/'+splitSrt[2]
  var dob = new Date(dateFromArray);
  //calculate month difference from current date in time
  var month_diff = Date.now() - dob.getTime();
  
  //convert the calculated difference in date format
  var age_dt = new Date(month_diff); 
  
  //extract year from date    
  var year = age_dt.getUTCFullYear();
  
  //now calculate the age of the user
  var age = Math.abs(year - 1970);
  if(!age){
    return ''
  }
  //display the calculated age
  else {
    return age
  }
  
}
const arrayToCount = (array) =>{
  return array.length
}
class Business extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      formInitialValue: {active: '', language: '', country: '', state: '',approved: '' },
      modal: {},
      filters: {},
      search: '',
      users: [],
      page: 1,
      sizePerPage: 100,
      total: 0,
      sortField: undefined,
      sortOrder: null,
      favTempleList: [],
      postList: [],
      from: '',
      exportvisibility: true,
      filterOn: false,
      isGenderUnknown : false,
      isCountryNone : false,
      allState: []
    };
  }

  componentWillMount() {
    const { location: { search } } = this.props;
    const filters = qs.parse(search, { ignoreQueryPrefix: true });
    if (filters.from) {
      this.setState({
        from: filters.from
      })
      delete filters.from
    }
    this.setState({ filters })
    this.fetchCountryStates()
  }

  async fetchCountryStates(){
    
    try {
      let url = '/c0nstants?type=countryStates';
      const response = await axios({
        method: 'GET',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      this.setState({
        loading: false,
        allState: response.data.constants[0].fields[0].States || [],
        
      });
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  async fetchUsers() {
    // check gender
    if(this.state.filters.gender === "unknown"){
      this.setState({
        isGenderUnknown : true,
        
      })
      this.state.filters.gender = ""
    }
    // check country
    if(this.state.filters.country === "null"){
      this.setState({
        isCountryNone : true,
        
      })
      this.state.filters.country = ""
    }
    try {
      const { search, page, sizePerPage, sortField, sortOrder, filters } = this.state;
      let url = `/store/store-list`;
      let query = { page, sizePerPage };
      if (sortField && sortOrder) { query = { ...query, sortField, sortOrder } }
      const copyOfFilters = JSON.parse(JSON.stringify(filters))
      if (copyOfFilters.createdAt) {
        if (copyOfFilters.createdAt.toDate) {
          const toDate = new Date(copyOfFilters.createdAt.toDate)
          toDate.setDate(toDate.getDate() + 1)
          copyOfFilters.createdAt.toDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`
        } else if (copyOfFilters.createdAt.fromDate && !copyOfFilters.createdAt.toDate) {
          const todayDate = new Date()
          copyOfFilters.createdAt.toDate = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`
        }
      }
      if (copyOfFilters) {
        cleanObject(copyOfFilters);
        query = { ...query, filters: copyOfFilters }
      }
      if (search) { query = { ...query, search } }
      if (this.state.from) {
        query = { ...query, from: this.state.from }
      }
      if (query) {
        const queryString = qs.stringify(query, { skipNulls: false });
        url =`${url}?${queryString}`
      }
      const { data } = await axios({
        method: 'GET',
        url,
        params: {
          apikey: AUTH_TOKEN,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },

      });
      if (data.success) {
      this.setState({
            users: data.stores.data,
            filterOn: true, 
            isCountryNone : false,
            isGenderUnknown : false,
            total: data.stores.pagination.total,
      });
      }
      else {
        this.setState({ users: [], total: 0, });
      }
    } catch (error) {
      this.setState({ error, isLoading: false })
    }
  }

  handleDeleteRecord = async () => {
    try {
      if (!this.state.activeUser || this.state.activeUser === null) return;
      const url = `users/${this.state.activeUser._id}/delete`;
      const response = await axios({
        method: 'DELETE',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.success) {
        this.setState({ modal: false, activeUser: null });
        this.notify('User Deleted')
        this.fetchUsers();
      } else {
        this.notify(response.msg, 'warning');
      }
    } catch (error) { }

  };
  handleStoreApproved = async () => {
    try {
      const url = `store/${this.state.activeUser._id}/approved`;
      const response = await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.status) {
        this.setState({ modal: false, activeUser: null });
        this.notify(response.data.message,'success')
        this.fetchUsers();
      } else {
        this.notify(response.msg, 'warning');
      }
    } catch (error) { }
  }
  handleStoreDisapproved = async () => {
    try {
      const url = `store/${this.state.activeUser._id}/disapproved`;
      const response = await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.status) {
        this.setState({ modal: false, activeUser: null });
        this.notify(response.data.message,'success')
        this.fetchUsers();
      } else {
        this.notify(response.msg, 'warning');
      }
    } catch (error) { }
  }
  disableStore = async (row) => {
    try {
      const url = `store/status/${this.state.activeUser._id}/inactive`;
      const response = await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.status) {
        this.setState({ modal: false, activeUser: null });
        this.notify('Store Inactivate')
        this.fetchUsers();
      } else {
        this.notify(response.msg, 'warning');
      }
    } catch (error) { }
  }

  enableStore = async (row) => {
    try {
      const url = `store/status/${this.state.activeUser._id}/active`;
      const response = await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.status) {
        this.setState({ modal: false, activeUser: null });
        this.notify('Store Activate')
        this.fetchUsers();
      } else {
        this.notify(response.msg, 'success');
      }
    } catch (error) { }
  }

  handleTableChange = (type, { page, sizePerPage, ...rest }) => {

    if (type === 'pagination') {
      this.setState({ sizePerPage, page }, this.fetchUsers)
    }

    if (type === 'sort') {
      this.setState({
        sortOrder: rest.sortOrder,
        sortField: rest.sortField
      }, this.fetchUsers)
    }
  }

  debounceFn = lodash.debounce((searchText) => {
    this.setState({ page: 1, search: searchText }, this.fetchUsers)
  }, 100)

  doSearch = (evt) => {
    const searchText = evt.target.value;
    this.debounceFn(searchText);

  }

  handleChange = event => {
    this.setState({ userType: event.target.value });
  };

  onSubmitFilterForm = (values) => {
    let filters = { ...values };

    // delete filters.userType

    // if (values.userType === 'religious') {
    //   filters['userType.religious'] = true;
    //   filters['userType.spiritual'] = false;
    // }

    // if (values.userType === 'spiritual') {
    //   filters['userType.religious'] = false;
    //   filters['userType.spiritual'] = true;
    // }

    this.setState({ filters }, this.fetchUsers);
  };

  onResetForm = () => {
    this.setState({ filters: {},users: [],search: '',total: 0, page: 1 });
  }

  toggleModal = (modelName, user) => {
    this.setState(prevState => ({
      modal: {
        ...prevState.modal,
        [modelName]: !prevState.modal[modelName]
      },
      activeUser: user,
    }));
  }

  // fetchFavList = async (modelName, user) => {

  //   const url = `/users/${user._id}/fav/list`;

  //   const response = await axios({
  //     method: 'GET',
  //     url,
  //     params: {
  //       apikey: AUTH_TOKEN
  //     },
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       'Authorization': `Bearer ${localStorage.getItem('token')}`
  //     }
  //   });

  //   this.setState(prevState => ({
  //     modal: {
  //       ...prevState.modal,
  //       [modelName]: !prevState.modal[modelName]
  //     },
  //     favTempleList: response.data.favoriteTemples,
  //   }));

  // }

  // fetchUserPostList = async (modelName, user) => {

  //   const url = `/users/${user._id}/posts?by=me`;

  //   const response = await axios({
  //     method: 'GET',
  //     url,
  //     params: {
  //       apikey: AUTH_TOKEN
  //     },
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       'Authorization': `Bearer ${localStorage.getItem('token')}`
  //     }
  //   });

  //   this.setState(prevState => ({
  //     modal: {
  //       ...prevState.modal,
  //       [modelName]: !prevState.modal[modelName]
  //     },
  //     postList: response.data.posts,
  //   }));

  // }

  sendMessage = async (e) => {
    try {
      e.preventDefault();
      const url = '/users/post-feedback/send-message';
      await this.setState({
        messageText: this.state.messageText
      })
    
      const body = {
        mobile: this.state.activeUser.mobileNumber,
        message: this.state.messageText
      }
      await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        data: body,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then((response)=>{
        if(response){
          this.notify('SMS Send successfully.', 'success')
        } else {
          this.notify(response.message, 'danger')
        }
      });
      this.setState({ modal: false, activeUser: null, messageText: '' });
    } catch (error) {

    }
  }

  notify(messageText = 'success', type = 'success', place = 'tr') {
    const options = {
      place,
      type,
      autoDismiss: 7,
      icon: "ni ni-bell-55",
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {messageText}
          </span>
        </div>
      ),
    }
    this.refs.notify.notificationAlert(options);
  };

  tableCallBack = (actionType, ...rest) => {
    switch (actionType) {
      case 'DISABLE':
        this.disableStore(...rest)
        break;
      case 'ENABLE':
        this.enableStore(...rest)
        break;
      case 'MODAL_SHOW':
        this.toggleModal(...rest)
        break;
      default:
        break;
    }
  }

  onExportExcel = async () => {
    if (this.state.filterOn === true) {
      const arrayOfStrings = this.state.users;
      var arrayOfObjects = [];     
      arrayOfStrings.forEach(function (element, index) {
        arrayOfObjects.push({
           Status: element.active === true ? 'Active' : 'Inactive',
           Approved: element.approved === true ? 'Yes' : 'No',
           Date: element.createdAt,
           Language: element.language,
           Name: element?.user[0]?.name, 
           Gender: element?.user[0]?.gender,
           MobileNumber: element?.user[0]?.mobileNumber, 
           Email: element?.user[0]?.email,
          //  Location: element.location,
           City: element.city, 
           State: element.state,
           Country: element.country,
           StoreName: element.name,
           StorePhone: element.phone,
           OwnerName: element.ownerName,
           OwnerPhone: element.ownerPhone,
          });
      });
      const ws = XLSX.utils.json_to_sheet(arrayOfObjects);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "business_stores_" + moment(new Date()).format('ddd, MMMM Do YYYY') + fileExtension);
    } else {
      let url = "users/getusers/all";
      const response = await axios({
        method: "GET",
        url,
        params: {
          apikey: AUTH_TOKEN,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      this.setState({ templesLists: response.data.result.map((row) => row) });
      const arrayOfStrings = this.state.templesLists;
      var arrayOfObjects = [];
      arrayOfStrings.forEach(function (element, index) {
        arrayOfObjects.push({
           RegisteredDate: element.createdAt,
           Name: element.name, 
           MobileNumber: element.mobileNumber, 
           City: element.city, 
           State: element.state, 
           Country: element.country, 
           Email: element.email,
           Language: element.language,
           DOB: element.dob,
           Gender: element.gender,
           Type: element.userType,
           FavTemplesCount: element.favorite_temple,
           UserPostCount: element.user_post,
           Status: element.active,
           Action: element.action
          });
      });
      const ws = XLSX.utils.json_to_sheet(arrayOfObjects);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "fileName" + fileExtension);
      // }
      // this.setState({ filters: {}, isLoading: true }, this.fetchTemples);
    }
  };

  render() {
    const { users } = this.state;
    const paginationOption = {
      custom: true,
      totalSize: this.state.total,
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
    }
    const pagination = paginationFactory(paginationOption);

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notify" />
        </div>

        {/* Page header */}
        <div className="header header-dark bg-info pb-6 pt-5 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card >
                <CardHeader >
                  <Row>
                    <Col md="6">
                      <FormGroup className="mb-0">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Search by store name,store phone,owner name ,owner phone"
                            type="text"
                            value={this.state.search}
                            onChange={evt => this.doSearch(evt)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col className="text-md-right" md={{ offset: 5, size: 1 }}>
                      <Button color="primary" size="sm" id="buttonTogglerFilters">
                        Filters
                      </Button>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col md="12">
                      <UncontrolledCollapse defaultOpen toggler="#buttonTogglerFilters">
                        <Formik
                          initialValues={this.state.formInitialValue}
                          onSubmit={this.onSubmitFilterForm}
                          onReset={this.onResetForm}
                        >
                          {({ handleReset }) => {
                            return (
                              <Form>
                                <Row>
                                <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Language
                                    </label>
                                    <Field className="form-control" as="select" name="language">
                                      <option value="">All</option>
                                      <option value="english">English</option>
                                      <option value="hindi">Hindi</option>
                                      <option value="telugu">Telugu</option>
                                    </Field>
                                </Col>
                                <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      User Status
                                    </label>
                                    <Field className="form-control" as="select" name="active">
                                      <option value="">All</option>
                                      <option value="true">Active</option>
                                      <option value="false">In-Active</option>
                                    </Field>
                                </Col>
                                <Col className="mb-3" md="3">
                                    <label className="form-control-label">
                                      Date
                                    </label>
                                    <Row>
                                      <div className="col  pr-1">
                                        <DateRangePicker
                                          name="createdAt"
                                          DATE_FORMAT='YYYY-MM-DD'
                                          placeholder1="start date"
                                          placeholder2="end date"
                                        />
                                      </div>
                                    </Row>
                                  </Col>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Country
                                  </label>
                                  <Field className="form-control" as="select" name="country">
                                      <option value="">All</option>
                                      <option value="india">India</option>
                                      <option value="other">Other</option>
                                      <option value="null">None</option>
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      State
                                  </label>
                                  <Field className="form-control" as="select" name="state">
                                    <option value=''>All</option>
                                      {this.state.allState?.map((item)=>{
                                        return <option value={item}>{item}</option>
                                      })}
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                    Requested (Apporved)
                                  </label>
                                  <Field className="form-control" as="select" name="approved">
                                      <option value="">All</option>
                                      <option value="true">Yes</option>
                                      <option value="false">No</option>
                                    </Field>
                                  </Col>

                                  <Col className="mb-3 pb-1 align-self-end" md="2" >
                                    <Button type="submit">Go</Button>
                                    <Button onClick={handleReset} >Reset</Button>
                                  </Col>
                                </Row>

                              </Form>
                            );
                          }}
                        </Formik>
                      </UncontrolledCollapse>
                    </Col>
                  </Row>
                </CardHeader>

                <PaginationProvider
                  pagination={pagination}
                >
                  {({
                    paginationProps,
                    paginationTableProps
                  }) => (
                      <>
                        <div className="table-responsive">
                          <Scrollbars style={{ height: '50vh' }}>
                            <BootstrapTable
                              remote
                              keyField='_id'
                              data={users}
                              columns={columns(this.tableCallBack)}
                              bootstrap4={true}
                              bordered={false}
                              onTableChange={this.handleTableChange}
                              noDataIndication="no records"
                              {...paginationTableProps}
                            />
                          </Scrollbars>
                        </div>

                        <CardFooter>
                          <PaginationListStandalone
                            {...paginationProps}
                          />
                          <Row className="mb-3 pb-1 align-self-end" md="2" >
                            { this.state.exportvisibility? <div><Button onClick={this.onExportExcel} type="submit">Export to Excel </Button> <span className={`badge badge-pill badge-dark`} style={{color: '#fff',backgroundColor: '#5e72e4'}}>{`Total Count : ${users.length}`}</span></div> : null}
                          </Row>
                        </CardFooter>
                      </>
                    )
                  }
                </PaginationProvider>
              </Card>
            </div>
          </Row>
        </Container>
        {/* active store */}
        <Modal
          isOpen={this.state.modal['DELETE_MODAL']}
          toggle={e => this.toggleModal('DELETE_MODAL')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('DELETE_MODAL')}>Inactivate Store</ModalHeader>
          <ModalBody>Are you sure, you want to Inactivate the store?</ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.disableStore}>
            Inactivate
          </Button>
            <Button color="secondary" onClick={e => this.toggleModal('DELETE_MODAL')}>
              Cancel
          </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modal['ENABLE_MODAL']}
          toggle={e => this.toggleModal('ENABLE_MODAL')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('ENABLE_MODAL')}>Activate Store</ModalHeader>
          <ModalBody>Are you sure, you want to Activate the store?</ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.enableStore}>
              Activate
          </Button>
            <Button color="secondary" onClick={e => this.toggleModal('ENABLE_MODAL')}>
              Cancel
          </Button>
          </ModalFooter>
        </Modal>
        {/* approved store */}
        <Modal
          isOpen={this.state.modal['DELETE_MODAL_APPROVED']}
          toggle={e => this.toggleModal('DELETE_MODAL_APPROVED')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('DELETE_MODAL_APPROVED')}>Disapprove Store</ModalHeader>
          <ModalBody>Are you sure, you want to Disapprove the store?</ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.handleStoreDisapproved}>
            Disapproved
          </Button>
            <Button color="secondary" onClick={e => this.toggleModal('DELETE_MODAL_APPROVED')}>
              Cancel
          </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal['ENABLE_MODAL_APPROVED']}
          toggle={e => this.toggleModal('ENABLE_MODAL_APPROVED')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('ENABLE_MODAL_APPROVED')}>Approve Store</ModalHeader>
          <ModalBody>Are you sure, you want to Approve the Store?</ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.handleStoreApproved}>
              Approved
          </Button>
            <Button color="secondary" onClick={e => this.toggleModal('ENABLE_MODAL_APPROVED')}>
              Cancel
          </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Business;
