import React from 'react';
import axios from 'axios';

import { AUTH_TOKEN } from '../../config'

// reactstrap components
import {
  Card,
  CardTitle,
  CardText,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem
} from 'reactstrap';

// core components
import NUserHeader from 'components/Headers/NUserHeader.jsx';
import ReadMoreAndLess from 'react-read-more-less';
import moment from 'moment';

const USER_TYPES = ['Spiritual', 'Religious', 'Spiritual & Religious'];
const USER_ROLES = ['devotee', 'templeMember'];

class NProfile extends React.Component {
  //   constructor(props) {
  //     super(props);

  //   }
  state = {
    user: null,
    error: null
  };
  componentDidMount() {
    const url = `/users/userId/${
      this.props.match.params.id
    }?filter=posts,events,templemember,favorites,templeupdates`;
    axios({
      method: 'GET',
      url,
      params: {
        apikey: AUTH_TOKEN
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(({data}) => {
        if (data.user) {
          let user = data.user;
          user.profilePhoto = user.profilePhoto
            ? user.profilePhoto
            : `https://www.gravatar.com/avatar/${user._id}?s=100` ||
              require('assets/img/theme/team-4-800x800.jpg');
          // const age = user.dob? ()

          if (user.userType.spiritual && user.userType.religious) {
            user._userType = USER_TYPES[2];
          } else if (user.userType.spiritual && !user.userType.religious) {
            user._userType = USER_TYPES[0];
          } else if (!user.userType.spiritual && user.userType.religious) {
            user._userType = USER_TYPES[1];
          }
          this.setState({
            user,
            isLoading: false
          });
        }
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }
  render() {
    const { user } = this.state;
    if (user) {
      user.profilePhoto = user.profilePhoto
        ? user.profilePhoto
        : `https://www.gravatar.com/avatar/${user._id}?s=100` ||
          require('assets/img/theme/team-4-800x800.jpg');
      // const age = user.dob? ()

      if (user.userType.spiritual && user.userType.religious) {
        user._userType = USER_TYPES[2];
      } else if (user.userType.spiritual && !user.userType.religious) {
        user._userType = USER_TYPES[0];
      } else if (!user.userType.spiritual && user.userType.religious) {
        user._userType = USER_TYPES[1];
      }
    }
    return (
      <>
        {user && (
          <>
            <NUserHeader user={user} />
            <Container className="mt--7" fluid>
              <Row>
                <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                  <Card className="card-profile shadow">
                    <Row className="justify-content-center">
                      <Col className="order-lg-2" lg="3">
                        <div className="card-profile-image">
                          <a
                            href={user.profilePhoto}
                            // eslint-disable-next-line react/jsx-no-target-blank
                            target="_blank"
                            rel="noopener"
                          >
                            <img
                              alt={user.name}
                              className="rounded-circle"
                              src={user.profilePhoto}
                              width="140"
                              height="140"
                            />
                          </a>
                        </div>
                      </Col>
                    </Row>
                    <CardBody className="pt-0 pt-md-4">
                      <Row>
                        <div className="col">
                          <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                            <div>
                              <span className="heading">
                                {user && user.userPosts && user.userPosts.length}
                              </span>
                              <span className="description">Posts</span>
                            </div>

                            {user &&
                            user.templeMember &&
                            user.templeMember[0] &&
                            user.templeMember[0].isAdmin ? (
                              <div>
                                <span className="heading">
                                  {user && user.templeEvents && user.templeEvents.length}
                                </span>
                                <span className="description">Events</span>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </Row>
                      {user.role === 'templeMember' && user.templeMember &&
                        user.templeMember.length && (
                          <div className="text-center">
                            <div className="h5 text-capitalize">
                              <i className="ni business_briefcase-24 mr-2" />
                              {user.templeMember[0].role}
                            </div>
                            <div>
                              <i className="ni education_hat mr-2" />
                              {user.templeMember[0].templeId.name}
                            </div>
                          </div>
                        )}
                      {user.role !== 'templeMember' && (
                        <div className="text-center">
                          <div className="h2 text-capitalize">
                            <i className="ni business_briefcase-24 mr-2" />
                            Devotee
                          </div>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                  <Card className="mt-2">
                    <CardBody>
                      <p className="mb-2 h3">Favorite Temples</p>
                      <ListGroup className="list h4" flush>
                        {user && user.templeFavorites && user.templeFavorites.map(favTemple => {
                          return (
                            <ListGroupItem className="d-flex justify-content-between align-items-center">
                              {favTemple.templeId.name}
                            </ListGroupItem>
                          );
                        })}
                      </ListGroup>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="order-xl-1" xl="8">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="8">
                          <h3 className="mb-0">My account</h3>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Form>
                        <h6 className="heading-small text-muted mb-4">
                          User information
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-mobileNumber"
                                >
                                  Mobile Number
                                </label>
                                <Input
                                  disabled
                                  className="form-control-alternative"
                                  id="input-mobileNumber"
                                  placeholder="Mobile Number"
                                  type="text"
                                  defaultValue={user.mobileNumber}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Email address
                                </label>
                                <Input
                                  disabled
                                  className="form-control-alternative"
                                  id="input-email"
                                  placeholder="email"
                                  type="email"
                                  defaultValue={user.email}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-first-name"
                                >
                                  First name
                                </label>
                                <Input
                                  disabled
                                  className="form-control-alternative"
                                  id="input-first-name"
                                  placeholder="Name"
                                  type="text"
                                  defaultValue={user.name}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-user-type"
                                >
                                  User Type
                                </label>
                                <Input
                                  disabled
                                  id="input-user-type"
                                  type="select"
                                  defaultValue={user._userType}
                                >
                                  {USER_TYPES.map(userType => {
                                    return (
                                      <option value={userType} key={userType}>
                                        {userType}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-user-role"
                                >
                                  User Role
                                </label>
                                <Input
                                  disabled
                                  id="input-user-role"
                                  type="select"
                                  defaultValue={user.role}
                                >
                                  {USER_ROLES.map(userRole => {
                                    return (
                                      <option value={userRole} key={userRole}>
                                        {userRole}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <hr className="my-4" />
                      </Form>
                      <Row>
                        <Col lg="12">
                          <h2>Posts</h2>
                        </Col>
                        {user && user.userPosts && user.userPosts.map(post => {
                          // const postUrl = `/admin/post/${post._id}`;
                          let createdAt = new Date(post.createdAt);
                          post.createdAt = createdAt.toLocaleDateString();
                          return (
                            <div className="col-md-12" key={post._id}>
                              <Card className="card mb-3 shadow">
                                <CardBody>
                                  <CardTitle className="mb-0">
                                    {post.title}
                                  </CardTitle>
                                  <div className="card-text mb-3">
                                    <ReadMoreAndLess
                                      ref={this.ReadMore}
                                      className="read-more-content"
                                      charLimit={100}
                                      readMoreText=" more"
                                      readLessText=" less"
                                    >
                                      {post.text}
                                    </ReadMoreAndLess>
                                  </div>
                                  <CardText className="mb-0">
                                    <small className="text-muted">
                                      Category:
                                      <strong className="text-capitalize">
                                        {post.category}
                                      </strong>
                                    </small>
                                  </CardText>
                                  <CardText className="mb-0">
                                    <small className="text-muted">
                                      Created at:{' '}
                                      <strong>{post.createdAt}</strong>
                                    </small>
                                  </CardText>
                                  <CardText>
                                    <small className="text-muted">
                                      Post Id: <strong>{post._id}</strong>
                                    </small>
                                  </CardText>
                                  {post && post.assets && post.assets.length ? (
                                    <Row>
                                      <Col>
                                        {post.assets.map((asset, index) => {
                                          return (
                                            <a
                                              key={index}
                                              href={asset.url}
                                              alt={asset.name}
                                              // eslint-disable-next-line react/jsx-no-target-blank
                                              target="_blank"
                                              rel="noopener"
                                            >
                                              <img
                                                src={asset.url}
                                                className="img-thumbnail"
                                                alt={asset.name}
                                                style={{
                                                  width: '100px',
                                                  height: '100px'
                                                }}
                                              />
                                            </a>
                                          );
                                        })}
                                      </Col>
                                    </Row>
                                  ) : (
                                    ''
                                  )}
                                </CardBody>
                              </Card>
                            </div>
                          );
                        })}
                      </Row>
                      <hr className="my-4" />
                      {user &&
                      user.templeMember &&
                      user.templeMember[0] &&
                      user.templeMember[0].isAdmin ? (
                        <Row>
                          <Col lg="12">
                            <h2>Events</h2>
                          </Col>
                          <div className="col-md-12">
                            <Row>
                              {user.templeEvents.map(event => {
                                let createdAt = new Date(event.createdAt);
                                if (event.date) {
                                  let year = event.date.substring(0, 4);
                                  let month = event.date.substring(4, 6);
                                  let day = event.date.substring(6, 8);
                                  let __date = new Date(
                                    `${year}-${month}-${day}`
                                  );
                                  let eventDate = moment(__date).format(
                                    'ddd DD, MMM YYYY'
                                  );
                                  event.date = eventDate;
                                } else {
                                  event.date = 'NOT PROVIDED';
                                }
                                event.createdAt = createdAt.toLocaleDateString();
                                return (
                                  <div className="col-md-12" key={event._id}>
                                    <Card className="card mb-3 shadow">
                                      <CardBody>
                                        <Row>
                                          <Col md="12" xs="12">
                                            <CardTitle className="mb-0">
                                              {event.eventName}
                                            </CardTitle>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md="8" xs="12">
                                            <CardText className="mb-0">
                                              <small className="text-muted">
                                                By:
                                                {event.userId &&
                                                event.userId.name ? (
                                                  <a
                                                    href={
                                                      '/admin/user/' +
                                                      event.userId._id
                                                    }
                                                  >
                                                    <strong className="ml-2">
                                                      {event.userId.name}
                                                    </strong>
                                                  </a>
                                                ) : (
                                                  <strong className="ml-2">
                                                    User was deleted
                                                  </strong>
                                                )}
                                              </small>
                                            </CardText>
                                            <CardText className="mb-0">
                                              <small className="text-muted">
                                                Temple:
                                                {event.templeId &&
                                                event.templeId.name ? (
                                                  <strong className="ml-2">
                                                    {event.templeId.name}
                                                  </strong>
                                                ) : (
                                                  'Temple was deleted'
                                                )}
                                              </small>
                                            </CardText>
                                            <CardText className="mb-0">
                                              <small className="text-muted">
                                                Contact Person:
                                                <strong className="ml-2">
                                                  {event.contactPerson}
                                                </strong>
                                              </small>
                                            </CardText>
                                            <CardText className="mb-0">
                                              <small className="text-muted">
                                                Mobile Number:
                                                <strong className="ml-2">
                                                  {event.mobileNumber}
                                                </strong>
                                              </small>
                                            </CardText>
                                            <CardText>
                                              <small className="text-muted">
                                                Created at:
                                                <strong className="ml-2">
                                                  {event.createdAt}
                                                </strong>
                                              </small>
                                            </CardText>
                                          </Col>
                                          <Col>
                                            <CardText className="mb-0">
                                              <small className="text-muted">
                                                Event Address:
                                                <strong className="ml-2">
                                                  {event.address}
                                                </strong>
                                              </small>
                                            </CardText>
                                            <CardText className="mb-0">
                                              <small className="text-muted">
                                                Locality:
                                                <strong className="ml-2">
                                                  {event.locality}
                                                </strong>
                                              </small>
                                            </CardText>
                                            <CardText className="mb-0">
                                              <small className="text-muted">
                                                Event Date:
                                                <strong className="ml-2">
                                                  {event.date}
                                                </strong>
                                              </small>
                                            </CardText>
                                            <CardText className="mb-0">
                                              <small className="text-muted">
                                                Start Time:
                                                <strong className="ml-2">
                                                  {event.startTimeHours}:
                                                  {event.startTimeMins}
                                                  {event.startTimeMeridiem}
                                                </strong>
                                              </small>
                                            </CardText>
                                            <CardText className="mb-0">
                                              <small className="text-muted">
                                                End Time:
                                                <strong className="ml-2">
                                                  {event.endTimeHours}:
                                                  {event.endTimeMins}
                                                  {event.endTimeMeridiem}
                                                </strong>
                                              </small>
                                            </CardText>
                                          </Col>
                                        </Row>
                                        <div className="card-text mb-3">
                                          <ReadMoreAndLess
                                            ref={this.ReadMore}
                                            className="read-more-content"
                                            charLimit={100}
                                            readMoreText=" more"
                                            readLessText=" less"
                                          >
                                            {event.description}
                                          </ReadMoreAndLess>
                                        </div>
                                        <CardText>
                                          <small className="text-muted">
                                            EventId:
                                            <strong className="ml-2">
                                              {event._id}
                                            </strong>
                                          </small>
                                        </CardText>
                                        {event &&
                                        event.assets &&
                                        event.assets.length ? (
                                          <Row>
                                            <Col>
                                              {event.assets.map(
                                                (asset, index) => {
                                                  return (
                                                    <a
                                                      key={index}
                                                      href={asset.url}
                                                      alt={asset.name}
                                                      // eslint-disable-next-line react/jsx-no-target-blank
                                                      target="_blank"
                                                      rel="noopener"
                                                    >
                                                      <img
                                                        src={asset.url}
                                                        className="img-thumbnail"
                                                        alt={asset.name}
                                                        style={{
                                                          width: '100px',
                                                          height: '100px'
                                                        }}
                                                      />
                                                    </a>
                                                  );
                                                }
                                              )}
                                            </Col>
                                          </Row>
                                        ) : (
                                          ''
                                        )}
                                      </CardBody>
                                    </Card>
                                  </div>
                                );
                              })}
                            </Row>
                          </div>
                        </Row>
                      ) : (
                        ''
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </>
    );
  }
}

export default NProfile;
