import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import axios from 'axios';


// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import "react-datepicker/dist/react-datepicker.css";


// plugins styles downloaded
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';

// core styles
import 'assets/scss/argon-dashboard-react.scss';

import AdminLayout from 'layouts/Admin.jsx';
import AuthLayout from 'layouts/Auth.jsx';
import './style.css'
import { BACKEND_URL } from './config';


axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';


function adminRedirect(props) {
  const AuthUser = localStorage.getItem('user');
  if (AuthUser) {
    return <AdminLayout {...props} />
  } else {
    return <Redirect from="/" to="/auth/login" />
  }
}

function App() {
  // localStorage.clear();
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/admin"
          render={props => adminRedirect(props)}
        />

        <Route
          path="/"
          render={props => <AuthLayout {...props} />}
        />

        {/* <Redirect from="/" to="/auth" /> */}

      </Switch>
    </BrowserRouter>
  )
}


ReactDOM.render(<App />, document.getElementById('root'));
