import React from "react";
import axios from "axios";
import qs from "qs";
import lodash from "lodash";
import NotificationAlert from "react-notification-alert";
import { Scrollbars } from "react-custom-scrollbars";
import { Field, Form, Formik } from "formik";
import BootstrapTable from "react-bootstrap-table-next";
import DateRangePicker from "components/Formik/DateRangePicker";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  Button,
  UncontrolledCollapse,
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";

import { cleanObject } from "utils";
import moment from 'moment';
// core components
import columns from "views/temples/column";
import AutosuggestSearch from "components/AutosuggestSearch";
import { RATING_OPTIONS, GET_RATING_VALUES } from "utils/enums/rating";
import "./temple.css";

import { AUTH_TOKEN } from "../../config";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
const allOption = {
  label: "Select all",
  value: "*",
};
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const arrayToCount = (array) =>{
  return array.length
}
class Temple extends React.Component {
  state = {
    // isLoading: true,
    autoSearchList: [],
    formInitialValue: {
      rating: "",
      tmCount: "",
      eventCount: "",
      updateCount: "",
      disable: "",
      contactEmail: "",
      contactNumber: "",
      contactEmailTA: "",
      user: "",
      smsCount: "",
      website: ""
    },
    temples: [],
    modal: {},
    search: "",
    filters: {},
    page: 1,
    sizePerPage: 100,
    user: '',
    total: 0,
    sortField: undefined,
    sortOrder: null,
    editForm: {
      templeName: "",
      city: "",
      address: "",
      status: "",
    },
    contactOptions: [
      { value: "phonenumber", label: " Phone (Google)" },
      { value: "email", label: "Email (Google)" },
      { value: "phonenumberta", label: "Phone (TA)" },
      { value: "emailta", label: "Email(TA)" },
    ],
    optionSelected: [],
    rowselected: [],
    exportvisibility: true,
    filterOn: false,
    selectalldata: [],
    templesLists: [],
    phoneCount: 0,
    count: 0
  };
  constructor(props) {
    super(props);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
  }

  componentWillMount() {
    const {
      location: { search },
    } = this.props;
    const filters = qs.parse(search, { ignoreQueryPrefix: true });
    
    this.setState({ filters });
    this.fetchUerFavTemples();
  }
  
  handleChange = (selected) => {
    this.setState({
      optionSelected: selected,
    });
    // console.log('satya-selected', selected)
    // console.log('satya-optionSelected', this.state.optionSelected)
  };
  fetchUerFavTemples = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userId = urlParams.get('user')
    if(userId?.length > 0) { 
    try {
      // console.log('In here')
      // console.log(filters)
      const { search, page, sizePerPage, sortField, sortOrder, filters } =
        this.state;
      let url = "/temples";
      let query = { page, sizePerPage, user : userId };
      if (sortField && sortOrder) {
        query = { ...query, sortField, sortOrder };
      }
      console.log(this.state.filters)
      const copyOfFilters = JSON.parse(JSON.stringify(filters));
      if (copyOfFilters.createdAt) {
        if (copyOfFilters.createdAt.toDate) {
          const toDate = new Date(copyOfFilters.createdAt.toDate);
          toDate.setDate(toDate.getDate() + 1);
          copyOfFilters.createdAt.toDate = `${toDate.getFullYear()}-${
            toDate.getMonth() + 1
          }-${toDate.getDate()}`;
        } else if (
          copyOfFilters.createdAt.fromDate &&
          !copyOfFilters.createdAt.toDate
        ) {
          const todayDate = new Date();
          copyOfFilters.createdAt.toDate = `${todayDate.getFullYear()}-${
            todayDate.getMonth() + 1
          }-${todayDate.getDate()}`;
        }
      }
      if (copyOfFilters) {
        cleanObject(copyOfFilters);
        query = { ...query, filters: copyOfFilters };
      }
      if (search) {
        query = { ...query, search };
      }
      if (query) {
        const queryString = qs.stringify(query, { skipNulls: false });
        url = `${url}?${queryString}`;
      }
      const response = await axios({
        method: "GET",
        url,
        params: {
          apikey: AUTH_TOKEN,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
       // api post response
        this.setState({
          isLoading: false,
          temples: response.data.temples || [],
          total: response.data.pagination && response.data.pagination.total,
        });
      if (this.state.temples) {
        const result = this.state.temples.map((row) => row.contactNumber)
        const MainResult = result.filter(Boolean)
        const resultValue  = this.state.temples.map((row) => row.created)
        const Main = resultValue.filter(Boolean)
        this.setState({ phoneCount : MainResult , count : Main })
      }
      if (this.state.temples.length == 0) {
        this.setState({ exportvisibility: true });
      }
    } catch (error) {
      //      console.log('error', error)
      this.setState({ error, isLoading: false });
    }
  }
  }

  fetchTemples = async () => {
    try {
      // console.log('In here')
      // console.log(filters)
      const { search, page, sizePerPage, sortField, sortOrder, filters, user } =
        this.state;
      let url = "/temples";
      let query = { page, sizePerPage, user };
      if (sortField && sortOrder) {
        query = { ...query, sortField, sortOrder };
      }
      console.log(this.state.filters)
      const copyOfFilters = JSON.parse(JSON.stringify(filters));
      if (copyOfFilters.createdAt) {
        if (copyOfFilters.createdAt.toDate) {
          const toDate = new Date(copyOfFilters.createdAt.toDate);
          toDate.setDate(toDate.getDate() + 1);
          copyOfFilters.createdAt.toDate = `${toDate.getFullYear()}-${
            toDate.getMonth() + 1
          }-${toDate.getDate()}`;
        } else if (
          copyOfFilters.createdAt.fromDate &&
          !copyOfFilters.createdAt.toDate
        ) {
          const todayDate = new Date();
          copyOfFilters.createdAt.toDate = `${todayDate.getFullYear()}-${
            todayDate.getMonth() + 1
          }-${todayDate.getDate()}`;
        }
      }
      if (copyOfFilters) {
        cleanObject(copyOfFilters);
        query = { ...query, filters: copyOfFilters };
      }
      if (search) {
        query = { ...query, search };
      }
      if (query) {
        const queryString = qs.stringify(query, { skipNulls: false });
        url = `${url}?${queryString}`;
      }
      const response = await axios({
        method: "GET",
        url,
        params: {
          apikey: AUTH_TOKEN,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
       // api post response
      this.setState({
        isLoading: false,
        temples: response.data.temples || [],
        total: response.data.pagination && response.data.pagination.total,
      });
    if (this.state.temples) {
      const result = this.state.temples.map((row) => row.contactNumber)
      const MainResult = result.filter(Boolean)
      const resultValue  = this.state.temples.map((row) => row.created)
      const Main = resultValue.filter(Boolean)
      this.setState({ phoneCount : MainResult , count : Main })
    }
    if (this.state.temples.length == 0) {
      this.setState({ exportvisibility: true });
    }
    } catch (error) {
      //      console.log('error', error)
      this.setState({ error, isLoading: false });
    }
  };

  onSelectAll = (isSelected) => {
    if (isSelected) {
      this.setState({
        exportvisibility: true,
        selectalldata: this.state.temples.map((row) => row),
      });
      //     console.log('selectalldata',this.state.selectalldata)
      return this.state.temples.map((row) => row._id);
    } else {
      this.setState({
        exportvisibility: true,
        selectalldata: [],
      });
      return [];
    }
  };

  onRowSelect({ _id }, isSelected) {
    console.log("checkk", _id);
    if (isSelected) {
      this.setState({
        selectalldata: this.state.selectalldata.concat(
          this.state.temples.filter((a) => a._id === _id)
        ),
      });
      return true;
    } else {
      this.setState({
        selectalldata: this.state.selectalldata.filter((a) => a._id !== _id),
      });
      return true;
    }
    return false;
  }

  handleTableChange = (type, { page, sizePerPage, filters, ...rest }) => {
    this.setState({ isLoading: true });
    if (type === "pagination") {
      this.setState({ sizePerPage, page }, this.fetchTemples);
    }

    if (type === "sort") {
      this.setState(
        {
          page: 1,
          sortOrder: rest.sortOrder,
          sortField: rest.sortField,
        },
        this.fetchTemples
      );
    }
  };

  handleUpdate = async (row) => {
    // this.setState({ isLoading: true });
    console.log( "row from column js" , row)
    let message = ''
    let url = '';
    if (row.disable) {
      url = `/temples/${row._id}/enable`;
      message = 'Temple Activated'
    } else {
      url = `/temples/${row._id}/disable`;
      message = 'Temple Inactivated'
    }

    const response = await axios({
      method: "PUT",
      url,
      params: {
        apikey: AUTH_TOKEN,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

   
    const type = response.data.success ? "success" : "danger";
    this.notify(message, type, "tr");

    if (response.data.success) {
      // this.setState({ isLoading: false });
      this.fetchTemples();
    }
  };

  onSubmitFilterForm = (values) => {
    let filters = { ...values };
    // if (values.rating) {
    //   filters = { 'gData.rating': GET_RATING_VALUES[values.rating] };
    // }
    // delete filters.rating;
    // console.log('satya1',values)
    // console.log('satya2',values.smsCount)
    // if(values.smsCount == 0){
    //   filters.smsCount = { $exists: false };

    // }

    if (this.state.optionSelected) {
      for (const i of this.state.optionSelected) {
        if (i.value === "email") {
          filters.contactEmail = { $exists: true, $ne: null };
        }
        if (i.value === "phonenumber") {
          filters.contactNumber = { $exists: true, $ne: null };
        }
        if (i.value === "emailta") {
          filters.user = "contactEmailTA"
        }
        if (i.value === "phonenumberta") {
          filters.user = "contactNumberTA"
        }
      }
    }
    this.setState(
      { filters, filterOn: true },
      this.fetchTemples
    );
  };

  onResetForm = () => {
    this.setState({ filters: {},temples: [] ,optionSelected: [],search: '',count: 0,phoneCount: 0,total: 0, page: 1 });
  };

  onExportExcel = async () => {
    
    if (this.state.filterOn === true) {
      const arrayOfStrings = this.state.temples ;
      var arrayOfObjects = [];     
      arrayOfStrings.forEach(function (element, index) {
        arrayOfObjects.push({ 
          status: element.disable === true ? 'INACTIVE' : 'ACTIVE',
          addedDate: element.createdAt,
          Image: element.image, 
          templeName: element.name, 
          phoneGoogle: element.user[0]?.mobileNumber,
          emailGoogle: element.contactEmail,
          phoneTA: element.contactNumber,
          emailTA: element.user[0]?.email,
          // contactNumberTA: element.contactNumber ? element.contactNumber.replace(/^[^,]+, */, ''): "", 
          Email: element.contactEmail, 
          'Own Website': element.templeOwnWebsite, 
          'Devotted Website': 'https://dev.temple.devotted.com/templedetails.html?templeUrl=' + element.templeUrl, 
          SMS: element.smsCount, 
          Country: element.country, 
          State:element.state, 
          City: element.city, 
          Address:element.address, 
          Rating:element.rating, 
          Members: arrayToCount(element.templemembers), 
          Events : arrayToCount(element.templeevents), 
          Updates: arrayToCount(element.templeupdates), 
          'Approved TA/TM' : arrayToCount(element.approvedtemplemembers),
          
        });
      });
      const ws = XLSX.utils.json_to_sheet(arrayOfObjects);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "" + moment(new Date()).format('ddd, MMMM Do YYYY') + fileExtension);
    } else {
      let url = "/temples/details/templesList";
      const response = await axios({
        method: "GET",
        url,
        params: {
          apikey: AUTH_TOKEN,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log("fghjk", response)
      this.setState({ templesLists: response.data.arrayOfObjects });
      const ws = XLSX.utils.json_to_sheet(this.state.templesLists);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "fileName" + fileExtension);
      // }
      // this.setState({ filters: {}, isLoading: true }, this.fetchTemples);
    }
  };

  notify = (message, type = "success", place = "tr") => {
    let options = {
      place: place,
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
      message: (
        <div className="alert-text">
          <span data-notify="message">{message}</span>
        </div>
      ),
    };
    this.refs.notify.notificationAlert(options);
  };

  toggleModal = (modelName, temple) => {
    this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        [modelName]: !prevState.modal[modelName],
      },
      activeTemple: temple,
    }));
    if (modelName === "EDIT" && temple !== undefined) {
      this.setState({
        editForm: {
          templeName: temple.name,
          city: temple.city,
          address: temple.address,
          status: temple.disable,
        },
      });
    }
  };

  tableCallBack = (actionType, ...rest) => {
    switch (actionType) {
      case "DISABLE":
        this.handleUpdate(...rest);
        break;
      case "MODAL_SHOW":
        this.toggleModal(...rest);
        break;
      default:
        break;
    }
  };

  handleTextChange = (event, { newValue, method }) => {
    this.setState({ search: newValue });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setState({ autoSearchList: [], page: 1 }, this.fetchTemples);
    }
  };

  debounceFn = lodash.debounce(async (value) => {
    const url = `/temples/searchlist?sizePerPage=5&search=${value}`;
    const response = await axios({
      method: "GET",
      url,
      params: {
        apikey: AUTH_TOKEN,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (Array.isArray(response?.data))
      this.setState({ autoSearchList: response.data });
  }, 1000);

  onSuggestionsFetchRequested = ({ value, rest }) => {
    this.debounceFn(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({ autoSearchList: [], page: 1 }, this.fetchTemples);
  };

  getSuggestionValue = (suggestion) => {
    return suggestion.name;
  };

  handleOnChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      editForm: {
        ...this.state.editForm,
        [name]: value,
      },
    });
  };

  editTemple = async () => {
    const { _id } = this.state.activeTemple;
    const { templeName, address, city, status } = this.state.editForm;
    let url = `/temples/${_id}/edit`;

    const response = await axios({
      method: "PUT",
      url,
      params: {
        apikey: AUTH_TOKEN,
      },
      data: {
        templeName,
        address,
        city,
        status,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const message = response.data.msg || response.data.message;
    const type = response.data.success ? "success" : "danger";
    this.notify(message, type, "tr");

    if (response.data.success) {
      this.setState({ modal: false, activeTemple: null });
      this.fetchTemples();
    }
  };

  Templesms = async () => {
    if (this.state.selectalldata) {
      let url = `/temples/templesms`;

      const response = await axios({
        method: "PUT",
        url,
        params: {
          apikey: AUTH_TOKEN,
        },
        data: this.state.selectalldata,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const message = response.data.msg || response.data.message;
      const type = response.data.success ? "success" : "danger";
      this.notify(message, type, "tr");

      if (response.data.success) {
        //  this.setState({ modal: false, activeTemple: null });
        this.fetchTemples();
      }
    }
  };

  renderSuggestion = (suggestion) => {
    return (
      <div>
        <p className="h4">{suggestion.name}</p>
        <p className="h6">{suggestion.address}</p>
      </div>
    );
  };

  render() {
    const {
      temples,
      search,
      isLoading,
      editForm: { templeName, status, city, address },
    } = this.state;
    //  console.log('temples', temples)
    const selectRowProp = {
      mode: "checkbox",
      clickToSelect: true,
      onSelectAll: this.onSelectAll,
      onSelect: this.onRowSelect,
      rowselected: this.state.rowselected,
    };
    const paginationOption = {
      custom: true,
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.total,
    };
    const pagination = paginationFactory(paginationOption);

    return (
      <>
        <NotificationAlert ref="notify" />

        {/* Page header */}
        <div className="header header-dark bg-info pb-6 pt-5 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4"></Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div className="row">
                    <div className="col-6">
                      <AutosuggestSearch
                        suggestions={this.state.autoSearchList}
                        renderSuggestion={this.renderSuggestion}
                        getSuggestionValue={this.getSuggestionValue}
                        onSuggestionsFetchRequested={
                          this.onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                          this.onSuggestionsClearRequested
                        }
                        inputProps={{
                          placeholder: "search by temple name or address",
                          value: search,
                          onChange: this.handleTextChange,
                          onKeyDown: this.handleKeyDown,
                        }}
                      />
                    </div>
                    <Col className="text-md-right" md={{ offset: 5, size: 1 }}>
                      <Button
                        color="primary"
                        size="sm"
                        id="buttonTogglerFilters"
                      >
                        Filters
                      </Button>
                    </Col>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <UncontrolledCollapse
                        defaultOpen
                        toggler="#buttonTogglerFilters"
                      >
                        <Formik
                          initialValues={this.state.formInitialValue}
                          onSubmit={this.onSubmitFilterForm}
                          onReset={this.onResetForm}
                        >
                          {({ handleReset }) => {
                            return (
                              <Form>
                                <Row>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Contact
                                    </label>

                                    <ReactSelect
                                      as="select"
                                      name="contact"
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                      }}
                                      menuPosition="fixed"
                                      data-toggle="popover"
                                      data-trigger="focus"
                                      dropDownDirection="TOP"
                                      options={this.state.contactOptions}
                                      isMulti
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={false}
                                      components={{
                                        Option,
                                      }}
                                      onChange={this.handleChange}
                                      allowSelectAll={true}
                                      value={this.state.optionSelected}
                                    />
                                  </Col>
                                  <Col className="mb-3" md='2'>
                                    <label className="form-control-label">
                                      SMS #
                                    </label>
                                    <Field
                                      className="form-control"
                                      as="select"
                                      name="smsCount"
                                    >
                                      <option value={""}>select</option>
                                      <option value={0}>0</option>
                                      <option value={1}>1</option>
                                      <option value={2}>2</option>
                                      <option value={3}>3</option>
                                      <option value={4}>4</option>
                                      <option value={5}>5</option>
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md='1'>
                                    <label className="form-control-label">
                                      TM Count
                                    </label>
                                    <Field
                                      className="form-control"
                                      as="select"
                                      name="tmCount"
                                    >
                                      <option value={""}>select</option>
                                      <option value={0}>0</option>
                                      <option value={2}>Upto 2</option>
                                      <option value={5}>Upto 5</option>
                                      <option value={10}>Upto 10</option>
                                      <option value={50}>Upto 50</option>
                                      <option value="50+">50 +</option>
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md='1'>
                                    <label className="form-control-label">
                                      Event's Count
                                    </label>
                                    <Field
                                      className="form-control"
                                      as="select"
                                      name = 'eventCount'
                                    >
                                      <option value={""}>select</option>
                                      <option value={"0"}>0</option>
                                      <option value={"10"}>Upto 10</option>
                                      <option value={"50"}>Upto 50</option>
                                      <option value={"100"}>Upto 100</option>
                                      <option value="100+">100 +</option>
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md='1'>
                                    <label className="form-control-label">
                                    Updates Count
                                    </label>
                                    <Field
                                      className="form-control"
                                      as="select"
                                      name='updateCount'
                                    >
                                      <option value={""}>select</option>
                                      <option value={"0"}>0</option>
                                      <option value={"10"}>Upto 10</option>
                                      <option value={"50"}>Upto 50</option>
                                      <option value={"100"}>Upto 100</option>
                                      <option value="100+">100 +</option>
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md='1'>
                                    <label className="form-control-label">
                                    Web Site
                                    </label>
                                    <Field
                                      className="form-control"
                                      as="select"
                                      name="website"
                                    >
                                      <option value={""}>All</option>
                                      <option value={'devotted'}>Devotted</option>
                                      <option value={'own'}>Own</option>
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Rating
                                    </label>
                                    <Field
                                      className="form-control"
                                      as="select"
                                      name="rating"
                                    >
                                      {RATING_OPTIONS.map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Status
                                    </label>
                                    <Field
                                      className="form-control"
                                      as="select"
                                      name="disable"
                                    >
                                      <option value={""}>All</option>
                                      <option value={false}>Active</option>
                                      <option value={true}>In-Active</option>
                                    </Field>
                                  </Col>

                                  <Col className="mb-3" md="3">
                                    <label className="form-control-label">
                                    Added Date
                                    </label>
                                    <Row>
                                      <div className="col  pr-1">
                                        <DateRangePicker
                                          name="createdAt"
                                          DATE_FORMAT="YYYY-MM-DD"
                                          placeholder1="start date"
                                          placeholder2="end date"
                                        />
                                      </div>
                                    </Row>
                                  </Col>

                                  {/* <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Contact
                                    </label>
                                    <Field className="form-control" as="select" name="disable">
                                      <option value={''}>select</option>
                                      <option value={true}>Email</option>
                                      <option value={false}>Phone Number</option>                                     
                                      <option value={true}>Website</option>
                                    </Field>
                                  </Col> */}
                                  <Col
                                    className="mb-3 pb-1 align-self-end"
                                    md="2"
                                  >
                                    <Button type="submit">Go</Button>
                                    <Button onClick={handleReset}>Reset</Button>
                                  </Col>
                                </Row>
                              </Form>
                            );
                          }}
                        </Formik>
                      </UncontrolledCollapse>
                    </div>
                  </div>
                </CardHeader>

                <PaginationProvider pagination={pagination}>
                  {({ paginationProps, paginationTableProps }) => (
                    <>
                      {isLoading && (
                        <div className="spinner-container">
                          <Spinner type="grow" color="dark" />
                          <Spinner type="grow" color="dark" />
                          <Spinner type="grow" color="dark" />
                          <Spinner type="grow" color="dark" />
                          <Spinner type="grow" color="dark" />
                        </div>
                      )}
                      <div className=" table-responsive">
                        <Scrollbars style={{ height: "50vh" }}>
                          <BootstrapTable
                            selectRow={selectRowProp}
                            keyField="_id"
                            remote
                            bootstrap4={true}
                            bordered={false}
                            data={temples}
                            columns={columns(this.tableCallBack)}
                            onTableChange={this.handleTableChange}
                            noDataIndication="no records"
                            {...paginationTableProps}
                          />
                        </Scrollbars>
                      </div>

                      <CardFooter className="py-4">
                        <PaginationListStandalone {...paginationProps} />
                        <Row className="mb-3 pb-1 align-self-end" md="2">
                          {this.state.exportvisibility ? (
                            <Button onClick={this.onExportExcel} type="submit">
                              Export to Excel
                            </Button>
                          ) : null}
                          {this.state.exportvisibility ? (
                            <Button onClick={this.Templesms} type="submit">
                              Mark SMS
                            </Button>
                          ) : null}
                           <label className="text">
                              PhoneCount: { this.state.phoneCount.length } 
                           </label>
                           <label className="text">
                              count: { this.state.count.length } 
                           </label>
                        </Row>
                      </CardFooter>
                    </>
                  )}
                </PaginationProvider>
              </Card>
            </div>
          </Row>
        </Container>
        <Modal
          isOpen={this.state.modal.EDIT}
          toggle={(e) => this.toggleModal("EDIT")}
          className={this.props.className}
        >
          <ModalHeader toggle={(e) => this.toggleModal("EDIT")}>
            Edit Temple
          </ModalHeader>
          <ModalBody>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-user"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Name"
                  name="templeName"
                  type="text"
                  value={templeName}
                  onChange={(e) => this.handleOnChange(e)}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="City"
                  name="city"
                  type="city"
                  value={city}
                  onChange={this.handleOnChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-phone"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Address"
                  name="address"
                  type="text"
                  value={address}
                  onChange={this.handleOnChange}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    {/* <i className="fas fa-phone"></i> */}
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Status"
                  name="status"
                  type="select"
                  onChange={this.handleOnChange}
                >
                  <option value="false" selected={status ? true : false}>
                    Active
                  </option>
                  <option value="true" selected={status ? false : true}>
                    In-Active
                  </option>
                </Input>
              </InputGroup>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.editTemple}>
              Save Temple
            </Button>
            <Button color="secondary" onClick={(e) => this.toggleModal("EDIT")}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Temple;
