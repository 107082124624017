import React from 'react';
import axios from 'axios';
import qs from 'qs';
import lodash from 'lodash';
import moment from 'moment/moment';
import { Field, Form, Formik } from 'formik';

// reactstrap components
import {
  Container, Row, Col,
  Button,
  Card, CardHeader, CardFooter,
  FormGroup, Form as FormB,
  Input, InputGroup, InputGroupAddon, InputGroupText,
  Modal, ModalBody, ModalFooter, ModalHeader,
  UncontrolledCollapse,
} from 'reactstrap';

import { Scrollbars } from 'react-custom-scrollbars';
import NotificationAlert from "react-notification-alert";
import paginationFactory,
{
  PaginationProvider, PaginationListStandalone
} from "react-bootstrap-table2-paginator";

import BootstrapTable from "react-bootstrap-table-next";

import { cleanObject } from 'utils';
// core components
import DateRangePicker from 'components/Formik/DateRangePicker';
import columns from './column';
import { AUTH_TOKEN } from '../../../config'

import { loggedInUser } from '../../../utils/User'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
const dateToAge = (date) => {
  var replaceStr = date ? date.replace("-", ",").replace("-", ",") : ''
  var splitSrt = replaceStr.split(",")
  var dateFromArray = '' + splitSrt[1] + '/' + splitSrt[0] + '/' + splitSrt[2]
  var dob = new Date(dateFromArray);
  //calculate month difference from current date in time
  var month_diff = Date.now() - dob.getTime();

  //convert the calculated difference in date format
  var age_dt = new Date(month_diff);

  //extract year from date    
  var year = age_dt.getUTCFullYear();

  //now calculate the age of the user
  var age = Math.abs(year - 1970);
  if (!age) {
    return ''
  }
  //display the calculated age
  else {
    return age
  }

}
const arrayToCount = (array) => {
  return array.length
}
class TempleMember extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      formInitialValue: { address: '', role: '', agentName: '', country: '', state: '', city: '', active: '', requestRaised: '', source: '', gender: '', language: '' },
      modal: {},
      filters: {},
      search: '',
      users: [],
      page: 1,
      sizePerPage: 100,
      total: 0,
      sortField: 'user.createdAt',
      sortOrder: 'desc',
      exportvisibility: true,
      filterOn: false,
      templesLists: [],
      isGenderUnknown: false,
      isCountryNone: false,
      allState: []
    };
  }


  componentWillMount() {
    const { location: { search } } = this.props;
    const filters = qs.parse(search, { ignoreQueryPrefix: true });
    this.setState({ filters })
    this.fetchCountryStates()
  }

  async fetchCountryStates() {
    try {
      let url = '/c0nstants?type=countryStates';
      const response = await axios({
        method: 'GET',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      this.setState({
        loading: false,
        allState: response.data.constants[0].fields[0].States || [],

      });
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  async fetchUsers() {
    var isRequestRaised = ''
    // check gender
    if (this.state.filters.gender === "unknown") {
      this.setState({
        isGenderUnknown: true,

      })
      this.state.filters.gender = ""
    }
    // check country
    if (this.state.filters.country === "null") {
      this.setState({
        isCountryNone: true,

      })
      this.state.filters.country = ""
    }
    try {
      const { search, page, sizePerPage, sortField, sortOrder, filters } = this.state;
      let url = "/users/temple-members";
      let query = { page, sizePerPage };
      if (sortField && sortOrder) { query = { ...query, sortField, sortOrder } }
       if(filters.requestRaised === 'false'){
        isRequestRaised = 'false';
         filters.requestRaised = '';
       } else {
        isRequestRaised = '';
       }
      const copyOfFilters = JSON.parse(JSON.stringify(filters))
      if (copyOfFilters.dateApproved) {
        if (copyOfFilters.dateApproved.toDate) {
          const toDate = new Date(copyOfFilters.dateApproved.toDate)
          toDate.setDate(toDate.getDate() + 1)
          copyOfFilters.dateApproved.toDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`
        } else if (copyOfFilters.dateApproved.fromDate && !copyOfFilters.dateApproved.toDate) {
          const todayDate = new Date()
          copyOfFilters.dateApproved.toDate = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`
        }
      }
      if (copyOfFilters.dateRegistered) {
        if (copyOfFilters.dateRegistered.toDate) {
          const toDate = new Date(copyOfFilters.dateRegistered.toDate)
          toDate.setDate(toDate.getDate() + 1)
          copyOfFilters.dateRegistered.toDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`
        } else if (copyOfFilters.dateRegistered.fromDate && !copyOfFilters.dateRegistered.toDate) {
          const todayDate = new Date()
          copyOfFilters.dateRegistered.toDate = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`
        }
      }
      if (copyOfFilters) {
        cleanObject(copyOfFilters);
        if (copyOfFilters.isAdmin === 'null') {
          delete copyOfFilters.isAdmin
          query = { ...query, filters: { ...copyOfFilters } }
        } else {
          query = { ...query, filters: { ...copyOfFilters, isAdmin: false } }
        }
      }
      if (search) { query = { ...query, search } }
      if (query) {
        const queryString = qs.stringify(query, { skipNulls: false });
        url = `${url}?${queryString}`;
      }
      const result = await axios({
        method: 'GET',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (result.data.users) {
        if (this.state.isCountryNone) {
          let filterdData = result.data.users?.filter((el) => {
            if(el.user){
              return !el.user[0]?.country
            }
            else{
              return true;
            }
          })
          if (this.state.isGenderUnknown) {

            let filterdGender = filterdData.filter((el) => {
              if(el.user){
                return !el.user[0]?.gender
              }
              else{
                return true;
              }
            })
            this.setState({
              users: filterdGender,
              filterOn: true,
              isCountryNone: false,
              isGenderUnknown: false
              // total: data.pagination.total,
            });
          } else {
            // let filterdData = result.data.users?.filter((el) => {
            //   return !el.user[0].country
            // })
            this.setState({
              users: filterdData,
              filterOn: true,
              isCountryNone: false,
              isGenderUnknown: false
              // total: data.pagination.total,
            });
          }
        } else if (this.state.isGenderUnknown) {
          let filterdGender = result.data.users?.filter((el) => {
            if(el.user){
              return !el.user[0]?.gender
            }
            else{
              return true;
            }
          })
          if (this.state.isCountryNone) {

            let filterdCountry = filterdGender.filter((el) => {
              if(el.user){
                return !el.user[0]?.country
              }
              else{
                return true;
              }
            })
            this.setState({
              users: filterdCountry,
              filterOn: true,
              isCountryNone: false,
              isGenderUnknown: false
              // total: data.pagination.total,
            });
          } else {
            this.setState({
              users: filterdGender,
              filterOn: true,
              isCountryNone: false,
              isGenderUnknown: false
              // total: data.pagination.total,
            });
          }
        }
        else {
          if(isRequestRaised === 'false'){
            this.setState({
              users: result.data.users.filter((el)=>el.approved.length === 0),
              total: result.data.pagination.total,
              isCountryNone: false,
              isGenderUnknown: false
              // total: data.pagination.total,
            });
          } else {
            this.setState({
              users: result.data.users,
              total: result.data.pagination.total,
              isCountryNone: false,
              isGenderUnknown: false
              // total: data.pagination.total,
            });
          }


         
        }
      } else if (this.state.users.length == 0) {
        this.setState({ exportvisibility: false })
      } else {
        this.setState({ users: [], total: 0, });
      }
    } catch (error) {
      this.setState({ error, isLoading: false })
    }
  }
  sendMessage = async (e) => {
    try {
      e.preventDefault();
      const url = '/users/post-feedback/send-message';
      await this.setState({
        messageText: this.state.messageText
      })
      // console.log("satya", this.statemessageText)

      const body = {
        mobile: this.state.activeUser.mobileNumber,
        message: this.state.messageText
      }
      await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        data: body,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then((response) => {
        if (response) {
          this.notify('SMS Send successfully.', 'success')
        } else {
          this.notify(response.message, 'danger')
        }
      });
      this.setState({ modal: false, activeUser: null, messageText: '' });
    } catch (error) {

    }
  }
  notify(messageText = 'success', type = 'success', place = 'tr') {
    const options = {
      place,
      type,
      autoDismiss: 7,
      icon: "ni ni-bell-55",
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {messageText}
          </span>
        </div>
      ),
    }
    this.refs.notify.notificationAlert(options);
  };
  deleteUser = async () => {
    try {
      if (!this.state.activeUser || this.state.activeUser === null) return;
      const url = `users/${this.state.activeUser.userId}/active`;
      const response = await axios({
        method: 'DELETE',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.success) {
        this.setState({ modal: false, activeUser: null });
        this.notify('User Disabled', 'success')
        this.fetchUsers();
      } else {
        this.notify(response.msg, 'danger');
      }
    } catch (error) {
      this.notify('Something went wrong, please try after sometime.', 'danger');
    }
  };

  enableUser = async () => {
    try {
      if (!this.state.activeUser || this.state.activeUser === null) return;
      const url = `users/${this.state.activeUser.userId}/active`;
      const response = await axios({
        method: 'PUT',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.success) {
        this.setState({ modal: false, activeUser: null });
        this.notify('User Enabled', 'success')
        this.fetchUsers();
      } else {
        this.notify(response.msg, 'danger');
      }
    } catch (error) {
      this.notify('Something went wrong, please try after sometime.', 'danger');
    }
  }

  revokeAdmin = async (row) => {
    try {
      const url = `users/${row.userId._id}/temple/${row.templeId._id}/temple-admin`;
      const response = await axios({
        method: 'DELETE',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.success) {
        this.notify('Revoke admin', 'warning')
        this.fetchUsers();
      } else {
        this.notify(response.msg, 'warning');
      }
    } catch (error) { }
  }

  makeAdmin = async (row) => {
    const data = {
      admin: {
        name: loggedInUser().name,
        email: loggedInUser().email,
        mobileNumber: loggedInUser().mobileNumber
      }
    }
    if (row.approved && row.approved.length) {
      data.approved = row.approved[0]._id
    }
    try {
      const url = `users/make-admin`;
      const response = await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN,
          userId: row.userId,
          templeId: row.templeId
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        data
      });
      if (response.data.success) {
        this.notify('Temple member is now an admin.')
        this.fetchUsers();
      } else {
        this.notify(response.data.message, 'warning');
      }
    } catch (error) {
      this.notify(error, 'warning');
    }
  }

  handleTableChange = (type, { page, sizePerPage, ...rest }) => {

    if (type === 'pagination') {
      this.setState({ sizePerPage, page }, this.fetchUsers)
    }

    if (type === 'sort') {
      this.setState({
        sortOrder: rest.sortOrder,
        sortField: rest.sortField
      }, this.fetchUsers)
    }
  }

  debounceFn = lodash.debounce((searchText) => {
    this.setState({ page: 1, search: searchText }, this.fetchUsers)
  }, 100)

  doSearch = (event) => {
    const searchText = event.target.value;
    this.debounceFn(searchText);
  }


  onSubmitFilterForm = (values) => {
    let filters = { ...values };
    this.setState({ filters, filterOn: true }, this.fetchUsers);
  };

  onResetForm = () => {
    this.setState({ filters: {}, users: [], search: '', total: 0, page: 1 });
  }

  toggleModal = (modelName, user) => {
    this.setState(prevState => ({
      modal: {
        ...prevState.modal,
        [modelName]: !prevState.modal[modelName]
      },
      activeUser: user,
    }));
  }

  // sendMessage = async (e) => {
  //   try {
  //     e.preventDefault();
  //     const url = '/users/send-message';
  //     const body = {
  //       mobile: this.state.activeUser.mobileNumber,
  //       message: this.state.messageText
  //     }
  //     const { data } = await axios({
  //       method: 'POST',
  //       url,
  //       params: {
  //         apikey: AUTH_TOKEN
  //       },
  //       data: body,
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         'Authorization': `Bearer ${localStorage.getItem('token')}`
  //       }
  //     });
  //     this.setState({ modal: false, activeUser: null, messageText: '' });
  //     if (data && data.success) {
  //       this.notify(data.message, 'success')
  //     }
  //   } catch (error) {
  //     this.notify('Something went wrong!')
  //   }
  // }

  // notify(messageText = 'success', type = 'success', place = 'tr') {
  //   const options = {
  //     place,
  //     type,
  //     autoDismiss: 7,
  //     icon: "ni ni-bell-55",
  //     message: (
  //       <div className="alert-text">
  //         <span data-notify="message">
  //           {messageText}
  //         </span>
  //       </div>
  //     ),
  //   }
  //   this.refs.notify.notificationAlert(options);
  // };

  tableCallBack = (actionType, ...rest) => {
    switch (actionType) {
      case 'REVOKE_ADMIN':
        this.revokeAdmin(...rest)
        break;
      case 'MAKE_ADMIN':
        this.makeAdmin(...rest)
        break;
      case 'MODAL_SHOW':
        this.toggleModal(...rest)
        break;
      default:
        break;
    }
  }

  onExportExcel = async () => {
    const arrayOfStrings = this.state.users;
    console.log("f....", arrayOfStrings)
    var arrayOfObjects = [];
    arrayOfStrings.forEach(function (element, index) {
      arrayOfObjects.push({
        Status: element.user[0]?.active,
        DateRegistered: element.user[0]?.createdAt,
        Role: element.role,
        Language: element.user[0]?.language,
        Name: element.user[0]?.name,
        DOB: element.user[0]?.dob,
        Age: dateToAge(element.user[0]?.dob),
        MobileNumber: element.user[0]?.mobileNumber,
        Email: element.user[0]?.email,
        TempleName: element.temple[0]?.name,
        Address: element.temple[0]?.address,
        Location: element.user[0]?.location,
        City: element.user[0]?.city,
        State: element.user[0]?.state,
        Country: element.user[0]?.country,
        IDProof: element.approved.idProof,
        DateApproved: element.approved.createdAt,
        AgentName: element.agent.name,
        FavTemples: arrayToCount(element.favorite_temple),
        UserPosr: arrayToCount(element.user_post),

      });
    })
    const ws = XLSX.utils.json_to_sheet(arrayOfObjects);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "" + moment(new Date()).format('ddd, MMMM Do YYYY') + fileExtension);
  }

  render() {
    const { users } = this.state;
    const paginationOption = {
      custom: true,
      totalSize: this.state.total,
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
    }
    const pagination = paginationFactory(paginationOption);

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notify" />
        </div>

        {/* Page header */}
        <div className="header header-dark bg-info pb-6 pt-5 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card >
                <CardHeader >
                  <Row>
                    <Col md="6">
                      <FormGroup className="mb-0">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Search by name, mobile number or temple name"
                            type="text"
                            value={this.state.search}
                            onChange={evt => this.doSearch(evt)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col className="text-md-right" md={{ offset: 5, size: 1 }}>
                      <Button color="primary" size="sm" id="buttonTogglerFilters">
                        Filters
                      </Button>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col md="12">
                      <UncontrolledCollapse defaultOpen toggler="#buttonTogglerFilters">
                        <Formik
                          initialValues={this.state.formInitialValue}
                          onSubmit={this.onSubmitFilterForm}
                          onReset={this.onResetForm}
                        >
                          {({ handleReset }) => {
                            return (
                              <Form>
                                <Row>
                                  <Col className="mb-3" md="1">
                                    <label className="form-control-label">
                                      Source
                                    </label>
                                    <Field className="form-control" as="select" name="source">
                                      <option value="">All</option>
                                      <option value="gm">Google</option>
                                      <option value="fb">Facebook</option>
                                      <option value="em">Email</option>
                                      <option value="ph">Phone</option>
                                    </Field>
                                  </Col>

                                  {/* <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Location
                                    </label>
                                    <Field className="form-control" as="input" name="address" />
                                  </Col> */}

                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Role
                                    </label>
                                    <Field className="form-control" as="input" component="select" name="role">
                                      <option value="">All</option>
                                      <option value="Head Priest">Head Priest</option>
                                      <option value="Dharmakarta">Dharmakarta</option>
                                      <option value="Pujari/Pandit/Purohit">Pujari/Pandit/Purohit</option>
                                      <option value="Temple Founder">Temple Founder</option>
                                      <option value="Temple Manager">Temple Manager</option>
                                      <option value="Temple Caretaker">Temple Caretaker</option>
                                      <option value="Temple Adviser">Temple Adviser</option>
                                      <option value="Other">Other</option>
                                    </Field>
                                  </Col>

                                  <Col className="mb-3" md="1">
                                    <label className="form-control-label">
                                      Gender
                                    </label>
                                    <Field className="form-control" as="select" name="gender">
                                      <option value="">All</option>
                                      <option value="male">Male</option>
                                      <option value="female">Female</option>
                                      <option value="other">Other</option>
                                      <option value="unknown">Unknown</option>
                                    </Field>
                                  </Col>

                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Agent Name
                                    </label>
                                    <Field className="form-control" as="input" name="agentName" />
                                  </Col>
                                  <Col className="mb-3" md="1">
                                    <label className="form-control-label">
                                      Language
                                    </label>
                                    <Field className="form-control" as="select" name="language">
                                      <option value="">All</option>
                                      <option value="english">English</option>
                                      <option value="hindi">Hindi</option>
                                      <option value="telugu">Telugu</option>
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md="1">
                                    <label className="form-control-label">
                                      User Status
                                    </label>
                                    <Field className="form-control" as="input" name="active" component="select">
                                      <option value="">All</option>
                                      <option value="true">Active</option>
                                      <option value="false">In-Active</option>
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md="3">
                                    <label className="form-control-label">
                                      Date Approved
                                    </label>
                                    <Row>
                                      <div className="col  pr-1">
                                        <DateRangePicker
                                          name="dateApproved"
                                          DATE_FORMAT='YYYY-MM-DD'
                                          placeholder1="start date"
                                          placeholder2="end date"
                                        />
                                      </div>
                                    </Row>
                                  </Col>

                                  <Col className="mb-3" md="3">
                                    <label className="form-control-label">
                                      Date Registered
                                    </label>
                                    <Row>
                                      <div className="col  pr-1">
                                        <DateRangePicker
                                          name="dateRegistered"
                                          DATE_FORMAT='YYYY-MM-DD'
                                          placeholder1="start date"
                                          placeholder2="end date"
                                        />
                                      </div>
                                    </Row>
                                  </Col>


                                  <Col className="mb-3" md="1">
                                    <label className="form-control-label">
                                      Country
                                    </label>
                                    <Field className="form-control" as="select" name="country">
                                      <option value="">All</option>
                                      <option value="india">India</option>
                                      <option value="other">Other</option>
                                      <option value="null">None</option>
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md="1">
                                    <label className="form-control-label">
                                      State
                                    </label>
                                    <Field className="form-control" as="select" name="state">
                                      <option value=''>All</option>
                                      {this.state.allState?.map((item) => {
                                        return <option value={item}>{item}</option>
                                      })}
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md="1">
                                    <label className="form-control-label">
                                      City
                                    </label>
                                    <Field className="form-control" as="input" name="city" />
                                  </Col>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Requested for TA
                                    </label>
                                    <Field className="form-control" as="input" name="requestRaised" component="select">
                                      <option value="">All</option>
                                      <option value="true">Yes</option>
                                      <option value="false">No</option>
                                    </Field>
                                  </Col>
                                  <Col className="mb-3 pb-1 align-self-end" md="2" >
                                    <Button type="submit">Go</Button>
                                    <Button onClick={handleReset} >Reset</Button>
                                  </Col>
                                </Row>

                              </Form>
                            );
                          }}
                        </Formik>
                      </UncontrolledCollapse>
                    </Col>
                  </Row>
                </CardHeader>
                <PaginationProvider
                  pagination={pagination}
                >
                  {({
                    paginationProps,
                    paginationTableProps
                  }) => (
                    <>
                      <div className="table-responsive">
                        <Scrollbars style={{ height: '50vh' }}>
                          <BootstrapTable
                            remote
                            keyField='approved.createdAt'
                            data={users}
                            columns={columns(this.tableCallBack)}
                            bootstrap4={true}
                            bordered={false}
                            onTableChange={this.handleTableChange}
                            noDataIndication="no records"
                            {...paginationTableProps}
                          />
                        </Scrollbars>
                      </div>

                      <CardFooter>
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                        <Row className="mb-3 pb-1 align-self-end" md="2" >
                          {this.state.exportvisibility ? <div><Button onClick={this.onExportExcel} type="submit">Export to Excel </Button> <span className={`badge badge-pill badge-dark`} style={{ color: '#fff', backgroundColor: '#5e72e4' }}>{`Total Count : ${users.length}`}</span></div> : null}
                        </Row>
                      </CardFooter>
                    </>
                  )
                  }
                </PaginationProvider>
              </Card>
            </div>
          </Row>
        </Container>
        <Modal
          isOpen={this.state.modal.sendEmail}
          toggle={e => this.toggleModal('sendEmail')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('sendEmail')}>Send Email</ModalHeader>
          <ModalBody>
            <FormB onSubmit={this.sendMessage}>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Message text
                </label>
                <Input
                  rows="3"
                  type="textarea"
                  name='messageText'
                  required
                  value={this.state.messageText}

                  // {...this.setState({
                  //   this.state.messageText : 'If your posted content is offensive, it could be removed and your account may be deactivated. Please post positive and good content only.'})}
                  readOnly={false}
                  onChange={e => this.setState({ [e.target.name]: e.target.value })}
                />
              </FormGroup>
              <div className="modal-footer">
                <Button color="primary" type="submit">
                  Send
                </Button>
                <Button color="secondary" onClick={e => this.toggleModal('sendEmail')}>
                  Cancel
                </Button>
              </div>
            </FormB>

          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modal.description}
          toggle={e => this.toggleModal('description')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('description')}>Inactivate User</ModalHeader>
          <ModalBody>Are you sure you want to Inactivate this user?</ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.deleteUser}>
              Inactivate User
            </Button>
            <Button color="secondary" onClick={e => this.toggleModal('description')}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal.enableModal}
          toggle={e => this.toggleModal('enableModal')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('enableModal')}>Activate User</ModalHeader>
          <ModalBody>Are you sure you want to Activate this user?</ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.enableUser}>
              Activate User
            </Button>
            <Button color="secondary" onClick={e => this.toggleModal('enableModal')}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal.sendMessage}
          toggle={e => this.toggleModal('sendMessage')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('sendMessage')}>Send Message</ModalHeader>
          <ModalBody>
            <FormB onSubmit={this.sendMessage}>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Message text
                </label>
                <Input
                  rows="3"
                  type="textarea"
                  name='messageText'
                  required
                  value={this.state.messageText || ''}
                  onChange={e => this.setState({ [e.target.name]: e.target.value })}
                />
              </FormGroup>
              <div className="modal-footer">
                <Button color="primary" type="submit">
                  Send
                </Button>
                <Button color="secondary" onClick={e => this.toggleModal('sendMessage')}>
                  Cancel
                </Button>
              </div>
            </FormB>

          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default TempleMember;
