import React from 'react';
import moment from 'moment';

import { Badge, Media, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,Button } from 'reactstrap';
import ImagePreview from 'components/ImagePreview';


const columns = (callBack) => [
  {
    dataField: 'action',
    isDummyField: true,
    text: 'Action',
    formatter: (cell, row) => {
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            style = {{color: 'blue !important'}}
            className="btn-icon-only text-light action-button-div"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={e => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-h action-button" />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-arrow"
            right
          >
            {row.isActive ? <DropdownItem
              onClick={e => callBack('MODAL_SHOW', 'DELETE_MODAL', row)}
            > Inactive
            </DropdownItem> : <DropdownItem
              onClick={e => callBack('MODAL_SHOW', 'PUBLISH_MODAL', row)}
            > Active
            </DropdownItem>}
            
            
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  },
  {
    dataField: 'isActive',
    text: 'Status',
    sort: true,
    formatter: (cell,row) => {
      return (
        <span style={{color: `${cell === true ? 'black' : 'red'}`,fontWeight:'600'}}>{cell === true ? 'ACTIVE' : 'INACTIVE'}</span>
      )
    }
  },
  {
    dataField: 'createdAt',
    text: 'Date time',
    sort: true,
    formatter: (cell) => {
      return (
        <div className="mb-0" style={{ width: '100px', whiteSpace: 'pre-line' }}>{moment(cell).format('MMMM Do YYYY, h:mm a')}</div>
      )
    }
  },
  {
    dataField: 'eventName',
    text: 'Event Name',
    formatter: (cell) => {
      return (
        <>
          <div style={{ width: '100px', whiteSpace: 'pre-line' }} className="mb-0">{cell}</div>
        </>
      )
    }
  },
  {
    dataField: 'templeId.name',
    text: 'Temple Name',
    formatter: (cell) => {
      return (
        <>
          <div style={{ width: '150px', whiteSpace: 'pre-line' }} className="mb-0">{cell}</div>
        </>
      )
    }
  },
  {
    dataField: 'locality',
    text: 'Locality',
    sort: false,
    formatter: (cell, row) => {
      return (
        <div style={{ width: '100px', whiteSpace: 'pre-line' }}>
          <div>{cell}</div>
        </div>
      )
    }
  },
  {
    dataField: 'address',
    text: 'Address',
    sort: true,
    formatter: (cell, row) => {
      return (
        <div style={{ width: '100px', whiteSpace: 'pre-line' }}>
          <div>{cell}</div>
        </div>
      )
    }
  },
  {
    dataField: 'contactPerson',
    text: 'Contact Person',
    sort: true,
    formatter: (cell) => {
      return (
        <>
          <div style={{ width: '80px', whiteSpace: 'pre-line' }} className="mb-0">{cell}</div>
        </>
      )
    }
  },
  {
    dataField: 'mobileNumber',
    text: 'Phone No.',
  },
  {
    dataField: 'repeatable',
    text: 'Repeats',
    formatter: (cell) => {
      const color = cell ? 'success' : 'warning';
      const text = cell ? 'yes' : 'no'
      return (
        <Badge color={color} >
          <span className="status">{text}</span>
        </Badge>
      )
    }
  },
  {
    dataField: 'startTime',
    text: 'Start date & time',
    sort: true,
    formatter: (cell) => {
      return (
        <div className="mb-0" style={{ width: '100px', whiteSpace: 'pre-line' }}>{moment(cell).format('MMMM Do YYYY, h:mm a')}</div>
      )
    }
  },
  {
    dataField: 'endTime',
    text: 'End date & time',
    sort: true,
    formatter: (cell) => {
      return (
        <div className="mb-0" style={{ width: '100px', whiteSpace: 'pre-line' }}>{moment(cell).format('MMMM Do YYYY, h:mm a')}</div>
      )
    }
  },
  {
    dataField: 'reportsINP',
    text: 'INP',
    sort: true,
    formatter: (cell) => {
      return (
        <Button
        style= {{backgroundColor:'#EF7B45',color:'#fff'}}
          size="sm"
          disabled={!cell?.length}
          onClick={e => e.preventDefault()}
        >
          {cell.length > 10 ? '10+' : cell.length}
        </Button >
      )
    }
  },
  {
    dataField: 'reportsINC',
    text: 'INC',
    sort: true,
    formatter: (cell) => {
      return (
        <Button
          color="success"
          size="sm"
          disabled={!cell?.length}
          onClick={e => e.preventDefault()}
        >
          {cell.length > 10 ? '10+' : cell.length}
        </Button >
      )
    }
  },
  {
    dataField: 'assets',
    text: 'Images',
    classes: 'p-2',
    formatter: (cell, row) => {
      const assets = cell.length > 0 ? cell.map(img => img.url) : [];
      return (
        <Media className="align-items-center">
          <ImagePreview
            type="group"
            imageStyle={{ height: 48 }}
            assets={assets}
          />
        </Media>
      )
    }
  },

];

export default columns;
