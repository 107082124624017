import React from 'react';
import axios from 'axios';
import qs from 'qs';
import lodash from 'lodash';
import { Field, Form, Formik } from 'formik';
import NotificationAlert from "react-notification-alert";
import { Scrollbars } from 'react-custom-scrollbars';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory,
{ PaginationProvider, PaginationListStandalone }
  from "react-bootstrap-table2-paginator";
import {
  Container, Row, Col,
  Card, CardHeader, CardFooter,
  Input, InputGroup, InputGroupAddon, InputGroupText,
  FormGroup, Form as FormB,
  Button,
  Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledCollapse,
  Label, Popover, PopoverHeader, PopoverBody
} from 'reactstrap';

import DateRangePicker from 'components/Formik/DateRangePicker';
import columns from 'views/feedbacks/column';

import { cleanObject } from 'utils';
import { loggedInUser } from '../../utils/User'
import { CATEGORY_OPTIONS, FeedbackMessages } from 'utils/enums/category';

import { options, statusColor } from '../../utils/enums/replyOptions'

import { AUTH_TOKEN } from '../../config'

class FeedbackList extends React.Component {
  state = {
    formInitialValue: { category: '', deleted: 'false' },
    modal: {},
    feedbacks: [],
    feedbacksCategory: [],
    loading: true,
    search: '',
    filters: {
      deleted: false,
    },
    page: 1,
    sizePerPage: 50,
    total: 0,
    sortField: 'createdAt',
    sortOrder: 'desc',
    isLoading: false,
    internalNotes: '',
    messageToTM: 'Namasthe ! Thank you for your feedback. We may contact you if any further information is required - Devotted.',
    messageToTMViaOptions: '',
    sendVia: '',
  };

  componentWillMount() {
    const {
      filters: oldFilters
    } = this.state
    const { location: { search } } = this.props;
    const filters = qs.parse(search, { ignoreQueryPrefix: true });
    this.setState({ filters: { ...filters, ...oldFilters } });
    this.fetchFeedbacksCategory();
    // this.fetchFeedbacks();
  }

  async fetchFeedbacksCategory() {
    try {
      let url = '/c0nstants?type=feedbackCategory';
      const response = await axios({
        method: 'GET',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      this.setState({
        loading: false,
        feedbacksCategory: response.data.constants[0].fields || [],
      });
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  async fetchFeedbacks() {
    try {
      const { search, page, sizePerPage, sortField, sortOrder, filters } = this.state;
      let url = '/feedbacks';
      let query = { page, sizePerPage };
      if (sortField && sortOrder) { query = { ...query, sortField, sortOrder } }
      const copyOfFilters = JSON.parse(JSON.stringify(filters))
      if (copyOfFilters.createdAt) {
        if (copyOfFilters.createdAt.toDate) {
          const toDate = new Date(copyOfFilters.createdAt.toDate)
          toDate.setDate(toDate.getDate() + 1)
          copyOfFilters.createdAt.toDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`
        } else if (copyOfFilters.createdAt.fromDate && !copyOfFilters.createdAt.toDate) {
          const todayDate = new Date()
          copyOfFilters.createdAt.toDate = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`
        }
      }

      if (copyOfFilters) {
        cleanObject(copyOfFilters);
        query = { ...query, filters: copyOfFilters }
      }
      if (search) { query = { ...query, search } }

      if (query) { url = `${url}?${qs.stringify(query)}`; }

      const response = await axios({
        method: 'GET',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      this.setState({
        loading: false,
        feedbacks: response.data.feedbacks || [],
        total: response.data.pagination.total,
      });
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  rejectRequest = async () => {
    try {
      const payload = {
        type: 'rejected',
        userId: this.state.activeUser.userId._id,
        templeId: this.state.activeUser.templeId._id
      }
      if (this.state.internalNotes) {
        payload.internalNotes = this.state.internalNotes
      }

      const url = `/feedbacks/${this.state.activeUser._id}`
      this.setState({
        isLoading: true
      })

      const response = await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        data: payload,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      this.setState({
        isLoading: false
      })
      if (response.data.success) {
        this.notify('Rejected successfully.')
        this.fetchFeedbacks()
        this.toggleModal('details')
      } else {
        this.notify('Could not reject!', 'danger')
      }
    } catch (e) {
      console.log('e', e)
      this.setState({
        isLoading: false
      })
      this.notify('Could not reject!', 'danger')
    }

  }
  handleDeleteFeedbacks = async () => {
    try {
      const { activeUser } = this.state;
      if (!activeUser || activeUser === null) return;
      this.setState({ loading: true })
      const id = activeUser._id;
      const url = `/feedbacks/${id}`;
      console.log(activeUser)
      await axios({
        method: 'DELETE',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      this.notify('Yes, Deleted it!', 'success');
      this.setState({
        loading: false,
        modal: false,
        activeUser: null,
      }, this.fetchTableData)

    } catch (error) {
      this.setState({ isLoading: false, modal: false, activeUser: null });
      this.notify('Something went wrong', 'warning');
    }
  }
  approveRequest = async () => {
    try {
      const payload = {
        admin: {
          name: loggedInUser().name,
          mobileNumber: loggedInUser().mobileNumber,
          email: loggedInUser().email
        },
        type: 'approved',
        userId: this.state.activeUser.userId._id,
        templeId: this.state.activeUser.templeId._id
      }
      if (this.state.internalNotes) {
        payload.internalNotes = this.state.internalNotes
      }

      const url = `/feedbacks/${this.state.activeUser._id}`
      this.setState({
        isLoading: true
      })
      const response = await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        data: payload,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });;
      this.setState({
        isLoading: false
      })
      if (response.data.success) {
        this.notify('Approved successfully.')
        this.fetchFeedbacks()
        this.toggleModal('details')
      } else {
        this.notify('Could not approve!', 'danger')
      }
    } catch (e) {
      this.setState({
        isLoading: false
      })
      this.notify('Could not approve!', 'danger')
    }
  }

  reply = async () => {
    try {
      const {
        internalNotes,
        messageToTM,
        messageToTMViaOptions,
        sendVia
      } = this.state
      if (sendVia) {
        const message = messageToTM + messageToTMViaOptions
        if (message.length) {
          if (sendVia === 'sms' && message.length > 160) {
            this.notify('Character length should be less than 160.', 'danger')
          } else {
            const payload = {
              type: 'reply',
              userId: this.state.activeUser.userId._id,
              // templeId: this.state.activeUser.templeId._id,
              via: sendVia
            }
            if (messageToTM) {
              payload.messageToTM = messageToTM
            }
            if (messageToTMViaOptions) {
              payload.messageToTMViaOptions = messageToTMViaOptions
            }
            if (internalNotes) {
              payload.internalNotes = internalNotes
            }
            const url = `/feedbacks/${this.state.activeUser._id}`
            this.setState({
              isLoading: true
            })
            const response = await axios({
              method: 'POST',
              url,
              params: {
                apikey: AUTH_TOKEN
              },
              data: payload,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              }
            });
            this.setState({
              isLoading: false
            })
            if (response.data.success) {
              this.notify('Replied successfully.')
              this.setState({
                readMore: false
              })
              this.toggleModal('details')
              this.fetchFeedbacks()
            } else {
              this.notify('Could not reply!', 'danger')
            }
          }
        } else {
          this.notify('Please add some message to reply!', 'warning')
        }
      } else {
        this.notify('Please choose a mode to reply', 'warning')
      }

    } catch (e) {
      console.log('e', e)
      this.setState({
        isLoading: false
      })
      this.notify('Could not reply!', 'danger')
    }
  }

  doSearch = (evt) => {
    this.setState({ page: 1, search: evt.target.value }, this.fetchFeedbacks)
  }

  handleTableChange = (type, { page, sizePerPage, filters, ...rest }) => {
    if (type === 'pagination') {
      this.setState({ sizePerPage, page }, this.fetchFeedbacks)
    }

    if (type === 'sort') {
      this.setState({
        sortOrder: rest.sortOrder,
        sortField: rest.sortField
      }, this.fetchFeedbacks)
    }
  }

  onSubmitFilterForm = (values) => {
    const filters = { ...values };
    this.setState({ filters }, this.fetchFeedbacks)
  }

  onResetForm = () => {
    this.setState({ filters: {}, feedbacks: [] });
  }

  async acknowledge(id) {
    try {
      this.setState({ isLoading: true });
      const url = `/feedbacks/${id}/acknowledge`;

      const response = await axios({
        method: 'PUT',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.data.success) {
        this.notify('updated feedback');
        this.setState({ isLoading: false });
        this.fetchFeedbacks();
      } else {
        this.setState({ isLoading: false });
        this.notify('updated feedback');
      }
    } catch (error) {
      this.setState({ isLoading: false });
      this.notify('Something went wrong', 'warning');
    }
  }

  async revokeAcknowledge(id) {
    try {
      this.setState({ isLoading: true });
      const url = `/feedbacks/${id}/acknowledge`;
      const response = await axios({
        method: 'DELETE',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.data.success) {
        this.notify('feedback revoked');
        this.setState({ isLoading: false });
        this.fetchFeedbacks();
      } else {
        this.setState({ isLoading: false });
        this.notify('feedback revoked');
      }
    } catch (error) {
      this.setState({ isLoading: false });
      this.notify('Something went wrong', 'warning');
    }
  }

  delete = async () => {
    try {
      if (!this.state.activeUser || this.state.activeUser === null) return;
      this.setState({ isLoading: true });
      const id = this.state.activeUser._id;
      const url = `/feedbacks/${id}`;
      const response = await axios({
        method: 'DELETE',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.data.success) {
        this.notify('Feedback removed');
        this.setState({ isLoading: false, modal: false, activeUser: null });
        this.fetchFeedbacks();
      } else {
        this.setState({ isLoading: false, modal: false, activeUser: null });
        this.notify('Feedback removed');
      }
    } catch (error) {
      this.setState({ isLoading: false, modal: false, activeUser: null });
      this.notify('Something went wrong', 'warning');
    }
  }

  toggleModal = (modelName, user) => {
    this.setState(prevState => ({
      modal: {
        ...prevState.modal,
        [modelName]: !prevState.modal[modelName]
      },
      activeUser: user,
      readMore: true,
      messageToTM: user ? FeedbackMessages[user.category] : ''
    }));
  }

  sendMessage = async (e) => {
    try {
      e.preventDefault();
      const url = '/users/post-feedback/send-message';
      const body = {
        mobile: this.state.activeUser.mobileNumber,
        message: this.state.messageText
      }

      const response = await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        data: body,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.data.success) {
        this.notify('Something went wrong. Please try again later.', 'warning')
      } else {
        this.notify('SMS sent successfully.', 'success');
      }
      this.setState({ modal: false, activeUser: null, messageText: '' });
    } catch (error) {
      this.notify('Something went wrong. Please try again later.', 'warning');
    }
  }

  sendEmail = async (e) => {
    try {
      e.preventDefault()
      const url = '/users/post-feedback/send-email'
      const body = {
        email: this.state.activeUser.email,
        emailText: this.state.emailText
      }
      const response = await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        data: body,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.data.success) {
        this.notify('Something went wrong. Please try again later.', 'warning')
      } else {
        this.notify('Mail sent successfully.', 'success');
      }
      this.setState({
        modal: false,
        activeUser: null,
        emailText: ''
      })
    } catch (error) {
      this.notify('Something went wrong. Please try again later.', 'warning');
    }
  }

  notify(messageText = 'success', type = 'success', place = 'tr') {
    const options = {
      place,
      type,
      autoDismiss: 7,
      icon: "ni ni-bell-55",
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {messageText}
          </span>
        </div>
      ),
    }
    this.refs.notify.notificationAlert(options);
  };

  tableCallBack = (actionType, ...rest) => {
    switch (actionType) {
      case 'ACKNOWLEDGE':
        this.acknowledge(...rest)
        break;
      case 'REVOKE':
        this.revokeAcknowledge(...rest)
        break;
      case 'MODAL_SHOW':
        this.toggleModal(...rest)
        break;
      default:
        break;
    }
  }

  render() {
    const { feedbacks } = this.state;
    const paginationOption = {
      custom: true,
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.total,
    }
    const pagination = paginationFactory(paginationOption);

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notify" />
        </div>
        {/* Page header */}
        <div className="header header-dark bg-info pb-6 pt-5 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
              </Row>
            </div>
          </Container>
        </div>

        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader >
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup className="mb-0">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Search by comment"
                            type="text"
                            onChange={evt => this.doSearch(evt)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <Col className="text-md-right" md={{ offset: 5, size: 1 }}>
                      <Button color="primary" size="sm" id="buttonTogglerFilters">
                        Filters
                      </Button>
                    </Col>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <UncontrolledCollapse defaultOpen toggler="#buttonTogglerFilters">
                        <Formik
                          initialValues={this.state.formInitialValue}
                          onSubmit={this.onSubmitFilterForm}
                          onReset={this.onResetForm}
                        >
                          {({ handleReset }) => {
                            return (
                              <Form>
                                <Row >
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Category
                                    </label>
                                    <Field className="form-control" as="select" name="category">
                                      <option value=''>
                                        All
                                      </option>
                                      {this.state.feedbacksCategory?.map((category,index) =>
                                        <option key={category.fieldName} value={index + 1}>
                                          {category.fieldName}
                                        </option>
                                      )}
                                    </Field>
                                  </Col>

                                  <Col className="mb-3" md="3">
                                    <label className="form-control-label">
                                      Date
                                    </label>
                                    <Row>
                                      <div className="col pr-1">
                                        <DateRangePicker
                                          name="createdAt"
                                          DATE_FORMAT='YYYY-MM-DD'
                                          placeholder1="start date"
                                          placeholder2="end date"
                                        />
                                      </div>
                                    </Row>
                                  </Col>

                                  <Col className="mb-2" md="2">
                                    <label className="form-control-label">
                                      Status
                                    </label>
                                    <Row>
                                      <div className="col pr-1">
                                        <Field className="form-control" as="select" name="deleted">
                                          <option value="">All</option>
                                          <option value="true">Deleted</option>
                                          <option value="false">Undeleted</option>
                                        </Field>
                                      </div>
                                    </Row>
                                  </Col>

                                  <Col className="mb-3 pb-1 align-self-end" md="2" >
                                    <Button type="submit">Go</Button>
                                    <Button onClick={handleReset}>Reset</Button>
                                  </Col>
                                </Row>

                              </Form>
                            );
                          }}
                        </Formik>
                      </UncontrolledCollapse>
                    </div>
                  </div>
                </CardHeader>

                <PaginationProvider
                  pagination={pagination}
                >
                  {({
                    paginationProps,
                    paginationTableProps
                  }) => (
                    <>
                      <div className="table-responsive">
                        <Scrollbars style={{ height: '50vh' }}>
                          <BootstrapTable
                            keyField='_id'
                            remote
                            bootstrap4={true}
                            bordered={false}
                            data={feedbacks}
                            columns={columns(this.tableCallBack)}
                            onTableChange={this.handleTableChange}
                            noDataIndication="no records"
                            {...paginationTableProps}
                          />
                        </Scrollbars>
                      </div>

                      <CardFooter>
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </CardFooter>
                    </>
                  )
                  }
                </PaginationProvider>
              </Card>
            </div>
          </Row>
        </Container>

        <Modal
          isOpen={this.state.modal.description}
          toggle={e => this.toggleModal('description')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('description')}>Deleting Feedback</ModalHeader>
          <ModalBody>This action cannot be reversed!!</ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.delete}>
              Delete
            </Button>
            <Button color="secondary" onClick={e => this.toggleModal('description')}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal.sendMessage}
          toggle={e => this.toggleModal('sendMessage')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('sendMessage')}>Send Message</ModalHeader>
          <ModalBody>
            <FormB onSubmit={this.sendMessage}>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Message text
                </label>
                <Input
                  rows="3"
                  type="textarea"
                  name='messageText'
                  required
                  value={this.state.messageText || ''}
                  onChange={e => this.setState({ [e.target.name]: e.target.value })}
                />
              </FormGroup>
              <div className="modal-footer">
                <Button color="primary" type="submit">
                  Send
                </Button>
                <Button color="secondary" onClick={e => this.toggleModal('sendMessage')}>
                  Cancel
                </Button>
              </div>
            </FormB>

          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modal.sendEmail}
          toggle={e => this.toggleModal('sendEmail')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('sendEmail')}>Send Email</ModalHeader>
          <ModalBody>
            <FormB onSubmit={this.sendEmail}>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Message text
                </label>
                <Input
                  rows="3"
                  type="textarea"
                  name='emailText'
                  required
                  value={this.state.emailText || ''}
                  onChange={e => this.setState({ [e.target.name]: e.target.value })}
                />
              </FormGroup>
              <div className="modal-footer">
                <Button color="primary" type="submit">
                  Send
                </Button>
                <Button color="secondary" onClick={e => this.toggleModal('sendEmail')}>
                  Cancel
                </Button>
              </div>
            </FormB>

          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modal.details}
          toggle={e => this.toggleModal('details')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('details')}>View Feedback</ModalHeader>
          <ModalBody style={{
            padding: '0rem 1.5rem 1.5rem 1.5rem'
          }}>
            <div className='row'>
              <div className='col'>
                <strong>Temple Name</strong>
              </div>
              <div className="col">
                {
                  this.state.activeUser
                  && this.state.activeUser.templeId
                  && this.state.activeUser.templeId.name
                }
                <span style={{
                  fontStyle: 'italic'
                }}>
                  {
                    this.state.activeUser
                    && this.state.activeUser.templeId
                    && this.state.activeUser.templeId.address
                  }
                </span>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <strong>Person Name</strong>
              </div>
              <div className="col">
                {
                  this.state.activeUser
                  && this.state.activeUser.name
                }
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <strong>Mobile Number</strong>
              </div>
              <div className="col">
                {
                  this.state.activeUser
                  && this.state.activeUser.mobileNumber
                }
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <strong>Email Id</strong>
              </div>
              <div className="col">
                {
                  this.state.activeUser
                  && this.state.activeUser.email
                }
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <strong>Role</strong>
              </div>
              <div className="col">
                {
                  this.state.activeUser
                    && this.state.activeUser.userId
                    && this.state.activeUser.userId.role ? this.state.activeUser.userId.role : 'Devotee'
                }
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <strong>Attachments</strong>
              </div>
              <div className="col">
                {
                  this.state.activeUser
                  && Array.isArray(this.state.activeUser.attachments)
                  && this.state.activeUser.attachments.map(
                    i => (
                      <><a color="primary" href={i.url} download>{i.name}</a><br /></>
                    )
                  )
                }
              </div>
            </div>
            {
              this.state.activeUser && (this.state.activeUser.category === 'make me temple admin' || this.state.activeUser.category === '7') && (
                <div className='row'>
                  <div className='col'>
                    <strong>Request Status</strong>
                  </div>
                  <div className="col">
                    {
                      this.state.activeUser
                      && this.state.activeUser.requestId
                      && this.state.activeUser.requestId.status && (
                        <span className={`badge badge-pill ${statusColor[this.state.activeUser.requestId.status]}`}>{this.state.activeUser.requestId.status}</span>

                      )
                    }
                  </div>
                </div>
              )
            }
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlTextarea1"
                style={{
                  color: 'red'
                }}
              >
                Internal Notes
              </label>
              {
                this.state.activeUser
                && (
                  !this.state.activeUser.internalNotes ? (
                    <Input
                      rows="3"
                      type="textarea"
                      name='internalNotes'
                      required
                      value={this.state.internalNotes || ''}
                      onChange={e => this.setState({ [e.target.name]: e.target.value })}
                    />
                  ) : (
                    <p>{this.state.activeUser.internalNotes}</p>
                  )
                )
              }
            </FormGroup>
            {
              this.state.activeUser
              && this.state.activeUser.userId
              && (
                this.state.activeUser.userId.email
                || this.state.activeUser.userId.mobileNumber
              ) && (this.state.activeUser.category !== 'make me temple admin' && this.state.activeUser.category !== '7')
              && (
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Message to TM
                  </label>
                  {/* {
                    this.state.activeUser
                      && (this.state.activeUser.messageToTMViaOptions
                        || this.state.activeUser.messageToTM) ? (
                        <p>
                          {this.state.activeUser.messageToTMViaOptions}
                          <br />
                          {this.state.activeUser.messageToTM}
                        </p>
                      ) : (<>{Object.keys(options).map(i => (
                        <React.Fragment key={options[i]}>
                          <FormGroup check>
                            <Label check>
                              <Input type="radio" name="messageToTMViaOptions" value={this.state.messageToTMViaOptions === options[i]} onClick={e => this.setState({ 'messageToTMViaOptions': options[i] })} />{' '}
                              {options[i]}
                            </Label>
                          </FormGroup>
                        </React.Fragment>
                      ))}
                        < i > Or, <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={() => this.setState({ 'readMore': !this.state.readMore })} href='#'>{
                          !this.state.readMore ? 'Add more...' : 'Add less...'
                        }</span></i></>)
                  } */}
                  {
                    this.state.readMore && (
                      <Input
                        rows="3"
                        type="textarea"
                        name='messageToTM'
                        required
                        readOnly={true}
                        value="Namasthe ! Thank you for your feedback. We may contact you if any further information is required - Devotted."

                        onChange={e => this.setState({ [e.target.name]: e.target.value })}
                        style={{
                          backgroundColor: '#f1eeee'
                        }}
                      />
                    )
                  }
                </FormGroup>
              )
            }
            {
              this.state.activeUser
              && (this.state.activeUser.messageToTM
                || this.state.activeUser.messageToTMViaOptions ? (
                null
              ) : (
                <FormGroup tag="fieldset">
                  {
                    ((this.state.activeUser.userId && this.state.activeUser.userId.email)
                      || (this.state.activeUser.userId && this.state.activeUser.userId.mobileNumber)) &&
                    (<legend>Reply via!</legend>)
                  }
                  {
                    this.state.activeUser.userId && this.state.activeUser.userId.email ? (
                      <FormGroup check>
                        <Label check>
                          <Input type="radio" name="sendVia" value={this.state.sendVia === 'email'} onClick={e => this.setState({ 'sendVia': 'email' })} />{' '}
                          Email
                        </Label>
                      </FormGroup>
                    ) : null
                  }
                  {
                    this.state.activeUser.userId && this.state.activeUser.userId.mobileNumber ? (
                      <FormGroup check>
                        <Label check>
                          <Input type="radio" name="sendVia" value={this.state.sendVia === 'sms'} onClick={e => this.setState({ 'sendVia': 'sms' })} />{' '}
                          SMS
                        </Label>
                      </FormGroup>
                    ) : null
                  }
                </FormGroup>
              ))
            }
            <div className="modal-footer">
              {
                this.state.activeUser
                && (this.state.activeUser.category === 'make me temple admin' || this.state.activeUser.category === '7')
                && this.state.activeUser.requestId
                && this.state.activeUser.requestId.status === 'pending'
                && this.state.activeUser.templeId
                && (
                  <>
                    <Button color="success" type="success" onClick={this.approveRequest} disabled={this.state.isLoading}>
                      Approve
                    </Button>
                    <Popover placement="bottom" isOpen={this.state.approvePopoverOpen} target="approvepopover" toggle={() => this.setState({
                      approvePopoverOpen: !this.state.approvePopoverOpen
                    })} trigger="hover">
                      <PopoverHeader>Approve Request</PopoverHeader>
                      <PopoverBody>{FeedbackMessages[this.state.activeUser.category].approve}</PopoverBody>
                    </Popover>
                  </>
                )
              }
              {
                this.state.activeUser && !this.state.activeUser.messageToTM && !this.state.activeUser.messageToTMViaOptions
                && ((this.state.activeUser.userId && this.state.activeUser.userId.email)
                  || (this.state.activeUser.userId && this.state.activeUser.userId.mobileNumber))
                && (
                  <>
                    <Button color="primary" type="primary" onClick={this.reply} disabled={this.state.isLoading} id="rejectpopover">
                      Reply
                    </Button>
                    <Popover placement="bottom" isOpen={this.state.rejectPopoverOpen} target="rejectpopover" toggle={() => this.setState({
                      rejectPopoverOpen: !this.state.rejectPopoverOpen
                    })} trigger="hover">
                      <PopoverHeader>Reply</PopoverHeader>
                      <PopoverBody>{FeedbackMessages[this.state.activeUser.category]?.reply}</PopoverBody>
                    </Popover>
                  </>
                )
              }
              {
                this.state.activeUser
                && (this.state.activeUser.category === 'make me temple admin' || this.state.activeUser.category === '7')
                && this.state.activeUser.requestId
                && this.state.activeUser.requestId.status === 'pending'
                && this.state.activeUser.templeId
                && (
                  <>
                    <Button id="approvepopover" color="danger" type="danger" onClick={this.rejectRequest} disabled={this.state.isLoading}>
                      Reject
                    </Button>
                  </>
                )
              }
              <Button color="secondary" onClick={() => this.toggleModal('details')} disabled={this.state.isLoading}>
                Cancel
              </Button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modal['SHOW_READMORE']}
          toggle={e => this.toggleModal('SHOW_READMORE')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('SHOW_READMORE')}>Show Description/Text</ModalHeader>
          <ModalBody>
            <Scrollbars style={{ height: '30vh' }}>
              <Row>
                {this.state.activeUser?.comments?.map(c => {
                  return (
                    <Col
                      key={c._id}
                      className="mt-2 mb-2"
                      md="6"
                      xs="12"
                    >
                      {c.text}
                    </Col>
                  );
                })}
              </Row>
            </Scrollbars>
          </ModalBody>
          <ModalFooter>

            <Button color="secondary" onClick={e => this.toggleModal('SHOW_READMORE')}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modal['DELETE_MODAL']}
          toggle={e => this.toggleModal('DELETE_MODAL')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('DELETE_MODAL')}>Delete </ModalHeader>
          <ModalBody>Are you sure you want to Delete?</ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.handleDeleteFeedbacks}>
              Delete
            </Button>
            <Button color="secondary" onClick={e => this.toggleModal('DELETE_MODAL')}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default FeedbackList;
