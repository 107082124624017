import React from "react";
import moment from "moment";
import { LANGUAGE } from 'utils/enums/language';
import { Link } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import {
  Media,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import ImagePreview from "components/ImagePreview";


const dateToAge = (date) => {
  var replaceStr = date?.replace("-",",").replace("-",",")
  var splitSrt = replaceStr?.split(",")
  var dateFromArray = ''+splitSrt[1]+'/'+ splitSrt[0]+'/'+splitSrt[2]
  var dob = new Date(dateFromArray);
  //calculate month difference from current date in time
  var month_diff = Date.now() - dob.getTime();
  
  //convert the calculated difference in date format
  var age_dt = new Date(month_diff); 
  
  //extract year from date    
  var year = age_dt.getUTCFullYear();
  
  //now calculate the age of the user
  var age = Math.abs(year - 1970);
  if(!age){
    return ''
  }
  //display the calculated age
  else {
    return age
  }
  
}

const columns = (callBack) => [
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    formatter: (cell, row) => {
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light action-button-div"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={(e) => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-h action-button" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            {row.user && row.user[0] && !row.user[0].active && (
              <DropdownItem
                onClick={(e) => callBack("MODAL_SHOW", "enableModal", row)}
              >
                Activate User
              </DropdownItem>
            )}
            {row.user && row.user[0] && row.user[0].active && (
              <DropdownItem
                onClick={(e) => callBack("MODAL_SHOW", "description", row)}
              >
                Inactivate User
              </DropdownItem>
            )}
            {row.user[0]?.email.length > 1 && row.user[0]?.mobileNumber.length > 1 ? <div><DropdownItem
              onClick={(e) => callBack("MODAL_SHOW", "sendMessage", row)}
            >
              Send SMS
            </DropdownItem><DropdownItem
              onClick={(e) => callBack("MODAL_SHOW", "sendEmail", row)}
            >
              Send Email
            </DropdownItem> </div>: row.user[0]?.email.length > 1 ? <DropdownItem
              onClick={(e) => callBack("MODAL_SHOW", "sendEmail", row)}
            >
              Send Email
            </DropdownItem> : row.user[0]?.mobileNumber.length > 1 ? <DropdownItem
              onClick={(e) => callBack("MODAL_SHOW", "sendMessage", row)}
            >
              Send SMS
            </DropdownItem>:''}
            
            {!row.isAdmin && (
              <DropdownItem
                onClick={(e) => callBack("MODAL_SHOW", "MAKE_ADMIN", row)}
              >
                Make TA
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    },
  },
  {
    dataField: 'user[0].active',
    text: 'Status',
    formatter: (cell) => {
      return (
        <>
         <span style={{color: `${cell === true ? 'black' : 'red'}`,fontWeight:'600'}}>{cell === true ? 'ACTIVE' : 'INACTIVE'}</span>
        </>
      )
    }
  },
  {
    dataField: "user[0].createdAt",
    sort: true,
    text: "Registered Date",
    formatter: (cell, row) => {
      let registeredDate = cell ?  new Date(cell) : '';
      if (typeof registeredDate === 'object' && registeredDate !== null && 'toDateString' in registeredDate){
        registeredDate = registeredDate.toDateString()
        let dateFormat = registeredDate.split(" ")
        if(dateFormat.length === 4) {
          return (dateFormat[2] + '-' + dateFormat[1] + '-' + dateFormat[3])
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
  },
  {
    dataField: "role",
    text: "Role",
    formatter: (cell, row) => {
      return (
        <>
          <span>{cell}</span>
          {/* {row.isAdmin && <span>admin</span>} */}
        </>
      );
    },
  },
  {
    dataField: 'user[0].language',
    text: 'Language',
    sort: true,
    formatter: (cell) => LANGUAGE[cell] || cell
  },
  {
    dataField: "user[0].name",
    text: "Name",
    sort: true,
    align: "center",
    formatter: (cell, row) => {
      return (
        <>
          <Media className="align-items-center">
            <ImagePreview
              imageStyle={{ height: 48 }}
              image={row?.user[0]?.profilePhoto}
            />
            <div className="ml-3">
              <span className="mb-0 text-sm">{cell}</span>
            </div>
          </Media>
        </>
      );
    },
  },
  {
    dataField: 'user[0].dob',
    text: 'DOB',
  },
  {
    dataField: 'user[0].dob',
    text: 'Age',
    sort: true,
    formatter: (cell) => cell ?  dateToAge(cell) : ''
  },
  {
    dataField: 'user[0].gender',
    text: 'Gender',
  },
  {
    dataField: "user[0].mobileNumber",
    text: "Mobile Number",
  },
  {
    dataField: "user[0].email",
    text: "Email",
    sort: true,
  },
  {
    dataField: "temple[0].name",
    text: "Temple name",
    sort: true,
    formatter: (cell) => {
      return (
        <div style={{ width: "300px", whiteSpace: "pre-line" }}>{cell}</div>
      );
    },
  },
  // {
  //   dataField: "temple[0].address",
  //   text: "Address",
  //   formatter: (cell) => {
  //     return (
  //       <div style={{ width: "300px", whiteSpace: "pre-line" }}>{cell}</div>
  //     );
  //   },
  // },
  {
    dataField: "user[0].location",
    text: "Location",
    sort: true,
    formatter: (cell, row) => {
      return (
        <div style={{ width: "300px", whiteSpace: "pre-line" }}>
          {cell}
        </div>
      );
    },
  },
  {
    dataField: "user[0].city",
    sort: true,
    text: "City",
    formatter: (cell, row) => {
      return <p>{cell}</p>;
    },
  },
  {
    dataField: "user[0].state",
    sort: true,
    text: "State",
    formatter: (cell, row) => {
      return <p>{cell}</p>;
    },
  },
  {
    dataField: "user[0].country",
    sort: true,
    text: "Country",
    formatter: (cell, row) => {
      return <p>{cell}</p>;
    },
  },
  {
    dataField: "approved[0].idProof",
    text: "ID Proof",
    classes: "p-2",
    formatter: (cell) => {
      const assets =
        cell && cell.length > 0 ? cell.map((img) => img.files) : [];
      console.log("assets", assets[0]);
      return (
        <Media className="align-items-center">
          <ImagePreview
            type="group"
            imageStyle={{ height: 48 }}
            assets={assets[0]}
          />
        </Media>
      );
    },
  },

  {
    dataField: "approved[0].createdAt",
    sort: true,
    text: "Date approved",
    formatter: (cell, row) => {
      let registeredDate = cell ?  new Date(cell) : '';
      if (typeof registeredDate === 'object' && registeredDate !== null && 'toDateString' in registeredDate){
        registeredDate = registeredDate.toDateString()
        let dateFormat = registeredDate.split(" ")
        if(dateFormat.length === 4) {
          return (dateFormat[2] + '-' + dateFormat[1] + '-' + dateFormat[3])
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
  },
  {
    dataField: "agent[0][0].name",
    sort: true,
    text: "Agent Name",
  },
  {
    dataField: 'favorite_temple',
    text: 'Fav Temples (Count)',
    formatter: (cell, row) => {
      return (
        <Link
      className="font-weight-bold"
      to={{
        pathname: '/admin/temples',
        search: `?user=${row.userId}`
      }}
    >
        {cell && cell.length ?  <Button
        color="primary"
        size="sm"
        // href={cell?.length ? `/admin/temple-updates/?temple=${row._id}` : '#'}
      >
      {cell.length}
      </Button>  : ''}
    </Link >
      )
    }
  },
  {
    dataField: 'user_post',
    text: 'User Post (Count)',
    formatter: (cell, row) => {
      return (
    <Link
          className="font-weight-bold"
          to={{
            pathname: '/admin/posts',
            search: `?userId=${row.userId}`
          }}
        >
          {cell && cell.length ?  <Button
        color="primary"
        size="sm"
        // href={cell?.length ? `/admin/temple-updates/?temple=${row._id}` : '#'}
      >
      {cell.length}
      </Button>  : ''}
          
        </Link >
        
      )
    }
  },
];

export default columns;
