import React from 'react';
import axios from 'axios';
import qs from 'qs';
import lodash from 'lodash';
import { Field, Form, Formik } from 'formik';
import NotificationAlert from "react-notification-alert";
import { Scrollbars } from 'react-custom-scrollbars';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory,
{ PaginationProvider, PaginationListStandalone }
  from "react-bootstrap-table2-paginator";
import {
  Container, Row, Col,
  Card, CardHeader, CardFooter,
  Button,
  UncontrolledCollapse,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input
} from 'reactstrap';

import DateRangePicker from 'components/Formik/DateRangePicker';
import columns from './column';

import { cleanObject } from 'utils';

import { AUTH_TOKEN } from '../../config'

class Ratings extends React.Component {
  state = {
    formInitialValue: {rating : '',category: ''},
    modal: {},
    feedbacks: [],
    ratingCategory:[],
    loading: true,
    search: '',
    filters: {},
    page: 1,
    sizePerPage: 50,
    total: 0,
    sortField: 'created',
    sortOrder: 'desc',
    isLoading: false,
    internalNotes: '',
    messageToTM: '',
    messageToTMViaOptions: '',
    sendVia: '',
    text: '',
    ratingRange : "",
    categoryType : ""
    // rating: '',
    // category: '',
  };

  componentWillMount() {
    const {
      filters: oldFilters
    } = this.state
    const { location: { search } } = this.props;
    const filters = qs.parse(search, { ignoreQueryPrefix: true });
    this.setState({ filters: { ...filters, ...oldFilters } })
    this.fetchRatingCategory();
    // this.fetchCollections();
  }
  async fetchRatingCategory(){
    try {
      let url = '/c0nstants?type=ratingCategory';
      const response = await axios({
        method: 'GET',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      this.setState({
        loading: false,
        ratingCategory: response.data.constants[0].fields || [],
      });
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }
  async fetchCollections() {
    let rating = this.state.ratingRange;
    let categoryType = this.state.categoryType;
    try {
      const { search, page, sizePerPage, sortField, sortOrder, filters } = this.state;
      let url = '/ratings';
      let query = { page, sizePerPage };
      if (sortField && sortOrder) { query = { ...query, sortField, sortOrder } }
      var copyOfFilters = JSON.parse(JSON.stringify(filters));
      // copyOfFilters.category = ""
      // copyOfFilters.rating = ""
      if (copyOfFilters.created) {
        if (copyOfFilters.created.toDate) {
          const toDate = new Date(copyOfFilters.created.toDate)
          toDate.setDate(toDate.getDate() + 1)
          copyOfFilters.created.toDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`
        } else if (copyOfFilters.created.fromDate && !copyOfFilters.created.toDate) {
          const todayDate = new Date()
          copyOfFilters.created.toDate = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`
        }
      }

      if (copyOfFilters) {
        cleanObject(copyOfFilters);
        query = { ...query, filters: copyOfFilters }
      }
      if (search) { query = { ...query, search } }

      if (query) { url = `${url}?${qs.stringify(query)}`; }

      const response = await axios({
        method: 'GET',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if(rating.length > 0 && categoryType.length > 0){
        let data = response.data.ratings.filter((item)=>{
          return item.rating[0][categoryType] === rating
        })
        this.setState({
          loading: false,
          feedbacks: data || [],
          total: response.data.pagination.total,
        });
      } else if (rating.length > 0 && categoryType.length === 0) {
        let data = response.data.ratings.filter((item)=>{
          return item.rating[0]["1"] === rating && item.rating[0]["2"] === rating && item.rating[0]["3"] === rating && item.rating[0]["4"] === rating && item.rating[0]["5"] === rating
        })
        this.setState({
          loading: false,
          feedbacks: data || [],
          total: response.data.pagination.total,
        });
      } else {
        this.setState({
          loading: false,
          feedbacks: response.data.ratings || [],
          total: response.data.pagination.total,
        });
      }
      
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  doSearch = (evt) => {
    this.setState({ page: 1, search: evt.target.value }, this.fetchCollections)
  }

  handleTableChange = (type, { page, sizePerPage, filters, ...rest }) => {
    if (type === 'pagination') {
      this.setState({ sizePerPage, page }, this.fetchCollections)
    }

    if (type === 'sort') {
      this.setState({
        sortOrder: rest.sortOrder,
        sortField: rest.sortField
      }, this.fetchCollections)
    }
  }

  onSubmitFilterForm = (values) => {
    var filters = { ...values };
    let rating = filters.rating;
    let categoryType = filters.category;
    filters.rating = "";
    filters.category = "";
    this.setState({ filters,ratingRange : rating,categoryType:categoryType }, this.fetchCollections)
  }

  onResetForm = () => {
    this.setState({ filters: {},ratingRange : '',categoryType: '',feedbacks:[]});
    // this.setState({rating:''})
  }

  toggleModal = (modelName, user) => {
    this.setState(prevState => ({
      modal: {
        ...prevState.modal,
        [modelName]: !prevState.modal[modelName]
      },
      activeUser: user,
      text: user && user.text ? user.text : ''
    }));
  }

  notify(messageText = 'success', type = 'success', place = 'tr') {
    const options = {
      place,
      type,
      autoDismiss: 7,
      icon: "ni ni-bell-55",
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {messageText}
          </span>
        </div>
      ),
    }
    this.refs.notify.notificationAlert(options);
  };

  tableCallBack = (actionType, ...rest) => {
    switch (actionType) {
      case 'MODAL_SHOW':
        this.toggleModal(...rest)
        break;
      default:
        break;
    }
  }

  render() {
    const { feedbacks } = this.state;
    const paginationOption = {
      custom: true,
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.total,
    }
    const pagination = paginationFactory(paginationOption);

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notify" />
        </div>
        {/* Page header */}
        <div className="header header-dark bg-info pb-6 pt-5 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
              </Row>
            </div>
          </Container>
        </div>

        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader >
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup className="mb-0">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Search by comment and userName"
                            type="text"
                            onChange={evt => this.doSearch(evt)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <Col className="text-md-right col-md-12">
                      <Button color="primary" size="sm" id="buttonTogglerFilters">
                        Filters
                      </Button>
                    </Col>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <UncontrolledCollapse defaultOpen toggler="#buttonTogglerFilters">
                        <Formik
                          initialValues={this.state.formInitialValue}
                          onSubmit={this.onSubmitFilterForm}
                          onReset={this.onResetForm}
                        >
                          {({ handleReset }) => {
                            return (
                              <Form>
                                <Row >
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Ratings
                                    </label>
                                    <Field className="form-control" as="select" name="rating">
                                      <option value=''>
                                        All
                                      </option>
                                      <option value='1'>
                                        1.0
                                      </option>
                                      <option value='2'>
                                        2.0
                                      </option>
                                      <option value='3'>
                                        3.0
                                      </option>
                                      <option value='4'>
                                        4.0
                                      </option>
                                      <option value='5'>
                                        5.0
                                      </option>

                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                    Category
                                    </label>
                                    <Field className="form-control" as="select" name="category">
                                      <option value=''>
                                        All
                                      </option>
                                      {this.state.ratingCategory.map((item,index)=>{
                                        return <option value={index+1}>{item.fieldName}</option>
                                      })}
                                    </Field>
                                  </Col>

                                  <Col className="mb-3" md="3">
                                    <label className="form-control-label">
                                      Date
                                    </label>
                                    <Row>
                                      <div className="col pr-1">
                                        <DateRangePicker
                                          name="created"
                                          DATE_FORMAT='YYYY-MM-DD'
                                          placeholder1="start date"
                                          placeholder2="end date"
                                        />
                                      </div>
                                    </Row>
                                  </Col>

                                  <Col className="mb-3 pb-1 align-self-end" md="2" >
                                    <Button type="submit">Go</Button>
                                    <Button onClick={handleReset}>Reset</Button>
                                  </Col>
                                </Row>

                              </Form>
                            );
                          }}
                        </Formik>
                      </UncontrolledCollapse>
                    </div>
                  </div>
                </CardHeader>

                <PaginationProvider
                  pagination={pagination}
                >
                  {({
                    paginationProps,
                    paginationTableProps
                  }) => (
                    <>
                      <div className="table-responsive">
                        <Scrollbars style={{ height: '50vh' }}>
                          <BootstrapTable
                            keyField='_id'
                            remote
                            bootstrap4={true}
                            bordered={false}
                            data={feedbacks}
                            columns={columns(this.tableCallBack)}
                            onTableChange={this.handleTableChange}
                            noDataIndication="no records"
                            {...paginationTableProps}
                          />
                        </Scrollbars>
                      </div>

                      <CardFooter>
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </CardFooter>
                    </>
                  )
                  }
                </PaginationProvider>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Ratings;
