import React, { Component } from 'react';
import axios from 'axios';
import qs from 'qs';
import lodash from 'lodash';
import moment from 'moment';
import { Field, Form, Formik } from 'formik';
import NotificationAlert from "react-notification-alert";
import { Scrollbars } from 'react-custom-scrollbars';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory,
{ PaginationProvider, PaginationListStandalone }
  from "react-bootstrap-table2-paginator";
import {
  Container, Row, Col,
  Card, CardHeader, CardFooter,
  Input, InputGroup, InputGroupAddon, InputGroupText,
  FormGroup, Form as FormB,
  Button,
  Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledCollapse,
} from 'reactstrap';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import 'react-google-places-autocomplete/dist/index.min.css'
import DateRangePicker from 'components/Formik/DateRangePicker';
import columns from './column';

import { cleanObject } from 'utils';

import { loggedInUser } from '../../../utils/User'

import './custom.css'

import { AUTH_TOKEN } from '../../../config'

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const arrayToImages = (array) =>{
   let images = [];
   array.map((item)=>{
    images.push(item)
   })
   return images+''
}

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
class ApprovedUserList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formValue: { address: '', role: '', rc: '', isActive: '', source: '', isAdmin: '' },
      modal: false,
      activeUser: null,
      templeList: [],
      loading: true,
      error: null,
      search: '',
      filters: {},
      page: 1,
      sizePerPage: 50,
      sortField: 'createdAt',
      sortOrder: 'desc',
      total: 0,
      isLoading: false,
      exportvisibility: true,
      newTAForm: [
        {
          name: '',
          email: '',
          mobileNumber: '',
          role: '',
          isAdmin: false
        }
      ],
      showConfirmation: false
    }
  }

  componentWillMount() {
    const { location: { search } } = this.props;
    const filters = qs.parse(search, { ignoreQueryPrefix: true });
    this.setState({ filters })
  }

  validate(items) {
    const MobileValidator = (mobileNo) => /^([0-9]{5})[-. ]?([0-9]{5})$/.test(mobileNo)
    let flag = []
    items.forEach(i => {
      if (i.name !== '' && i.mobileNumber !== '' && MobileValidator(i.mobileNumber) && i.role !== '' && i.isAdmin !== undefined) {
        flag.push(true)
        return
      }
      flag.push(false)
      return
    })
    return flag
  }

  callCreateAPI = async () => {

    let {
      newTAForm,
      temple
    } = this.state

    newTAForm = JSON.parse(JSON.stringify(newTAForm))

    newTAForm = newTAForm.map((i, k) => {
      i.role = i.role.toLowerCase()
      return i
    })

    const payload = {
      temple,
      admin: {
        name: loggedInUser().name,
        email: loggedInUser().email,
        mobileNumber: loggedInUser().mobileNumber
      },
      items: newTAForm
    }

    console.log('payload', payload)

    const url = '/agents/create-tatm'
    this.setState({
      isLoading: true
    })

    const { data } = await axios({
      method: 'POST',
      url,
      params: {
        apikey: AUTH_TOKEN
      },
      data: payload,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    this.setState({
      isLoading: false
    })

    if (data.success) {
      this.notify('Created TA/TMs successfully.', 'success')
      this.setState({
        newTAForm: [
          {
            name: '',
            email: '',
            mobileNumber: '',
            role: '',
            isAdmin: true
          }
        ]
      })
      this.toggleModal('newTA')
      this.setState({
        showConfirmation: false
      })
      this.fetchTableRecord()
    } else {
      this.notify(data.message, 'danger')
    }
  }
  onExportExcel = async () => {
    const arrayOfStrings = this.state.templeList ;
    console.log("gfhjk", arrayOfStrings)
    var arrayOfObjects = [];     
    arrayOfStrings.forEach(function (element, index) {
      arrayOfObjects.push({
        Status: element.isActive,
        ApprovedDate: element.createdAt, 
        Role: element.role, 
        Type: element.isAdmin === true ? 'Admin' : 'Member', 
        RemCount: element.rc, 
        Name: element.name,
        MobileNumber: element.mobileNumber,
        Photos: element.photos,
        IdProof: element.idProof,
        Attachments: element.Attachments,
        TempleName: element.temple.name,
        Location: element.temple.address, 
        City: element.temple.city, 
        State:element.temple.state, 
        Country:element.temple.country,
        Source:element.source,
        ApprovedBy:element.approvedBy.name + ',' + element.approvedBy.mobileNumber
    });
    })
    const ws = XLSX.utils.json_to_sheet(arrayOfObjects);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "" + moment(new Date()).format('ddd, MMMM Do YYYY') + fileExtension);
  }
  findDuplicates = () => {
    
    const {
      newTAForm
    } = this.state

    const original = []

    newTAForm.forEach(n => {
      const filtered = original.findIndex(o => o.mobileNumber === n.mobileNumber)
      if (filtered < 0) {
        original.push(n);
      }
    })

    return newTAForm.length === original.length

  }

  createTATM = async () => {
    try {
      let {
        newTAForm,
        temple
      } = this.state

      newTAForm = JSON.parse(JSON.stringify(newTAForm))

      newTAForm = newTAForm.map((i, k) => {
        i.role = i.role.toLowerCase()
        return i
      })

      if (temple) {
        if (!this.validate(newTAForm).includes(false)) {

          if (!this.findDuplicates()) {
            this.notify('Matching phone numbers found, No two phone numbers should match.', 'danger')
          } else {
            // check if no admin is created, in that case show a popup, else continue
            const noAdmins = newTAForm.filter(n => n.isAdmin)
            if (noAdmins.length) {
              if (noAdmins.length > 1) {
                this.notify('You have selected more than ONE Admin. Please verify and select the correct ADMIN.', 'danger')
              } else {
                this.callCreateAPI(temple, newTAForm)
              }
            } else {
              // put a popup here
              this.setState({
                showConfirmation: true
              })
            }
          }

        } else {
          this.notify('Please fill the details properly to proceed', 'danger')
        }
      } else {
        this.notify('Please pick a temple!', 'danger')
      }
    } catch (e) {
      this.setState({
        isLoading: false
      })
      this.notify('Something went wrong, please try again later', 'danger')
    }
  }

  findTemple = async (inputValue, callback) => {
    const {
      position: {
        latitude,
        longitude
      }
    } = this.state

    axios({
      method: 'GET',
      url: `/misc/search-temple?input=${inputValue}&lat=${latitude}&long=${longitude}`,
      params: {
        apikey: AUTH_TOKEN
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then((data) => {
      const items = []
      data.data.message.results.forEach(i => {
        items.push({
          label: i.name,
          value: i.place_id,
          otherDetails: i
        })
      })
      callback(items)
    })
  }

  fetchTableRecord = async () => {
    try {
      const { filters, page, search, sizePerPage, sortField, sortOrder } = this.state;
      let url = '/misc/approved-temple-members-admin';
      let query = { page, sizePerPage };
      if (sortField && sortOrder) { query = { ...query, sortField, sortOrder } }
      const copyOfFilters = JSON.parse(JSON.stringify(filters))
      if (copyOfFilters.createdAt) {
        if (copyOfFilters.createdAt.toDate) {
          const toDate = new Date(copyOfFilters.createdAt.toDate)
          toDate.setDate(toDate.getDate() + 1)
          copyOfFilters.createdAt.toDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`
        } else if (copyOfFilters.createdAt.fromDate && !copyOfFilters.createdAt.toDate) {
          const todayDate = new Date()
          copyOfFilters.createdAt.toDate = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`
        }
      }
      console.log('filters', copyOfFilters)
      if (copyOfFilters) {
        cleanObject(copyOfFilters);
        query = { ...query, filters: copyOfFilters }
      }
      if (search) { query = { ...query, search } }
      if (query) { url = `${url}?${qs.stringify(query)}`; }
//console.log('satya',url);
      const response = await axios({
        method: 'GET',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      this.setState({
        loading: false,
        templeList: response.data.users || [],
        total: response.data.pagination.total
      })
      if (this.state.templeList.length == 0) {
        this.setState({ exportvisibility: true })
      }
    } catch (error) {
      this.setState({ error: error.message, loading: false })
    }
  }

  debounceFn = lodash.debounce((searchText) => {
    this.setState({ page: 1, search: searchText })
    this.fetchTableRecord();
  }, 100)

  doSearch = (event) => {
    const searchText = event.target.value;
    this.debounceFn(searchText);
  }


  handleTableChange = (type, { page, sizePerPage, filters, ...rest }) => {
    if (type === 'pagination') {
      this.setState({
        page,
        sizePerPage
      })
      this.fetchTableRecord();
    }

    if (type === 'sort') {
      this.setState({
        sortOrder: rest.sortOrder,
        sortField: rest.sortField,
      })
      this.fetchTableRecord();
    }
  }

  onSubmitFilterForm = (values) => {
    const filters = { ...values };
    if (values.address)
      filters['temple.address'] = values.address;

    delete filters.address;
    this.setState({ filters })
    this.fetchTableRecord();
  }

  onResetForm = () => {
    this.setState({ filters: {}, templeList: [] , search: '', total: 0, page: 1})
  }

  handleDeleteRecord = async () => {
    try {
      const { activeUser } = this.state;
      if (!activeUser || activeUser === null) return;
      const id = activeUser._id;
      const url = `/misc/approved-temple-member/${id}/disable`;
      await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      this.notify('PTA memeber Inactivate successfully!', 'success');
      this.setState({
        loading: true,
        modal: false,
        activeUser: null,
      }, this.fetchTableRecord)

    } catch (error) {
      this.setState({ isLoading: false, modal: false, activeUser: null });
      this.notify('Something went wrong', 'warning');
    }
  }

  handleEnableUser = async () => {
    try {
      const { activeUser } = this.state;
      if (!activeUser || activeUser === null) return;
      const id = activeUser._id;
      const url = `/misc/approved-temple-member/${id}/enable`;
      await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      this.notify('PTA memeber Activated successfully!', 'success');
      this.setState({
        loading: true,
        modal: false,
        activeUser: null,
      }, this.fetchTableRecord)

    } catch (error) {
      this.setState({ isLoading: false, modal: false, activeUser: null });
      this.notify('Something went wrong', 'warning');
    }
  }

  makeUserTempleAdmin = async (user) => {
    try {

      const url = `misc/approved-temple-members/admin`;
      const data = {
        templeId: user.temple._id,
        userId: user._id
      }

      const response = await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        data,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      this.notify(response.data.msg);
      this.fetchTableRecord();
    } catch (error) {
      this.notify('Something went wrong', 'error');
    }
  }

  sendMessage = async (e) => {
    try {
      e.preventDefault();
      const url = '/users/send-message';
      const body = {
        mobile: this.state.activeUser.mobileNumber,
        message: this.state.messageText
      }
      const { data } = await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        data: body,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (data && data.success) {
        this.notify('SMS sent successfully.', 'success')
      }

      this.setState({
        modal: false,
        activeUser: null,
        messageText: '',
        loading: true,
        filters: {}
      }, this.fetchTableRecord);
    } catch (error) {
      this.setState({ isLoading: false, modal: false, activeUser: null });
      this.notify('Something went wrong', 'warning');
    }
  }

  toggleModal = (modelName, user) => {
    this.setState(prevState => ({
      modal: {
        ...prevState.modal,
        [modelName]: !prevState.modal[modelName]
      },
      activeUser: user,
    }));
  }

  notify = (messageText = 'success', type = 'success', place = 'tr') => {
    const options = {
      place,
      type,
      autoDismiss: 7,
      icon: "ni ni-bell-55",
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {messageText}
          </span>
        </div>
      ),
    }
    this.refs.notify.notificationAlert(options);
  };

  tableCallBack = (actionType, ...rest) => {

    switch (actionType) {
      case 'MODAL_ACTION':
        this.toggleModal(...rest)
        break;
      case 'APPROVED_ADMIN':
        this.makeUserTempleAdmin(...rest)
        break;

      default:
        break;
    }
  }


  render() {
    const { templeList } = this.state;


    const paginationOption = {
      custom: true,
      totalSize: this.state.total,
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
    }
    const pagination = paginationFactory(paginationOption);
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notify" />
        </div>
        {/* Page header */}
        <div className="header header-dark bg-info pb-6 pt-5 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
              </Row>
            </div>
          </Container>
        </div>

        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader >
                  <div className="row">

                    <div className="col-md-8">
                      <FormGroup className="mb-0">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Search by mobile number, name, temple name..."
                            type="text"
                            value={this.state.search}
                            onChange={this.doSearch}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <div className="col-md-4">
                      <Button color="primary" size="sm" id="buttonTogglerFilters" className='float-right'>
                        Filters
                      </Button>
                      <Button color="warning" size="sm" className='float-right' style={{
                        marginRight: '2%'
                      }} onClick={() => this.toggleModal('newTA')}>
                        Add TA/TM
                      </Button>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <UncontrolledCollapse defaultOpen toggler="#buttonTogglerFilters">
                        <Formik
                          initialValues={this.state.formValue}
                          onSubmit={this.onSubmitFilterForm}
                          onReset={this.onResetForm}
                        >
                          {({ handleReset }) => {
                            return (
                              <Form>
                                <Row >
                                <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Type
                                  </label>
                                    <Field className="form-control" as="input" component="select" name="isAdmin">
                                      <option value="">All</option>
                                      <option value="true">Temple Admin</option>
                                      <option value="false">Temple Member</option>
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md="3">
                                    <label className="form-control-label">
                                      Location
                                    </label>
                                    <Field className="form-control" as="input" name="address" />
                                  </Col>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Role
                                  </label>
                                    <Field className="form-control" as="input" component="select" name="role">
                                      <option value="">All</option>
                                      <option value="Head Priest">Head Priest</option>
                                      <option value="Dharmakarta">Dharmakarta</option>
                                      <option value="Pujari/Pandit/Purohit">Pujari/Pandit/Purohit</option>
                                      <option value="Temple Founder">Temple Founder</option>
                                      <option value="Temple Manager">Temple Manager</option>
                                      <option value="Temple Caretaker">Temple Caretaker</option>
                                      <option value="Temple Adviser">Temple Adviser</option>
                                      <option value="Other">Other</option>
                                    </Field>
                                  </Col>
                                  
                                  <Col className="mb-3" md="3">
                                    <label className="form-control-label">
                                    Approved Date
                                  </label>
                                    <Row>
                                      <div className="col pr-1">
                                        <DateRangePicker
                                          name="createdAt"
                                          DATE_FORMAT='YYYY-MM-DD'
                                          placeholder1="start date"
                                          placeholder2="end date"
                                        />
                                      </div>
                                    </Row>
                                  </Col>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Status
                                    </label>
                                    <Field className="form-control" as="input" name="isActive" component="select">
                                      <option value="">All</option>
                                      <option value="true">Active</option>
                                      <option value="false">In-Active</option>
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                    Approved Source
                                    </label>
                                    <Field className="form-control" as="input" name="source" component="select">
                                      <option value="">All</option>
                                      <option value="admin">Admin</option>
                                      <option value="agent">Agent</option>
                                      {/* <option value="self">Self</option> */}
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                    Reminder Count
                                    </label>
                                    <Field className="form-control" as="input" name="rc" />
                                  </Col>
                                  <Col className="mb-3 pb-1 align-self-end" md="3" >
                                    <Button type="submit">Go</Button>
                                    <Button onClick={handleReset}>Reset</Button>
                                  </Col>
                                </Row>
                              </Form>
                            );
                          }}
                        </Formik>
                      </UncontrolledCollapse>
                    </div>
                  </div>
                </CardHeader>

                <PaginationProvider
                  pagination={pagination}
                >
                  {({
                    paginationProps,
                    paginationTableProps
                  }) => (
                    <>
                      <div className=" table-responsive">
                        <Scrollbars style={{ height: '50vh' }}>
                          <BootstrapTable
                            keyField='_id'
                            remote
                            bootstrap4={true}
                            bordered={false}
                            data={templeList}
                            columns={columns(this.tableCallBack)}
                            onTableChange={this.handleTableChange}
                            noDataIndication="no records"
                            {...paginationTableProps}
                          />
                        </Scrollbars>
                      </div>
                      <CardFooter>
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                        <Row className="mb-3 pb-1 align-self-end" md="2" >
                        { this.state.exportvisibility? <div><Button onClick={this.onExportExcel} type="submit">Export to Excel </Button> <span className={`badge badge-pill badge-dark`} style={{color: '#fff',backgroundColor: '#5e72e4'}}>{`Total Count : ${templeList.length}`}</span></div> : null}
                        </Row>
                      </CardFooter>
                    </>
                  )}
                </PaginationProvider>
              </Card>
            </div>
          </Row>
        </Container>

        <Modal
          isOpen={this.state.modal['DELETE_MODAL']}
          toggle={e => this.toggleModal('DELETE_MODAL')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('DELETE_MODAL')}>Inactivate User</ModalHeader>
          <ModalBody>Are you sure, you want to Inactivate the user?</ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.handleDeleteRecord}>
            Inactivate
          </Button>
            <Button color="secondary" onClick={e => this.toggleModal('DELETE_MODAL')}>
              Cancel
          </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal['ENABLE_MODAL']}
          toggle={e => this.toggleModal('ENABLE_MODAL')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('ENABLE_MODAL')}>Activate User</ModalHeader>
          <ModalBody>Are you sure, you want to Activate the user?</ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.handleEnableUser}>
              Activate
          </Button>
            <Button color="secondary" onClick={e => this.toggleModal('ENABLE_MODAL')}>
              Cancel
          </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal.SEND_MESSAGE}
          toggle={e => this.toggleModal('SEND_MESSAGE')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('SEND_MESSAGE')}>Send Message</ModalHeader>
          <ModalBody>
            <FormB onSubmit={this.sendMessage}>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Message text
                  </label>
                <Input
                  rows="3"
                  type="textarea"
                  name='messageText'
                  required
                  value={this.state.messageText || ''}
                  onChange={e => this.setState({ [e.target.name]: e.target.value })}
                />
              </FormGroup>
              <div className="modal-footer">
                <Button color="primary" type="submit">
                  Send
                  </Button>
                <Button color="secondary" onClick={e => this.toggleModal('SEND_MESSAGE')}>
                  Cancel
                  </Button>
              </div>
            </FormB>

          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modal.newTA}
          toggle={e => this.toggleModal('newTA')}
          className={this.props.className}
          id='add-new'
          keyboard={false}
          backdrop="static"
        >
          <ModalHeader toggle={e => {
            this.setState({
              temple: '',
              newTAForm: [
                {
                  name: '',
                  email: '',
                  mobileNumber: '',
                  role: '',
                  isAdmin: true
                }
              ]
            })
            this.toggleModal('newTA')
          }}>Add New TA/TM</ModalHeader>
          <ModalBody className='new-modal-body'>
            <div className="row">
              <div className="col">
                <FormGroup className='pick-a-temple'>
                  <label
                    className="form-control-label"
                    htmlFor="exampleFormControlTextarea1"
                  >
                    Pick a Temple
                  </label>
                  <GooglePlacesAutocomplete
                  
                  apiKey="AIzaSyACFOa0M8QIbJXgQUb_mKiLLRbsNmaWQPk"
                  
                  autocompletionRequest={{
                      fields: [
                        'place_id'
                      ]
                    }}
                    debounce={500}
                    onSelect={(address) => {
                      this.setState({
                        'temple': {
                          label: address.description,
                          value: address.place_id
                        }
                      })
                    }}
                    minLengthAutocomplete={3}
                    placeholder="Enter to find temple"
                    required={true}
                    onLoadFailed={() => alert('The Google API is not working, please try after some time.')}
                  />
                </FormGroup>

              </div>
            </div>
            <div className="row">
              <div className="col text-center">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  *Name
                  </label>
              </div>
              <div className="col text-center">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  *Mobile No.
                  </label>
              </div>
              <div className="col text-center">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Role*
                  </label>
              </div>
              <div className="col text-center">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Is Admin*
                  </label>
              </div>
              <div className="col text-center">
                <label
                  className="form-control-label"
                  htmlFor="exampleFormControlTextarea1"
                >
                  Action
                  </label>
              </div>
            </div>
            {
              this.state.newTAForm.map((item, key) => (
                <div className="row">
                  <div className="col">
                    <FormGroup>
                      <Input
                        type="text"
                        required
                        value={this.state.newTAForm[key].name || ''}
                        onChange={e => {
                          const items = this.state.newTAForm
                          items[key].name = e.target.value
                          this.setState({
                            newTAForm: items
                          })
                        }}
                        onKeyPress={(event) => {
                          if (!/^[a-zA-Z ]*$/.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className="col">
                    <FormGroup>
                      <Input
                        required
                        type="tel"
                        max={10}
                        value={this.state.newTAForm[key].mobileNumber || ''}
                        onChange={e => {
                          if (e.target.value.length <= 10) {
                            const items = this.state.newTAForm
                            items[key].mobileNumber = e.target.value
                            this.setState({
                              newTAForm: items
                            })
                          }
                        }}
                        onKeyPress={(event) => {
                          if (/[a-z]/i.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                      />
                    </FormGroup>
                  </div>
                  <div className="col">
                    <FormGroup>
                      <Input
                        type="select"
                        required
                        value={this.state.newTAForm[key].role}
                        onChange={e => {
                          const items = this.state.newTAForm
                          items[key].role = e.target.value
                          this.setState({
                            newTAForm: items
                          })
                        }}
                      >
                        <option value="">All</option>
                        <option value="Head Priest">Head Priest</option>
                        <option value="Dharmakarta">Dharmakarta</option>
                        <option value="Pujari/Pandit/Purohit">Pujari/Pandit/Purohit</option>
                        <option value="Temple Founder">Temple Founder</option>
                        <option value="Temple Manager">Temple Manager</option>
                        <option value="Temple Caretaker">Temple Caretaker</option>
                        <option value="Temple Adviser">Temple Adviser</option>
                        <option value="Other">Other</option>
                      </Input>
                    </FormGroup>
                  </div>
                  <div className="col">
                    <FormGroup>
                      <Input
                        type="select"
                        required
                        value={this.state.newTAForm[key].isAdmin}
                        onChange={e => {
                          const items = this.state.newTAForm
                          if (e.target.value === 'true') {
                            items[key].isAdmin = true
                          } else {
                            items[key].isAdmin = false
                          }
                          // items.forEach((i, k) => {
                          //   if (k !== key) {
                          //     i.isAdmin = false
                          //   }
                          // })
                          this.setState({
                            newTAForm: items
                          })
                        }}
                      >
                        <option value="">--Select Role--</option>
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </Input>
                    </FormGroup>
                  </div>
                  <div className="col">
                    <FormGroup>
                      {
                        key === 0 ? (
                          <div className='align-btn'>
                            <Button size="sm" onClick={() => {
                              const items = this.state.newTAForm
                              items.push({
                                name: '',
                                email: '',
                                mobileNumber: '',
                                role: '',
                                isAdmin: false
                              })
                              this.setState({
                                newTAForm: items
                              })
                            }} color="success">Add</Button>
                          </div>
                        ) : (
                            <div className='align-btn'>
                              <Button size="sm" onClick={() => {
                                const items = this.state.newTAForm
                                items.splice(key, 1)
                                this.setState({
                                  newTAForm: items
                                })
                              }} color="danger">Remove</Button>
                            </div>
                          )
                      }
                    </FormGroup>
                  </div>
                </div>
              ))
            }
          </ModalBody>
          <div className="modal-footer">
            <Button color="primary" type="submit" onClick={this.createTATM} disabled={this.state.isLoading}>
              Create
            </Button>
            <Button color="secondary" onClick={e => {
              this.setState({
                temple: '',
                newTAForm: [
                  {
                    name: '',
                    email: '',
                    mobileNumber: '',
                    role: '',
                    isAdmin: true
                  }
                ]
              })
              this.toggleModal('newTA')
            }} disabled={this.state.isLoading}>
              Cancel
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showConfirmation}
          toggle={e => this.setState({ showConfirmation: !this.state.showConfirmation })}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('SHOW_CONFIRMATION_MODAL')}>Are you sure?</ModalHeader>
          <ModalBody>You have not selected any "Admin", do you want to proceed ?</ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.callCreateAPI}>
              Yes
          </Button>
            <Button color="secondary" onClick={e => this.setState({ showConfirmation: false })}>
              No
          </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

export default ApprovedUserList
