import React from 'react';
import moment from 'moment';
import {
  Badge,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button
} from 'reactstrap';
import ImagePreview from 'components/ImagePreview';
import { BACKEND_URL } from '../../config';

const columns = (callBack) => [
  {
    dataField: 'actions',
    text: 'Actions',
    isDummyField: true,
    formatter: (cell, row) => {
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light action-button-div"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={e => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-h action-button" />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-arrow"
            right
          >
            {row.disable? <DropdownItem
              href="#pablo"
              onClick={e => callBack('DISABLE', row)}

            >
              Activate
            </DropdownItem>: <DropdownItem
              href="#pablo"
              onClick={e => callBack('DISABLE', row)}

            >
              Inactivate
            </DropdownItem>}
            
            <DropdownItem
              href="#pablo"
              onClick={e => callBack('MODAL_SHOW', 'EDIT', row)}

            >
              Edit
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  },
  {
    dataField: 'disable',
    text: 'Status',
    formatter: (cell,row) => {
      return  <span style={{color: `${row.disable === true ? 'red' : 'black'}`,fontWeight:'600'}}>{row.disable  === true ? 'INACTIVE' : 'ACTIVE'}</span>
    }
  },
  {
    dataField: 'createdAt',
    text: 'Added Date',
    sort: true,
    formatter: (cell) => {
      let registeredDate = cell ?  new Date(cell) : '';
      if (typeof registeredDate === 'object' && registeredDate !== null && 'toDateString' in registeredDate){
        registeredDate = registeredDate.toDateString()
        let dateFormat = registeredDate.split(" ")
        if(dateFormat.length === 4) {
          return (dateFormat[2] + '-' + dateFormat[1] + '-' + dateFormat[3])
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  },
  {
    dataField: 'image',
    text: 'Image',
    classes: 'pt-1 pb-0',
    formatter: (cell) => {
      return (
        <>
          <ImagePreview image={cell} />
        </>
      )
    }
  },
  {
    dataField: 'name',
    text: 'Temple Name',
    sort: true,
    formatter: (cell) => {
      return (
        <div style={{ maxWidth: '100px', whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
  {
    dataField: 'contactNumber',
    text: 'Phone (Google)',
    sort: true,
    formatter: (cell) => {
      return (
        <div style={{ maxWidth: '100px', whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
  {
    dataField: 'contactEmail',
    text: 'Email (Google)',
    sort: true,
    formatter: (cell) => {
      return (
        <div style={{ maxWidth: '100px', whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
  {
    dataField: 'user[0].mobileNumber',
    text: 'Phone (TA)',
    sort: true,
    formatter: (cell) => {
      return (
        <div style={{ maxWidth: '100px', whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
  // {
  //   dataField: 'contactEmail',
  //   text: 'Email Id',
  //   sort: true,
  //   formatter: (cell) => {
  //     return (
  //       <div style={{ maxWidth: '100px', whiteSpace: 'pre-line' }}>
  //         {cell}
  //       </div>
  //     )
  //   }
  // },
  
  {
    dataField: 'user[0].email',
    text: 'Email (TA)',
    sort: true,
    formatter: (cell) => {
      return (
        <div style={{ maxWidth: '100px', whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
  
  {
    dataField: 'templeUrl',
    text: 'Devotted WebSite',
    sort: true,
    formatter: (cell,row) => {
      console.log(row.templeUrl)
      return (
      <div style={{ maxWidth: '100px', whiteSpace: 'pre-line' }}>
      <a href={ BACKEND_URL === "https://admin-dev-dashboard-api-916925-ksjdfg.devotted.com/api" ? 'https://dev-temple.devotted.com/?templeUrl='+row.templeUrl : 'https://temple.devotted.com/templedetails.html?templeUrl='+row.templeUrl} target="_blank" >{BACKEND_URL === "https://admin-dev-dashboard-api-916925-ksjdfg.devotted.com/api" ? 'https://dev-temple.devotted.com/?templeUrl='+row.templeUrl : 'https://temple.devotted.com/templedetails.html?templeUrl='+row.templeUrl}</a>
    </div>
       
      )
    }
  },
  {
    dataField: 'templeOwnWebsite',
    text: 'Own WebSite',
    sort: true,
    formatter: (cell,row) => {
      return (
        cell ?  <div style={{ maxWidth: '100px', whiteSpace: 'pre-line' }}>
        <a href={cell} target="_blank" >{cell}</a>
      </div> :  ''
      )
    }
  },
  {
    dataField: 'smsCount',
    text: 'SMS #',
    sort: true,
    formatter: (cell) => {
      const cell1 = cell == undefined ? "0" : cell;
      return (
        <div style={{ maxWidth: '100px', whiteSpace: 'pre-line' }}>
          {cell1}
        </div>
      )
    }
  },
  {
    dataField: 'country',
    text: 'Country',
    formatter: (cell) => {
      return (
        <div style={{ maxWidth: '100px', whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
  {
    dataField: 'state',
    text: 'State',
    formatter: (cell) => {
      return (
        <div style={{ maxWidth: '100px', whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
  {
    dataField: 'city',
    text: 'City',
    sort: true,
    formatter: (cell) => {
      return (
        <div style={{ maxWidth: '100px', whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
  {
    dataField: 'address',
    text: 'Address',
    sort: true,
    formatter: (cell, row) => {
      return (
        <>
          <div style={{ width: '300px', whiteSpace: 'pre-line' }}>
            {cell}
          </div>
          <div>
            {row.locality}
          </div>
        </>
      )
    }
  },
  
  {
    dataField: 'rating',
    text: 'Rating',
    sort: true,
    align: 'center',
    formatter: (cell) => {
      const color = cell > 3.9 ? "success" : "danger";
      return (
        <Badge color={color} pill>
          {cell}
        </Badge>
      )
    }
  },
  {
    dataField: 'templemembers',
    text: 'members',
    sort: true,
    formatter: (cell, row) => {
      return (
        <>
        {cell.length ? <Button
            color="primary"
            size="sm"
            href={cell.length ? `/admin/temple-members/?templeId=${row._id}&isAdmin=false` : '#'}
          >
            {cell.length}
          </Button> : ''}
          

        </>
      )
    }
  },
  {
    dataField: 'templeevents',
    text: 'events',
    sort: true,
    align: 'center',
    formatter: (cell, row) => {
      return (
        <>
        {cell.length ?  <Button
            color="primary"
            size="sm"
            href={cell?.length ? `/admin/events/?templeId=${row._id}` : '#'}
          >
            {cell?.length}
          </Button> : ''}
         
        </>
      )
    }
  },
  {
    dataField: 'templeupdates',
    text: 'updates',
    sort: true,
    align: 'center',
    formatter: (cell, row) => {
      return (
        <>
        {cell.length? <Button
            color="primary"
            size="sm"
            href={cell?.length ? `/admin/temple-updates/?temple=${row._id}` : '#'}
          >
            {cell?.length}
          </Button> : ''}
          
        </>
      )
    }
  },
  {
    dataField: 'approvedtemplemembers',
    text: 'Approved TA/TM',
    sort: true,
    align: 'center',
    formatter: (cell, row) => {
      return (
        <>
        {cell.length ? <Button
            color="primary"
            size="sm"
            href={cell?.length ? `/admin/approved-users/?placeId=${row.placeId}` : '#'}
          >
            {cell?.length}
          </Button>: '' }
          
        </>
      )
    }
  },
  
  

]


export default columns;