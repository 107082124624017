export const options = {
  SAY_HI: 'Say Hi!',
  SAY_HELLO: 'Say Hello!',
  SAY_NAMASTER: 'Say Namaste'
}

export const statusColor = {
  'pending': 'badge-info',
  'approved': 'badge-success',
  'rejected': 'badge-danger'
}
