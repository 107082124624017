import React from "react";
import axios from "axios";
import qs from "qs";
import lodash from "lodash";
import { Field, Form, Formik } from "formik";
import NotificationAlert from "react-notification-alert";
import { Scrollbars } from "react-custom-scrollbars";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  Input,
  FormGroup,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledCollapse,
} from "reactstrap";

import DateRangePicker from "components/Formik/DateRangePicker";
import columns from "./column";

import { cleanObject } from "utils";

import { AUTH_TOKEN } from "../../../config";

class HealthCollect extends React.Component {
  state = {
    formInitialValue: { reviewStatus: "", language: "",category:"" },
    modal: {},
    feedbacks: [],
    loading: true,
    search: "",
    filters: {},
    page: 1,
    sizePerPage: 50,
    total: 0,
    sortField: "createdAt",
    sortOrder: "desc",
    isLoading: false,
    internalNotes: "",
    messageToTM: "",
    messageToTMViaOptions: "",
    sendVia: "",
    text: "",
    language: ""
  };

  componentWillMount() {
    const { filters: oldFilters } = this.state;
    const {
      location: { search },
    } = this.props;
    const filters = qs.parse(search, { ignoreQueryPrefix: true });
    this.setState(
      { filters: { ...filters, ...oldFilters } }
    );
  }

  async fetchCollections() {
    try {
      const { search, page, sizePerPage, sortField, sortOrder, filters } =
        this.state;
      let url = "/misc/religious/collect";
      let query = { page, sizePerPage, type: "health" };
      if (sortField && sortOrder) {
        query = { ...query, sortField, sortOrder };
      }
      const copyOfFilters = JSON.parse(JSON.stringify(filters));
      if (copyOfFilters.createdAt) {
        if (copyOfFilters.createdAt.toDate) {
          const toDate = new Date(copyOfFilters.createdAt.toDate);
          toDate.setDate(toDate.getDate() + 1);
          copyOfFilters.createdAt.toDate = `${toDate.getFullYear()}-${
            toDate.getMonth() + 1
          }-${toDate.getDate()}`;
        } else if (
          copyOfFilters.createdAt.fromDate &&
          !copyOfFilters.createdAt.toDate
        ) {
          const todayDate = new Date();
          copyOfFilters.createdAt.toDate = `${todayDate.getFullYear()}-${
            todayDate.getMonth() + 1
          }-${todayDate.getDate()}`;
        }
      }

      if (copyOfFilters) {
        cleanObject(copyOfFilters);
        query = { ...query, filters: copyOfFilters };
      }
      if (search) {
        query = { ...query, search };
      }

      if (query) {
        url = `${url}?${qs.stringify(query)}`;
      }

      const response = await axios({
        method: "GET",
        url,
        params: {
          apikey: AUTH_TOKEN,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      this.setState({
        loading: false,
        feedbacks: response.data.users || [],
        total: response.data.pagination.total,
      });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  rejectRequest = async () => {
    try {
      console.log("activeUser", this.state.activeUser);
      const payload = {
        type: "health",
        id: this.state.activeUser,
        action: "reject",
      };

      const url = `/misc/religious/collect`;
      this.setState({
        isLoading: true,
      });

      const response = await axios({
        method: "PUT",
        url,
        params: {
          apikey: AUTH_TOKEN,
        },
        data: payload,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      this.setState({
        isLoading: false,
      });
      if (response.data.success) {
        this.notify("Rejected successfully.");
        this.fetchCollections();
        this.toggleModal("REJECT");
      } else {
        this.notify("Could not reject!", "danger");
      }
    } catch (e) {
      console.log("e", e);
      this.setState({
        isLoading: false,
      });
      this.notify("Could not reject!", "danger");
    }
  };

  approveRequest = async () => {
    try {
      console.log("activeUser", this.state.activeUser);
      const payload = {
        type: "health",
        id: this.state.activeUser,
        action: "approve",
      };

      const url = `/misc/religious/collect`;
      this.setState({
        isLoading: true,
      });

      const response = await axios({
        method: "PUT",
        url,
        params: {
          apikey: AUTH_TOKEN,
        },
        data: payload,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      this.setState({
        isLoading: false,
      });
      if (response.data.success) {
        this.notify("Approved successfully.");
        this.fetchCollections();
        this.toggleModal("APPROVE");
      } else {
        this.notify("Could not approve!", "danger");
      }
    } catch (e) {
      console.log("e", e);
      this.setState({
        isLoading: false,
      });
      this.notify("Could not approve!", "danger");
    }
  };

  debounceFn = lodash.debounce((searchText) => {
    this.setState({ page: 1, search: searchText }, this.fetchCollections);
  }, 1000);

  doSearch = (evt) => {
    const searchText = evt.target.value;
    this.debounceFn(searchText);
  };

  handleTableChange = (type, { page, sizePerPage, filters, ...rest }) => {
    if (type === "pagination") {
      this.setState({ sizePerPage, page }, this.fetchCollections);
    }

    if (type === "sort") {
      this.setState(
        {
          sortOrder: rest.sortOrder,
          sortField: rest.sortField,
        },
        this.fetchCollections
      );
    }
  };

  onSubmitFilterForm = (values) => {
    const filters = { ...values };
    this.setState({ filters }, this.fetchCollections);
  };

  onResetForm = () => {
    this.setState({ filters: {} });
    this.setState({feedbacks: []})
  };

  saveEditForm = async () => {
    try {
      this.setState({ isLoading: true });
      const url = `/misc/religious/collect`;
      const data = {
        text: this.state.text,
        language: this.state.language
      };
      const response = await axios({
        method: "PUT",
        url,
        params: {
          apikey: AUTH_TOKEN,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          type: "health",
          id: this.state.activeUser._id,
          action: "edit-approve",
          data,
        },
      });
      this.setState({ isLoading: false });
      console.log("response.........", response)
      if (response.data.success) {
        this.notify(response.data.message);
        this.toggleModal("EDIT");
        this.fetchCollections();
      } else {
        this.notify("Could not update HealthTip.", "warning");
      }
    } catch (error) {
      this.setState({ isLoading: false });
      this.notify("Could not update HealthTip.", "warning");
    }
  };

  review = async () => {
    try {
      const payload = {
        type: "health",
        id: this.state.activeUser,
        action: "review",
      };

      const url = `/misc/religious/collect`;
      this.setState({
        isLoading: true,
      });

      const response = await axios({
        method: "PUT",
        url,
        params: {
          apikey: AUTH_TOKEN,
        },
        data: payload,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      this.setState({
        isLoading: false,
      });
      if (response.data.success) {
        this.notify("Added for review successfully.");
        this.fetchCollections();
        this.toggleModal("REVIEW");
      } else {
        this.notify("Could not add for review!", "danger");
      }
    } catch (e) {
      console.log("e", e);
      this.setState({
        isLoading: false,
      });
      this.notify("Could not add for review!", "danger");
    }
  };

  delete = async () => {
    try {
      const payload = {
        type: "health",
        id: this.state.activeUser,
        action: "delete",
      };

      const url = `/misc/religious/collect`;
      this.setState({
        isLoading: true,
      });

      const response = await axios({
        method: "PUT",
        url,
        params: {
          apikey: AUTH_TOKEN,
        },
        data: payload,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      this.setState({
        isLoading: false,
      });
      if (response.data.success) {
        this.notify("Deleted successfully.");
        this.fetchCollections();
        this.toggleModal("DELETE");
      } else {
        this.notify("Could not delete!", "danger");
      }
    } catch (e) {
      console.log("e", e);
      this.setState({
        isLoading: false,
      });
      this.notify("Could not delete!", "danger");
    }
  };

  toggleModal = (modelName, user) => {
    this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        [modelName]: !prevState.modal[modelName],
      },
      activeUser: user,
      text: user && user.text ? user.text : "",
      language: user && user.language ? user.language : ""
    }));
  };

  notify(messageText = "success", type = "success", place = "tr") {
    const options = {
      place,
      type,
      autoDismiss: 7,
      icon: "ni ni-bell-55",
      message: (
        <div className="alert-text">
          <span data-notify="message">{messageText}</span>
        </div>
      ),
    };
    this.refs.notify.notificationAlert(options);
  }

  tableCallBack = (actionType, ...rest) => {
    switch (actionType) {
      case "MODAL_SHOW":
        this.toggleModal(...rest);
        break;
      default:
        break;
    }
  };

  render() {
    const { feedbacks } = this.state;
    const paginationOption = {
      custom: true,
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.total,
    };
    const pagination = paginationFactory(paginationOption);

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notify" />
        </div>
        {/* Page header */}
        <div className="header header-dark bg-info pb-6 pt-5 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4"></Row>
            </div>
          </Container>
        </div>

        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader>
                  <div className="row">
                    {/* <div className="col-md-6">
                      <FormGroup className="mb-0">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Search by comment"
                            type="text"
                            onChange={evt => this.doSearch(evt)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div> */}
                    <Col className="text-md-right col-md-12">
                      <Button
                        color="primary"
                        size="sm"
                        id="buttonTogglerFilters"
                      >
                        Filters
                      </Button>
                    </Col>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <UncontrolledCollapse
                        defaultOpen
                        toggler="#buttonTogglerFilters"
                      >
                        <Formik
                          initialValues={this.state.formInitialValue}
                          onSubmit={this.onSubmitFilterForm}
                          onReset={this.onResetForm}
                        >
                          {({ handleReset }) => {
                            return (
                              <Form>
                                <Row>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Status
                                    </label>
                                    <Field
                                      className="form-control"
                                      as="select"
                                      name="reviewStatus"
                                    >
                                      <option value="Pending">Pending</option>
                                      <option value="Approved">Approved</option>
                                      <option value="Rejected">Rejected</option>
                                      <option value="Reviewing">
                                        Reviewing
                                      </option>
                                      <option value="Deleted">Deleted</option>
                                      <option value="">All</option>
                                    </Field>
                                  </Col>
                                  
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Category
                                    </label>
                                    <Field
                                      className="form-control"
                                      as="select"
                                      name="category"
                                    >
                                      <option value="">Select</option>
                                      <option value="Allopathy">Allopathy</option>
                                      <option value="Homeopathy">Homeopathy</option>
                                      <option value="Ayurveda">Ayurveda</option>
                                      <option value="Beauty Tips">
                                      Beauty Tips
                                      </option>
                                      <option value="Nutrition Tips">Nutrition Tips</option>
                                      
                                    </Field>
                                  </Col>

                                  <Col className="mb-3" md="3">
                                    <label className="form-control-label">
                                      Date
                                    </label>
                                    <Row>
                                      <div className="col pr-1">
                                        <DateRangePicker
                                          name="createdAt"
                                          DATE_FORMAT="YYYY-MM-DD"
                                          placeholder1="start date"
                                          placeholder2="end date"
                                        />
                                      </div>
                                    </Row>
                                  </Col>

                                  <Col
                                    className="mb-3 pb-1 align-self-end"
                                    md="2"
                                  >
                                    <Button type="submit">Go</Button>
                                    <Button onClick={handleReset}>Reset</Button>
                                  </Col>
                                </Row>
                              </Form>
                            );
                          }}
                        </Formik>
                      </UncontrolledCollapse>
                    </div>
                  </div>
                </CardHeader>

                <PaginationProvider pagination={pagination}>
                  {({ paginationProps, paginationTableProps }) => (
                    <>
                      <div className="table-responsive">
                        <Scrollbars style={{ height: "50vh" }}>
                          <BootstrapTable
                            keyField="_id"
                            remote
                            bootstrap4={true}
                            bordered={false}
                            data={feedbacks}
                            columns={columns(this.tableCallBack)}
                            onTableChange={this.handleTableChange}
                            noDataIndication="no records"
                            {...paginationTableProps}
                          />
                        </Scrollbars>
                      </div>

                      <CardFooter>
                        <PaginationListStandalone {...paginationProps} />
                      </CardFooter>
                    </>
                  )}
                </PaginationProvider>
              </Card>
            </div>
          </Row>
        </Container>

        <Modal
          isOpen={this.state.modal.APPROVE}
          toggle={(e) => this.toggleModal("APPROVE")}
          className={this.props.className}
        >
          <ModalHeader toggle={(e) => this.toggleModal("APPROVE")}>
            Approve
          </ModalHeader>
          <ModalBody>Are you sure you want to approve?</ModalBody>
          <ModalFooter>
            <Button color="success" onClick={this.approveRequest}>
              Approve
            </Button>
            <Button
              color="secondary"
              onClick={(e) => this.toggleModal("APPROVE")}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal.REJECT}
          toggle={(e) => this.toggleModal("REJECT")}
          className={this.props.className}
        >
          <ModalHeader toggle={(e) => this.toggleModal("REJECT")}>
            Reject
          </ModalHeader>
          <ModalBody>Are you sure you want to reject?</ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.rejectRequest}>
              Reject
            </Button>
            <Button
              color="secondary"
              onClick={(e) => this.toggleModal("REJECT")}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal.REVIEW}
          toggle={(e) => this.toggleModal("REVIEW")}
          className={this.props.className}
        >
          <ModalHeader toggle={(e) => this.toggleModal("REVIEW")}>
            Review
          </ModalHeader>
          <ModalBody>Are you sure want to review this later?</ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.review}>
              Review
            </Button>
            <Button
              color="secondary"
              onClick={(e) => this.toggleModal("REVIEW")}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal.DELETE}
          toggle={(e) => this.toggleModal("DELETE")}
          className={this.props.className}
        >
          <ModalHeader toggle={(e) => this.toggleModal("DELETE")}>
            Delete
          </ModalHeader>
          <ModalBody>This action cannot be reversed!!</ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.delete}>
              Delete
            </Button>
            <Button
              color="secondary"
              onClick={(e) => this.toggleModal("DELETE")}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal.EDIT}
          toggle={(e) => this.toggleModal("EDIT")}
          className={this.props.className}
        >
          <ModalHeader toggle={(e) => this.toggleModal("EDIT")}>
            Edit
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlTextarea1"
              >
                Question
              </label>
              <Input
                rows="3"
                type="textarea"
                name="text"
                required
                value={this.state.text}
                onChange={(e) => {
                  this.setState({
                    text: e.target.value,
                  });
                }}
              />

              <label
                className="form-control-label"
                htmlFor="exampleFormControlTextarea1"
              >Language
              </label>
              <Input
                type="select"
                name="select"
                id="exampleSelect"
                onChange={(e) => {
                  this.setState({
                    language: e.target.value,
                  });
                }}
                value={this.state.language}
              >
                <option value="Telugu">Telugu</option>
                <option value="English">English</option>
                <option value="Hindi">Hindi</option>
              </Input>
            </FormGroup>

            <div className="modal-footer">
              <Button color="primary" type="button" onClick={this.saveEditForm}>
                Edit & Approve
              </Button>
              <Button
                color="secondary"
                onClick={(e) => this.toggleModal("EDIT")}
              >
                Cancel
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default HealthCollect;
