import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import qs from 'qs';
import lodash from 'lodash';
import { Field, Form, Formik } from 'formik';
import NotificationAlert from "react-notification-alert";
import { Scrollbars } from 'react-custom-scrollbars';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory,
{ PaginationProvider, PaginationListStandalone }
  from "react-bootstrap-table2-paginator";
import {
  Container, Row, Col,
  Card, CardHeader, CardFooter,
  Input, InputGroup, InputGroupAddon, InputGroupText,
  FormGroup,
  Button,
  Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledCollapse,
} from 'reactstrap';

import DateRangePicker from 'components/Formik/DateRangePicker';
import DatePicker from 'react-datepicker';
import columns from 'views/temple-updates/column';

import { AUTH_TOKEN } from '../../config'

function clean(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
      delete obj[propName];
    }
  }
  return obj
}

function TempleUpdatesList(props) {
  const { location: { search: qsearch } } = props;

  const notifyEl = useRef(null);

  const [formValue,setFormValue] = useState({ isActive: '', report: '', INC: 'select', INP: 'select' });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [modal, setModal] = useState({});
  const [activeUser, setActiveUser] = useState(null);
  const [templeList, setTempleList] = useState([]);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [total, setTotal] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [startDate,setStartDate] = useState('');
  const [endDate,setEndDate] = useState('');

  const paginationOption = {
    custom: true,
    page,
    sizePerPage,
    totalSize: total,
  }

  const pagination = paginationFactory(paginationOption);

  const fetchTableRecord = async () => {
    var url = "/temple-updates"
    let query = { };
    if (search) { query = { ...query, search } }

    if (query) { if(search.length) url = `${url}?${qs.stringify(query)}`; }
    try {
      url = url + `${search.length ? '&' : '?'}filters%5BINC%5D=${formValue.INC}&filters%5BINP%5D=${formValue.INP}`;
      if(formValue.isActive.length){
        url = url + `&filters%5BisActive%5D=${formValue.isActive}`
      }
      if(startDate){
        var toDate = new Date(startDate);
        toDate.setDate(toDate.getDate())
        toDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`
        console.log("toDate",toDate)
        url = url + `&filters%5BcreatedAt%5D%5BfromDate%5D=${toDate}`
      }
      if(endDate){
        var toDate = new Date(endDate);
        toDate.setDate(toDate.getDate())
        toDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`
        console.log("toDate",toDate)
        url = url + `&filters%5BcreatedAt%5D%5BtoDate%5D=${toDate}`
      }
      

      if (sortField && sortOrder) { query = { ...query, sortField, sortOrder } }
      query = clean(query);
      query.filters = clean(query.filters);
      url = `${url}&${qs.stringify(query)}`;
      // const copyOfFilters = JSON.parse(JSON.stringify(filters))
      // console.log("copyOfFilters.createdAt ---> ", copyOfFilters)
      // if (startDate.length && endDate.length) {
      //   if (copyOfFilters.createdAt.toDate) {
      //     const toDate = new Date(copyOfFilters.createdAt.toDate);
      //     toDate.setDate(toDate.getDate() + 1)
      //     copyOfFilters.createdAt.toDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`

      //   } else if (copyOfFilters.createdAt.fromDate && !copyOfFilters.createdAt.toDate) {
      //     const todayDate = new Date()
      //     copyOfFilters.createdAt.toDate = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`
      //   }
      // }
      // if (copyOfFilters) {
      //   copyOfFilters.filters = clean(copyOfFilters.filters);
      //   query = { ...query, ...copyOfFilters }
      // }


      const response = await axios({
        method: 'GET',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      setLoading(false);
      setTempleList(response.data.templeUpdates || []);
      setTotal(response.data.pagination.total);

    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    const filters = qs.parse(qsearch, { ignoreQueryPrefix: true });
    setFilters({ filters });
  }, [search,qsearch,formValue,formValue.INC,formValue.INP])


  const debounceFn = lodash.debounce((searchText) => {
    setPage(1);
    setSearch(searchText);
  }, 1000)


  const doSearch = (evt) => {
    setPage(1);
    setSearch(evt);
    fetchTableRecord();
    // debounceFn(evt.target.value);
  }

  const handleTableChange = (type, { page, sizePerPage, filters, ...rest }) => {
    if (type === 'pagination') {
      setSizePerPage(sizePerPage);
      setPage(page);
      this.setState({ sizePerPage, page }, this.fetchFeedbacks)
    }

    if (type === 'sort') {
      setSortOrder(rest.sortOrder);
      setSortField(rest.sortField);
      fetchTableRecord();
    }
  }

  const onSubmitFilterForm = (values) => {
    const filters = { ...values };
    setFilters({ filters });
    fetchTableRecord()
  }

  const onResetForm = () => {
    setFormValue({isActive: '', report: '', INC: 'select', INP: 'select'})
    setStartDate('');
    setEndDate('');
    setTempleList([]);
  }

  const handleDeleteRecord = async () => {
    try {
      if (!activeUser || activeUser === null) return;
      setLoading(true);

      const id = activeUser._id;
      const url = `/temple-updates/${id}/disable`;

      await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      notify('Temple update unpublished successfully!');
      setLoading(false);
      setModal(false);
      setActiveUser(null);
      fetchTableRecord();

    } catch (error) {
      this.setState({ isLoading: false, modal: false, activeUser: null });
      notify('Something went wrong', 'warning');
    }
  }

  const handlePublishRecord = async () => {
    try {
      if (!activeUser || activeUser === null) return;
      setLoading(true);

      const id = activeUser._id;
      const url = `/temple-updates/${id}/enable`;

      await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      notify('Temple update published successfully!');
      setLoading(false);
      setModal(false);
      setActiveUser(null);
      fetchTableRecord();

    } catch (error) {
      this.setState({ isLoading: false, modal: false, activeUser: null });
      notify('Something went wrong', 'warning');
    }
  }

  const toggleModal = (modelName, user) => {
    setModal({
      ...modal,
      [modelName]: !modal[modelName]
    });
    setActiveUser(user);
  }

  const notify = (messageText = 'success', type = 'success', place = 'tr') => {
    const options = {
      place,
      type,
      autoDismiss: 7,
      icon: "ni ni-bell-55",
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {messageText}
          </span>
        </div>
      ),
    }
    notifyEl.current.notificationAlert(options);
  };

  useEffect(() => {
    // fetchTableRecord();
  }, [filters,formValue, page, search, sizePerPage, sortField, sortOrder,qsearch])

  const tableCallBack = (actionType, ...rest) => {

    switch (actionType) {
      case 'MODAL_SHOW':
        toggleModal(...rest)
        break;

      default:
        break;
    }
  }

  return (
    <>
      {error && loading}
      <div className="rna-wrapper">
        <NotificationAlert ref={notifyEl} />
      </div>
      {/* Page header */}
      <div className="header header-dark bg-info pb-6 pt-5 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
            </Row>
          </div>
        </Container>
      </div>

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader >
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup className="mb-0">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          value ={search}
                          placeholder="Search by update ,location"
                          type="text"
                          onChange={evt => doSearch(evt.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                  </div>
                  <Col className="text-md-right" md={{ offset: 5, size: 1 }}>
                    <Button color="primary" size="sm" id="buttonTogglerFilters">
                      Filters
                    </Button>
                  </Col>
                </div>
                <div className="row mt-2">
                  <div className="col-md-12">
                    <UncontrolledCollapse defaultOpen toggler="#buttonTogglerFilters">
                      <Formik
                        initialValues={formValue}
                        onSubmit={onSubmitFilterForm}
                        onReset={onResetForm}
                      >
                        {({ handleReset }) => {
                          return (
                            <Form>
                              <Row >
                                {/* <Col className="mb-3" md="2">
                                  <label className="form-control-label">
                                    Location
                                  </label>
                                  <Field className="form-control" as="input" name="address">

                                  </Field>
                                </Col> */}
                                <Col className="mb-3" md="2">
                                  <label className="form-control-label">
                                    Inappropriate
                                  </label>
                                  <select className="form-control" value={formValue.INP} name="INP" onChange={(e)=>{
                                    setFormValue({...formValue,INP : e.target.value})
                                  }}>
                                    <option value=''>select</option>
                                    <option value='10'>Up to 10</option>
                                    <option value='10%2B'>10+</option>
                                  </select>
                                </Col>
                                <Col className="mb-3" md="2">
                                  <label className="form-control-label">
                                    Incorrect
                                  </label>
                                  <select className="form-control" value={formValue.INC} name="INC" onChange={(e)=>{
                                    setFormValue({...formValue,INC : e.target.value})
                                  }}>
                                    <option value=''>select</option>
                                    <option value='10'>Up to 10</option>
                                    <option value='10%2B'>10+</option>
                                  </select>
                                </Col>
                                <Col className="mb-3" md="3">
                                  <label className="form-control-label">
                                    Date
                                  </label>
                                  <Row>
                                    <div className="col pr-1">
                                    <Row>
                                      <Col md={6} className="pr-0">
                                        <DatePicker
                                          selectsStart
                                          className="form-control"
                                          name="start date"
                                          placeholderText={"start date"}
                                          dateFormat="dd-MM-yyyy"
                                          onChange={(date)=>{
                                           setStartDate(date)
                                          }}
                                          selected={startDate}
                                          startDate={startDate}
                                          endDate={endDate}
                                        />
                                      </Col>
                                      <Col md={6} className="pl-0">
                                        <DatePicker
                                          selectsEnd
                                          className="form-control"
                                          name="end date"
                                          placeholderText={"end date"}
                                          dateFormat="dd-MM-yyyy"
                                          onChange={(date)=>{
                                            setEndDate(date)
                                          }}
                                          selected={endDate}
                                          startDate={startDate}
                                          endDate={endDate}
                                          minDate={startDate}
                                        />
                                      </Col>
                                    </Row>
                                    </div>
                                  </Row>
                                </Col>
                                <Col className="mb-3" md="3">
                                  <label className="form-control-label">
                                    Status
                                  </label>
                                  <select className="form-control" as={formValue.isActive} value={formValue.isActive} name="isActive" onChange={(e)=>{
                                    setFormValue({...formValue,isActive : e.target.value})
                                  }}>
                                    <option value="">All</option>
                                    <option value="true">Active</option>
                                    <option value="false">In-Active</option>
                                  </select>
                                </Col>
                                <Col className="mb-3 pb-1 align-self-end" md="2" >
                                  <Button type="submit">Go</Button>
                                  <Button onClick={handleReset}>Reset</Button>
                                </Col>
                              </Row>
                            </Form>
                          );
                        }}
                      </Formik>
                    </UncontrolledCollapse>
                  </div>
                </div>
              </CardHeader>

              <PaginationProvider
                pagination={pagination}
              >
                {({
                  paginationProps,
                  paginationTableProps
                }) => (
                  <>
                    <div className="table-responsive">
                      <Scrollbars style={{ height: '50vh' }}>
                        <BootstrapTable
                          keyField='_id'
                          remote
                          bootstrap4={true}
                          bordered={false}
                          data={templeList}
                          columns={columns(tableCallBack)}
                          onTableChange={handleTableChange}
                          noDataIndication="no records"
                          {...paginationTableProps}
                        />
                      </Scrollbars>
                    </div>

                    <CardFooter>
                      <PaginationListStandalone
                        {...paginationProps}
                      />
                    </CardFooter>
                  </>
                )
                }
              </PaginationProvider>
            </Card>
          </div>
        </Row>
      </Container>

      <Modal
        isOpen={modal['DELETE_MODAL']}
        toggle={e => toggleModal('DELETE_MODAL')}
        className={props.className}
      >
        <ModalHeader toggle={e => toggleModal('DELETE_MODAL')}>Inactivate Temple Update</ModalHeader>
        <ModalBody>Are you sure you want to Inactivate?</ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={handleDeleteRecord}>
            Inactivate
          </Button>
          <Button color="secondary" onClick={e => toggleModal('DELETE_MODAL')}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modal['PUBLISH_MODAL']}
        toggle={e => toggleModal('PUBLISH_MODAL')}
        className={props.className}
      >
        <ModalHeader toggle={e => toggleModal('PUBLISH_MODAL')}>Active Temple Update</ModalHeader>
        <ModalBody>Are you sure you want to Active?</ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={handlePublishRecord}>
            Active
          </Button>
          <Button color="secondary" onClick={e => toggleModal('PUBLISH_MODAL')}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default TempleUpdatesList
