import React from "react"

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Spinner
} from "reactstrap"

import NotificationAlert from "react-notification-alert";
import {
  ForgotPassword as ForgotPasswordService
} from './service'

import {
  EmailValidater
} from './helper'

const ErrorNotification = {
  place: "tr",
  message: (
    <div className="alert-text">
      <span data-notify="message">
        Invalid Email entered!
      </span>
    </div>
  ),
  type: 'warning',
  icon: "ni ni-bell-55",
  autoDismiss: 7
};

const SuccessNotification = {
  place: "tr",
  message: (
    <div className="alert-text">
      <span data-notify="message">
        Verification mail sent successfully!
      </span>
    </div>
  )
}

class Forgot extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      isLoading: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  async handleSubmit() {
    try {
      const { email } = this.state
      if (EmailValidater(email)) {
        this.setState({ isLoading: true })
        let { data } = await ForgotPasswordService(email)

        //  set loading to false
        this.setState({ isLoading: false })
        if (data.status === 200) {
          this.setState({ email: "" })
          this.refs.notificationAlert.notificationAlert(SuccessNotification)

        } else {
          this.refs.notificationAlert.notificationAlert(ErrorNotification)
        }
      } else {
        this.refs.notificationAlert.notificationAlert(ErrorNotification)
      }
    } catch (error) {
      this.refs.notificationAlert.notificationAlert(ErrorNotification)
    }
  }

  render() {
    const { email, isLoading } = this.state
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Reset your Password</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.handleChange}
                      value={email}
                      placeholder="Email"
                      type="email"
                      name="email"
                    />
                  </InputGroup>
                </FormGroup>
                {
                  !isLoading ? (
                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="primary"
                        type="button"
                        onClick={this.handleSubmit}
                      >
                        Send Link
                  </Button>
                    </div>
                  ) : (
                      <div className="text-center">
                        <Spinner color="primary" size="md" />
                      </div>
                    )
                }
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    )
  }
}

export default Forgot
