import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// reactstrap components
import { Container, Row } from 'reactstrap';

// core components
// import AuthNavbar from 'components/Navbars/AuthNavbar.jsx';
import AuthFooter from 'components/Footers/AuthFooter.jsx';

import routes from 'routes.js';

class Auth extends React.Component {

  componentDidMount() {
    document.body.classList.add('bg-default');
  }

  componentWillUnmount() {
    document.body.classList.remove('bg-default');
  }

  // getRoutes = routes => {
  //   return routes.map((route, key) => {
  //     if (route.layout === '/auth') {
  //       return (
  //         <Route
  //           path={route.layout + route.path}
  //           component={route.component}
  //           key={key}
  //         />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };

  getRoutes = routes => {
    if (sessionStorage.getItem('token')) {
      return <Redirect from={this.props.location.pathname} to='/admin/index' />
    } else {
      return routes.map((prop, key) => {
        if (prop.layout === "/auth") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else {
          return null
        }
      });
    }
  };

  getDefaultRoutes = routes => {
    const route = routes.filter((r) => this.props.location.pathname === r.layout + r.path)
    if (!route.length) {
      return <Redirect from={this.props.location.pathname} to='/auth/login' />
    }
    return null
  }; 

  render() {

    return (
      <>
        <div className="main-content">
          {/* <AuthNavbar /> */}

          <div className="mb-4">
            <div className="header bg-gradient-info py-5 py-lg-5">
              <div style={{ width: '100%', textAlign: "center" }}>
                <img
                  alt="..."
                  src={require('assets/img/brand/LOGO_IMG_O_Web_d200.png')}
                />
              </div>
              {/* {pathname === "/" &&
                <Container>
                  <Row>
                    <Col lg="6" md="6">
                      <h1 className="text-white text-center heading__text">
                        Welcome to the Devotted Dashboard
                        </h1>
                    </Col>

                    <Col lg="6" md="6" className="text-center button__container">
                      <a href="/auth/login" className="login__button">Login</a>
                    </Col>

                  </Row>
                </Container>
              } */}
            </div>
          </div>

          {/* Page content */}
          <Container className="mt--6 pb-5">
            <Row className="justify-content-center">
              <Switch>
                {this.getRoutes(routes)}
                {this.getDefaultRoutes(routes)}
              </Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Auth;
