import React from 'react';
import axios from 'axios';
import qs from 'qs';
import lodash from 'lodash';
import { Field, Form, Formik } from 'formik';
import NotificationAlert from "react-notification-alert";
import { Scrollbars } from 'react-custom-scrollbars';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory,
{ PaginationProvider, PaginationListStandalone }
  from "react-bootstrap-table2-paginator";
import {
  Container, Row, Col,
  Card, CardHeader, CardFooter,
  Button,
  UncontrolledCollapse
} from 'reactstrap';

import DateRangePicker from 'components/Formik/DateRangePicker';
import columns from './column';

import { cleanObject } from 'utils';

import { AUTH_TOKEN } from '../../config'

class FeedbackList extends React.Component {
  state = {
    formInitialValue: { agentName: '', templeName: '', templeAddress: '', references: 'yes' },
    modal: {},
    feedbacks: [],
    loading: true,
    search: '',
    filters: {},
    page: 1,
    sizePerPage: 50,
    total: 0,
    sortField: 'createdAt',
    sortOrder: 'desc',
    isLoading: false,
    internalNotes: '',
    messageToTM: '',
    messageToTMViaOptions: '',
    sendVia: ''
  };

  componentWillMount() {
    const {
      filters: oldFilters
    } = this.state
    const { location: { search } } = this.props;
    const filters = qs.parse(search, { ignoreQueryPrefix: true });
    this.setState({ filters: { ...filters, ...oldFilters } })
  }

  async fetchFeedbacks() {
    try {
      const { search, page, sizePerPage, sortField, sortOrder, filters } = this.state;
      let url = '/agentfeedbacks';
      let query = { page, sizePerPage };
      if (sortField && sortOrder) { query = { ...query, sortField, sortOrder } }
      const copyOfFilters = JSON.parse(JSON.stringify(filters))
      if (copyOfFilters.createdAt) {
        if (copyOfFilters.createdAt.toDate) {
          const toDate = new Date(copyOfFilters.createdAt.toDate)
          toDate.setDate(toDate.getDate() + 1)
          copyOfFilters.createdAt.toDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`
        } else if (copyOfFilters.createdAt.fromDate && !copyOfFilters.createdAt.toDate) {
          const todayDate = new Date()
          copyOfFilters.createdAt.toDate = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`
        }
      }

      if (copyOfFilters) {
        cleanObject(copyOfFilters);
        query = { ...query, filters: copyOfFilters }
      }
      if (search) { query = { ...query, search } }

      if (query) { url = `${url}?${qs.stringify(query)}`; }

      const response = await axios({
        method: 'GET',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      this.setState({
        loading: false,
        feedbacks: response.data.feedbacks || [],
        total: response.data.pagination.total,
      });
    } catch (error) {
      this.setState({ error, loading: false })
    }
  }

  debounceFn = lodash.debounce((searchText) => {
    this.setState({ page: 1, search: searchText }, this.fetchFeedbacks)
  }, 1000)

  doSearch = (evt) => {
    const searchText = evt.target.value;
    this.debounceFn(searchText);
  }

  handleTableChange = (type, { page, sizePerPage, filters, ...rest }) => {
    if (type === 'pagination') {
      this.setState({ sizePerPage, page }, this.fetchFeedbacks)
    }

    if (type === 'sort') {
      this.setState({
        sortOrder: rest.sortOrder,
        sortField: rest.sortField
      }, this.fetchFeedbacks)
    }
  }

  onSubmitFilterForm = (values) => {
    const filters = { ...values };
    this.setState({ filters }, this.fetchFeedbacks)
  }

  onResetForm = () => {
    this.setState({ filters: {} });
  }

  toggleModal = (modelName, user) => {
    this.setState(prevState => ({
      modal: {
        ...prevState.modal,
        [modelName]: !prevState.modal[modelName]
      },
      activeUser: user,
    }));
  }

  notify(messageText = 'success', type = 'success', place = 'tr') {
    const options = {
      place,
      type,
      autoDismiss: 7,
      icon: "ni ni-bell-55",
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {messageText}
          </span>
        </div>
      ),
    }
    this.refs.notify.notificationAlert(options);
  };

  tableCallBack = (actionType, ...rest) => {
    switch (actionType) {
      case 'ACKNOWLEDGE':
        this.acknowledge(...rest)
        break;
      case 'REVOKE':
        this.revokeAcknowledge(...rest)
        break;
      case 'MODAL_SHOW':
        this.toggleModal(...rest)
        break;
      default:
        break;
    }
  }

  render() {
    const { feedbacks } = this.state;
    const paginationOption = {
      custom: true,
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.total,
    }
    const pagination = paginationFactory(paginationOption);

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notify" />
        </div>
        {/* Page header */}
        <div className="header header-dark bg-info pb-6 pt-5 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
              </Row>
            </div>
          </Container>
        </div>

        <Container className="mt--7" fluid >
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader >
                  <div className="row">
                    
                    <Col className="text-md-right col-md-12">
                      <Button color="primary" size="sm" id="buttonTogglerFilters" className="pull-right">
                        Filters
                      </Button>
                    </Col>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <UncontrolledCollapse defaultOpen toggler="#buttonTogglerFilters">
                        <Formik
                          initialValues={this.state.formInitialValue}
                          onSubmit={this.onSubmitFilterForm}
                          onReset={this.onResetForm}
                        >
                          {({ handleReset }) => {
                            return (
                              <Form>
                                <Row >
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Agent Name
                                    </label>
                                    <Field className="form-control" as="input" name="agentName" />
                                  </Col>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Temple Name
                                    </label>
                                    <Field className="form-control" as="input" name="templeName" />
                                  </Col>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Temple Address
                                    </label>
                                    <Field className="form-control" as="input" name="templeAddress" />
                                  </Col>
                                  <Col className="mb-3" md="3">
                                    <label className="form-control-label">
                                      Date
                                    </label>
                                    <Row>
                                      <div className="col pr-1">
                                        <DateRangePicker
                                          name="createdAt"
                                          DATE_FORMAT='YYYY-MM-DD'
                                          placeholder1="start date"
                                          placeholder2="end date"
                                        />
                                      </div>
                                    </Row>
                                  </Col>

                                  <Col className="mb-2" md="2">
                                    <label className="form-control-label">
                                      References
                                    </label>
                                    <Row>
                                      <div className="col pr-1">
                                        <Field className="form-control" as="select" name="references">
                                          <option value="yes">Yes</option>
                                          <option value="no">No</option>
                                          <option value="all">All</option>
                                        </Field>
                                      </div>
                                    </Row>
                                  </Col>

                                  <Col className="mb-3 pb-1 align-self-end" md="2" >
                                    <Button type="submit">Go</Button>
                                    <Button onClick={handleReset}>Reset</Button>
                                  </Col>
                                </Row>

                              </Form>
                            );
                          }}
                        </Formik>
                      </UncontrolledCollapse>
                    </div>
                  </div>
                </CardHeader>

                <PaginationProvider
                  pagination={pagination}
                >
                  {({
                    paginationProps,
                    paginationTableProps
                  }) => (
                      <>
                        <div className="table-responsive">
                          <Scrollbars style={{ height: '50vh' }}>
                            <BootstrapTable
                              keyField='_id'
                              remote
                              bootstrap4={true}
                              bordered={false}
                              data={feedbacks}
                              columns={columns(this.tableCallBack)}
                              onTableChange={this.handleTableChange}
                              noDataIndication="no records"
                              {...paginationTableProps}
                            />
                          </Scrollbars>
                        </div>

                        <CardFooter>
                          <PaginationListStandalone
                            {...paginationProps}
                          />
                        </CardFooter>
                      </>
                    )
                  }
                </PaginationProvider>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default FeedbackList;
