import React, {Component} from 'react';
import axios from 'axios';
import NotificationAlert from 'react-notification-alert';
// reactstrap components
import {
  Card,
  CardBody,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
  Container,
} from 'reactstrap';

import { AUTH_TOKEN } from '../../config'

class SuperAdminSignup extends Component {
  state = {
    name: '',
    email: '',
    password: '',
    mobileNumber: 0,
  };
  handleSubmit = async () => {
    const {name, email, password, mobileNumber} = this.state;
    let params = {
      name,
      email,
      password,
      mobileNumber,
    };
    
    const user = await axios({
      method: 'POST',
      url: 'https://dev-api.devotted.com/api/super/register',
      params: {
        apikey: AUTH_TOKEN
      },
      data: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    this.notify(
      'tr',
      user.data.msg || user.data.message,
      user.data.success ? 'success' : 'danger'
    );
    this.props.history.push('superuser/login');
  };
  handleOnChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => console.log(this.state, 'state')
    );
  };
  notify = (place, message, type) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    return (
      <>
        <Container>
          <NotificationAlert ref="notificationAlert" />
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <large>Add New Super Admin</large>
                </div>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i class="fas fa-user"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Name"
                      name="name"
                      type="text"
                      onChange={this.handleOnChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      name="email"
                      type="email"
                      onChange={this.handleOnChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      name="password"
                      type="password"
                      onChange={this.handleOnChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i class="fas fa-phone"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Mobile Number"
                      name="mobileNumber"
                      type="number"
                      onChange={this.handleOnChange}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={this.handleSubmit}
                  >
                    Add Super Admin
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      </>
    );
  }
}

export default SuperAdminSignup;
