import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

import NHeader from "components/Headers/NHeader.jsx";
import InfoCard from './InfoCard';
import { home } from '../../utils/enums/urlMap'
// import PropTypes from 'prop-types'
import qs from 'qs';


function Home(props) {
  const {
    taTm: { approvedTempleAdmins, registeredTempleAdmins, registeredTempleMembers },
    usersRegistered: { devotees, spiritual, both },
    laguageUsers: { english, hindi, telugu },
    updateEventsPosts: { updates, events, posts },
    templesFeedback: { temples, feedback, phonegoogle }
  } = home
  console.log("apppprovvvvvee...",approvedTempleAdmins)

  const start = new Date();
  start.setHours(0, 0, 0, 0);
  const end = new Date();
  end.setHours(23, 59, 59, 999);
  const todayQuery = { createdAt: { fromDate: start, toDate: end } };

  const startDate = new Date();
  // eslint-disable-next-line no-mixed-operators
  const startHours = new Date(startDate.getTime() - 1000 * 60 * 60);
  const endHours = new Date();
  const hoursQuery = { createdAt: { fromDate: startHours, toDate: endHours } };

  const thirtyDayPriorDate = new Date(new Date().setDate(startDate.getDate() - 30));
  console.log("thirtyDayPriorDate...222222222",thirtyDayPriorDate)

  const monthlyQuery = { createdAt: { fromDate: thirtyDayPriorDate, toDate: startDate } };
  console.log("apppprovvvvvee...222222222",monthlyQuery)

  const sevenDayPriorDate = new Date(new Date().setDate(startDate.getDate() - 7));
  const weeklyQuery = { createdAt: { fromDate: sevenDayPriorDate, toDate: startDate } };


  return (
    <>
      <NHeader />

      <Container className="mt--9" fluid>

        <Row className="mt-0" >
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Temple Admin/Temple Member</h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>

                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType="templeadmin"
                      bgColor="bg-gradient-primary"
                      ButtonText="Today"
                      title="Temple Admins (TA)"
                      totalText='Total'
                      todayText='Today'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      hoursText='Last Hour'
                      onClickMonthly={e => props.history.push(`${registeredTempleAdmins.base}?${qs.stringify({...registeredTempleAdmins.monthly, ...monthlyQuery})}`)}
                      onClickWeekly={e => props.history.push(`${registeredTempleAdmins.base}?${qs.stringify({...registeredTempleAdmins.weekly, ...weeklyQuery})}`)}
                      onClickTotal={e => props.history.push(`${registeredTempleAdmins.base}?${qs.stringify({...registeredTempleAdmins.total})}`)}
                      onClickToday={e => props.history.push(`${registeredTempleAdmins.base}?${qs.stringify({...registeredTempleAdmins.today, ...todayQuery})}`)}
                      onClickHours={e => props.history.push(`${registeredTempleAdmins.base}?${qs.stringify({...registeredTempleAdmins.hourly, ...hoursQuery})}`)}
                    />
                  </Col>

                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType="templemembers"
                      bgColor="bg-gradient-primary"
                      ButtonText="Today"
                      title="Temple Members (TM)"
                      totalText='Total'
                      todayText='Today'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      hoursText='Last Hour'
                      onClickMonthly={e => props.history.push(`${registeredTempleMembers.base}?${qs.stringify({...registeredTempleMembers.monthly, ...monthlyQuery})}`)}
                      onClickWeekly={e => props.history.push(`${registeredTempleMembers.base}?${qs.stringify({...registeredTempleMembers.weekly, ...weeklyQuery})}`)}
                      onClickTotal={e => props.history.push(`${registeredTempleMembers.base}?${qs.stringify({...registeredTempleMembers.total})}`)}
                      onClickToday={e => props.history.push(`${registeredTempleMembers.base}?${qs.stringify({...registeredTempleMembers.today, ...todayQuery})}`)}
                      onClickHours={e => props.history.push(`${registeredTempleMembers.base}?${qs.stringify({...registeredTempleMembers.hourly, ...hoursQuery})}`)}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-0" >
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Pe-Approved Temple Member</h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType='approvedtemplemembersbyself'
                      bgColor="bg-gradient-warning"
                      ButtonText="Today"
                      title="Self Requested"
                      totalText='Total'
                      todayText='Today'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      hoursText='Last Hour'
                      onClickMonthly={e => props.history.push(`${approvedTempleAdmins.base}?${qs.stringify({...approvedTempleAdmins.monthly, ...monthlyQuery, source: "Self"}
                        )}`)}
                      onClickWeekly={e => props.history.push(`${approvedTempleAdmins.base}?${qs.stringify({...approvedTempleAdmins.weekly, ...weeklyQuery, source: "Self"})}`)}
                      onClickTotal={e => props.history.push(`${approvedTempleAdmins.base}?${qs.stringify({...approvedTempleAdmins.total, source: "Self" })}`)}
                      onClickToday={e => props.history.push(`${approvedTempleAdmins.base}?${qs.stringify({...approvedTempleAdmins.today, ...todayQuery, source: "Self" })}`)}
                      onClickHours={e => props.history.push(`${approvedTempleAdmins.base}?${qs.stringify({...approvedTempleAdmins.hourly, ...hoursQuery, source: "Self"})}`)}
                    />
                  </Col>
                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType='approvedtemplemembersbyadmin'
                      bgColor="bg-gradient-warning"
                      ButtonText="Today"
                      title="By Admin"
                      totalText='Total'
                      todayText='Today'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      hoursText='Last Hour'
                      onClickMonthly={e => props.history.push(`${approvedTempleAdmins.base}?${qs.stringify({...approvedTempleAdmins.monthly, ...monthlyQuery, source: "admin"}
                        )}`)}
                      onClickWeekly={e => props.history.push(`${approvedTempleAdmins.base}?${qs.stringify({...approvedTempleAdmins.weekly, ...weeklyQuery, source: "admin"})}`)}
                      onClickTotal={e => props.history.push(`${approvedTempleAdmins.base}?${qs.stringify({...approvedTempleAdmins.total, source: "admin" })}`)}
                      onClickToday={e => props.history.push(`${approvedTempleAdmins.base}?${qs.stringify({...approvedTempleAdmins.today, ...todayQuery , source: "admin"})}`)}
                      onClickHours={e => props.history.push(`${approvedTempleAdmins.base}?${qs.stringify({...approvedTempleAdmins.hourly, ...hoursQuery, source: "admin"})}`)}
                    />
                  </Col>
                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType='approvedtemplemembersbyagent'
                      bgColor="bg-gradient-warning"
                      ButtonText="Today"
                      title="By Agent"
                      totalText='Total'
                      todayText='Today'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      hoursText='Last Hour'
                      onClickMonthly={e => props.history.push(`${approvedTempleAdmins.base}?${qs.stringify({...approvedTempleAdmins.monthly, ...monthlyQuery, source: "agent"}
                        )}`)}
                      onClickWeekly={e => props.history.push(`${approvedTempleAdmins.base}?${qs.stringify({...approvedTempleAdmins.weekly, ...weeklyQuery, source: "agent"})}`)}
                      onClickTotal={e => props.history.push(`${approvedTempleAdmins.base}?${qs.stringify({...approvedTempleAdmins.total , source: "agent"})}`)}
                      onClickToday={e => props.history.push(`${approvedTempleAdmins.base}?${qs.stringify({...approvedTempleAdmins.today, ...todayQuery, source: "agent" })}`)}
                      onClickHours={e => props.history.push(`${approvedTempleAdmins.base}?${qs.stringify({...approvedTempleAdmins.hourly, ...hoursQuery, source: "agent"})}`)}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5" >
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader className=" border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Devotees</h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="6" xl="4">
                    <InfoCard

                      apiType="religioususers"
                      bgColor="bg-gradient-default"
                      ButtonText="Today"
                      title="Religious"
                      totalText='Total'
                      todayText='Today'
                      hoursText='Last Hour'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      onClickTotal={e => props.history.push(`${devotees.base}?${qs.stringify({...devotees.total, from: 'main'})}`)}
                      onClickToday={e => props.history.push(`${devotees.base}?${qs.stringify({...devotees.today, ...todayQuery, from: 'main'})}`)}
                      onClickHours={e => props.history.push(`${devotees.base}?${qs.stringify({...devotees.hourly, ...hoursQuery, from: 'main'})}`)}
                      onClickMonthly={e => props.history.push(`${devotees.base}?${qs.stringify({...devotees.monthly, ...monthlyQuery, from: 'main'})}`)}
                      onClickWeekly={e => props.history.push(`${devotees.base}?${qs.stringify({...devotees.weekly, ...weeklyQuery, from: 'main'})}`)}
                    />

                  </Col>

                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType="spiritualusers"
                      bgColor="bg-gradient-default"
                      ButtonText="Today"
                      title="Spiritual"
                      totalText='Total'
                      todayText='Today'
                      hoursText='Last Hour'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      onClickTotal={e => props.history.push(`${spiritual.base}?${qs.stringify({...spiritual.total})}`)}
                      onClickToday={e => props.history.push(`${spiritual.base}?${qs.stringify({...spiritual.today, ...todayQuery, from: 'main'})}`)}
                      onClickHours={e => props.history.push(`${spiritual.base}?${qs.stringify({...spiritual.hourly, ...hoursQuery, from: 'main'})}`)}
                      onClickMonthly={e => props.history.push(`${spiritual.base}?${qs.stringify({...spiritual.monthly, ...monthlyQuery, from: 'main'})}`)}
                      onClickWeekly={e => props.history.push(`${spiritual.base}?${qs.stringify({...spiritual.weekly, ...weeklyQuery, from: 'main'})}`)}
                    />
                  </Col>

                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType="bothtypeusers"
                      bgColor="bg-gradient-default"
                      ButtonText="Today"
                      title="Both"
                      totalText='Total'
                      todayText='Today'
                      hoursText='Last Hour'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      onClickTotal={e => props.history.push(`${both.base}?${qs.stringify({...both.total})}`)}
                      onClickToday={e => props.history.push(`${both.base}?${qs.stringify({...both.today, ...todayQuery, from: 'main'})}`)}
                      onClickHours={e => props.history.push(`${both.base}?${qs.stringify({...both.hourly, ...hoursQuery, from: 'main'})}`)}
                      onClickMonthly={e => props.history.push(`${both.base}?${qs.stringify({...both.monthly, ...monthlyQuery, from: 'main'})}`)}
                      onClickWeekly={e => props.history.push(`${both.base}?${qs.stringify({...both.weekly, ...weeklyQuery, from: 'main'})}`)}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mt-5" >
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Language Users Added</h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType="englishlanguage"
                      bgColor="bg-gradient-danger"
                      ButtonText="Today"
                      title="English"
                      totalText='Total'
                      todayText='Today'
                      hoursText='Last Hour'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      onClickTotal={e => props.history.push(`${english.base}?${qs.stringify({...english.total, from: 'main'})}`)}
                      onClickToday={e => props.history.push(`${english.base}?${qs.stringify({...english.today, ...todayQuery, from: 'main'})}`)}
                      onClickHours={e => props.history.push(`${english.base}?${qs.stringify({...english.hourly, ...hoursQuery, from: 'main'})}`)}
                      onClickMonthly={e => props.history.push(`${english.base}?${qs.stringify({...english.monthly, ...monthlyQuery, from: 'main'})}`)}
                      onClickWeekly={e => props.history.push(`${english.base}?${qs.stringify({...english.weekly, ...weeklyQuery, from: 'main'})}`)}
                    />
                  </Col>

                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType="hindilanguage"
                      bgColor="bg-gradient-danger"
                      ButtonText="Today"
                      title="Hindi"
                      totalText='Total'
                      todayText='Today'
                      hoursText='Last Hour'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      onClickTotal={e => props.history.push(`${hindi.base}?${qs.stringify({...hindi.total, from: 'main'})}`)}
                      onClickToday={e => props.history.push(`${hindi.base}?${qs.stringify({...hindi.today, ...todayQuery, from: 'main'})}`)}
                      onClickHours={e => props.history.push(`${hindi.base}?${qs.stringify({...hindi.hourly, ...hoursQuery, from: 'main'})}`)}
                      onClickMonthly={e => props.history.push(`${hindi.base}?${qs.stringify({...hindi.monthly, ...monthlyQuery, from: 'main'})}`)}
                      onClickWeekly={e => props.history.push(`${hindi.base}?${qs.stringify({...hindi.weekly, ...weeklyQuery, from: 'main'})}`)}
                    />
                  </Col>

                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType="telugulanguage"
                      bgColor="bg-gradient-danger"
                      ButtonText="Today"
                      title="Telugu"
                      totalText='Total'
                      todayText='Today'
                      hoursText='Last Hour'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      onClickTotal={e => props.history.push(`${telugu.base}?${qs.stringify({...telugu.total, from: 'main'})}`)}
                      onClickToday={e => props.history.push(`${telugu.base}?${qs.stringify({...telugu.today, ...todayQuery, from: 'main'})}`)}
                      onClickHours={e => props.history.push(`${telugu.base}?${qs.stringify({...telugu.hourly, ...hoursQuery, from: 'main'})}`)}
                      onClickMonthly={e => props.history.push(`${telugu.base}?${qs.stringify({...telugu.monthly, ...monthlyQuery, from: 'main'})}`)}
                      onClickWeekly={e => props.history.push(`${telugu.base}?${qs.stringify({...telugu.weekly, ...weeklyQuery, from: 'main'})}`)}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mt-5" >
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">
                    Temple  Update/ Events - User Posts
                    </h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType='templeupdates'
                      bgColor="bg-gradient-success"
                      ButtonText="Update"
                      title=" Total Updates"
                      totalText='Total'
                      todayText='Today'
                      hoursText='Last Hour'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      onClickTotal={e => props.history.push(`${updates.base}?${qs.stringify({...updates.total})}`)}
                      onClickToday={e => props.history.push(`${updates.base}?${qs.stringify({...updates.today, ...todayQuery})}`)}
                      onClickHours={e => props.history.push(`${updates.base}?${qs.stringify({...updates.hourly, ...hoursQuery})}`)}
                      onClickMonthly={e => props.history.push(`${updates.base}?${qs.stringify({...updates.monthly, ...monthlyQuery})}`)}
                      onClickWeekly={e => props.history.push(`${updates.base}?${qs.stringify({...updates.weekly, ...weeklyQuery})}`)}
                    />
                  </Col>

                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType="templeevents"
                      bgColor="bg-gradient-success"
                      ButtonText="Events"
                      title=" Total Events"
                      totalText='Total'
                      todayText='Today'
                      hoursText='Last Hour'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      onClickTotal={e => props.history.push(`${events.base}?${qs.stringify({...events.total})}`)}
                      onClickToday={e => props.history.push(`${events.base}?${qs.stringify({...events.today, ...todayQuery})}`)}
                      onClickHours={e => props.history.push(`${events.base}?${qs.stringify({...events.hourly, ...hoursQuery})}`)}
                      onClickMonthly={e => props.history.push(`${events.base}?${qs.stringify({...events.monthly, ...monthlyQuery})}`)}
                      onClickWeekly={e => props.history.push(`${events.base}?${qs.stringify({...events.weekly, ...weeklyQuery})}`)}
                    />
                  </Col>

                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType="userposts"
                      bgColor="bg-gradient-success"
                      ButtonText="Posts"
                      title=" Total Posts"
                      totalText='Total'
                      todayText='Today'
                      hoursText='Last Hour'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      onClickTotal={e => props.history.push(`${posts.base}?${qs.stringify({...posts.total})}`)}
                      onClickToday={e => props.history.push(`${posts.base}?${qs.stringify({...posts.today, ...todayQuery})}`)}
                      onClickHours={e => props.history.push(`${posts.base}?${qs.stringify({...posts.hourly, ...hoursQuery})}`)}
                      onClickMonthly={e => props.history.push(`${posts.base}?${qs.stringify({...posts.monthly, ...monthlyQuery})}`)}
                      onClickWeekly={e => props.history.push(`${posts.base}?${qs.stringify({...posts.weekly, ...weeklyQuery})}`)}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mt-5" >
          <Col className="mb-5 mb-xl-0">
            <Card className=" shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">
                    Temples
                    </h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType="temples"
                      bgColor="bg-gradient-dark"
                      ButtonText="Today"
                      title="Temples ( Added to DB )"
                      totalText='Total'
                      todayText='Today'
                      hoursText='Last Hour'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      onClickTotal={e => props.history.push(`${temples.base}?${qs.stringify({...temples.total})}`)}
                      onClickToday={e => props.history.push(`${temples.base}?${qs.stringify({...temples.today, ...todayQuery})}`)}
                      onClickHours={e => props.history.push(`${temples.base}?${qs.stringify({...temples.hourly, ...hoursQuery})}`)}
                      onClickMonthly={e => props.history.push(`${temples.base}?${qs.stringify({...temples.monthly, ...monthlyQuery})}`)}
                      onClickWeekly={e => props.history.push(`${temples.base}?${qs.stringify({...temples.weekly, ...weeklyQuery})}`)}
                    />
                  </Col>

                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType="templeswithadmin"
                      bgColor="bg-gradient-dark"
                      ButtonText="Today"
                      title="Temples (With TA)"
                      totalText='Total'
                      todayText='Today'
                      hoursText='Last Hour'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      onClickTotal={e => props.history.push(`${temples.base}?${qs.stringify({...feedback.total, "isAdmin":true})}`)}
                      onClickToday={e => props.history.push(`${temples.base}?${qs.stringify({...feedback.today, ...todayQuery, "isAdmin":true})}`)}
                      onClickHours={e => props.history.push(`${temples.base}?${qs.stringify({...feedback.hourly, ...hoursQuery,"isAdmin":true})}`)}
                      onClickMonthly={e => props.history.push(`${temples.base}?${qs.stringify({...feedback.monthly, ...monthlyQuery, "isAdmin":true})}`)}
                      onClickWeekly={e => props.history.push(`${temples.base}?${qs.stringify({...feedback.weekly, ...weeklyQuery, "isAdmin":true})}`)}
                    />
                  </Col>

                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType="templeswithoutadmin"
                      bgColor="bg-gradient-dark"
                      ButtonText="Today"
                      title="Temples (Without TA)"
                      totalText='Total'
                      todayText='Today'
                      hoursText='Last Hour'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      onClickTotal={e => props.history.push(`${temples.base}?${qs.stringify({...phonegoogle.total,"isAdmin":false})}`)}
                      onClickToday={e => props.history.push(`${temples.base}?${qs.stringify({...phonegoogle.today, ...todayQuery,"isAdmin":false})}`)}
                      onClickHours={e => props.history.push(`${temples.base}?${qs.stringify({...phonegoogle.hourly, ...hoursQuery,"isAdmin":false})}`)}
                      onClickMonthly={e => props.history.push(`${temples.base}?${qs.stringify({...phonegoogle.monthly, ...monthlyQuery,"isAdmin":false})}`)}
                      onClickWeekly={e => props.history.push(`${temples.base}?${qs.stringify({...phonegoogle.weekly, ...weeklyQuery,"isAdmin":false})}`)}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="mt-5" >
          <Col className="mb-5 mb-xl-0">
            <Card className=" shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Others </h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType="feedBacks"
                      bgColor="bg-gradient-success"
                      ButtonText="Today"
                      title=" Feedback (Received)"
                      totalText='Total'
                      todayText='Today'
                      hoursText='Last Hour'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      onClickTotal={e => props.history.push(`${feedback.base}?${qs.stringify({...feedback.total})}`)}
                      onClickToday={e => props.history.push(`${feedback.base}?${qs.stringify({...feedback.today, ...todayQuery})}`)}
                      onClickHours={e => props.history.push(`${feedback.base}?${qs.stringify({...feedback.hourly, ...hoursQuery})}`)}
                      onClickMonthly={e => props.history.push(`${feedback.base}?${qs.stringify({...feedback.monthly, ...monthlyQuery})}`)}
                      onClickWeekly={e => props.history.push(`${feedback.base}?${qs.stringify({...feedback.weekly, ...weeklyQuery})}`)}
                    />
                  </Col>

                  <Col lg="6" xl="4">
                    <InfoCard
                      apiType="phonegoogle"
                      bgColor="bg-gradient-success"
                      ButtonText="Today"
                      title="Phone Numbers (Google)"
                      totalText='Total'
                      todayText='Today'
                      hoursText='Last Hour'
                      monthlyText='Monthly'
                      weeklyText='Weekly'
                      onClickTotal={e => props.history.push(`${phonegoogle.base}?${qs.stringify({...phonegoogle.total})}`)}
                      onClickToday={e => props.history.push(`${phonegoogle.base}?${qs.stringify({...phonegoogle.today, ...todayQuery})}`)}
                      onClickHours={e => props.history.push(`${phonegoogle.base}?${qs.stringify({...phonegoogle.hourly, ...hoursQuery})}`)}
                      onClickMonthly={e => props.history.push(`${phonegoogle.base}?${qs.stringify({...phonegoogle.monthly, ...monthlyQuery})}`)}
                      onClickWeekly={e => props.history.push(`${phonegoogle.base}?${qs.stringify({...phonegoogle.weekly, ...weeklyQuery})}`)}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
    </>
  )
}


export default Home

