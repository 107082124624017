import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, UncontrolledCarousel,
} from 'reactstrap';

function ImagePreview(props) {
  const { imageStyle, type, image, assets } = props;

  const [showModal, setShowModal] = useState(false);

  const items = [];
  if (type === 'single') {
    const obj = {
      src: image,
      altText: '',
      caption: '',
      header: ''
    };
    items.push(obj);
  }

  if (type === 'group') {
    const array = assets.map((src, index) => ({ key: index, src, altText: '', caption: '', header: '' }));
    items.push(...array);
  }

  const openImagePreviewModal = (e) => {
    e.preventDefault();

    if (image) {
      setShowModal(true)
    }
  }

  return (
    <>
      {type === 'single' && image &&
        <a
          className={props.avatarClassName}
          href="#img"
          onClick={openImagePreviewModal}
        >
          <img alt="..." src={image} style={imageStyle} />
        </a>
      }
      {type === 'group' &&
        <div className="avatar-group">
          {Array.isArray(assets) && assets.map(((img, index) => {
            if (index > 4) {
              return true
            }
            return (
              <React.Fragment key={index}>
                <a
                  className="avatar rounded-circle"
                  href="#img"
                  onClick={e => setShowModal(true)}

                >
                  <img alt="..." src={img} style={imageStyle} />
                </a>
              </React.Fragment>
            )
          }
          ))}
        </div>
      }
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Image Preview
            </h5>
          <button
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">

          <UncontrolledCarousel items={items} />
        </div>
      </Modal>

    </>
  )
}

ImagePreview.propTypes = {
  type: PropTypes.oneOf(['single', 'group']),
  url: PropTypes.string, // .oneOfType([PropTypes.string, PropTypes.array]),
  assets: PropTypes.arrayOf(PropTypes.string), //.oneOfType([PropTypes.string, PropTypes.array]),
  imageStyle: PropTypes.object,
}

ImagePreview.defaultProps = {
  type: 'single',
  image: null,
  assets: [],
  avatarClassName: "avatar rounded-circle",
  modalClassName: "modal-dialog-centered",
}

export default ImagePreview

