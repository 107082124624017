// import Profile from 'views/examples/Profile.jsx';
import Maps from './views/examples/Maps.jsx';
// import Register from 'views/examples/Register.jsx';
import Icons from './views/examples/Icons.jsx';
import Tables from './views/examples/Tables.jsx';

import User from './views/users/AllUser';
import TempleMember from './views/users/TempleMember/index.jsx';
import TempleAdmin from './views/users/TempleAdmin';
import ApprovedUserList from './views/users/ApprovedUser';
import NProfile from './views/users/NProfile';
import TempleMemberWithoutTAs from './views/users/TemplesWOTAs'

import MarketingRep from './views/agent/MarketingRep';
import AddMarketingRep from 'views/agent/AddMarketingRep';

// import Index from 'views/Index.jsx';
// import AdminList from 'views/admins';
import HomeScreen from './views/home';
import OtpScreen from './views/otpScreen';
import Posts from './views/posts/list';
import Events from './views/events/list';
import Temple from './views/temples/Temple.jsx';
import Login from './views/login/Login.jsx';
import TempleUpdates from './views/temple-updates/list';
import Feedbacks from './views/feedbacks/list';
import SuperAdminSignup from './views/superadmin/signupsuper';
import Business from './views/business-store/index.jsx';
import Forgot from './views/Forgot/Forgot'
import Reset from './views/Forgot/Reset'
import Verify from './views/Forgot/Verify'
import StaticUploads from './views/StaticUploads'

import References from './views/references'

import DharmasCollect from './views/collect/dharmasCollect'
import HealthCollect from './views/collect/healthCollect'
import PrayersCollect from './views/collect/prayersCollect'
import QuotesCollect from './views/collect/quotesCollect'

import Ratings from './views/ratings'

// let user = (localStorage.getItem('user') && localStorage.getItem('user') !== 'undefined')
//   ? JSON.parse(localStorage.getItem('user'))
//   : '';
// console.log(user.role, "userr");
var routes = [
  {
    path: '/superuser/verifyotp',
    name: 'Verify Otp',
    icon: 'ni ni-tv-2 text-primary',
    component: OtpScreen,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '/verifyotp',
    name: 'Verify Otp',
    icon: 'ni ni-tv-2 text-primary',
    component: OtpScreen,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '/super',
    name: 'Super Admin Signup',
    icon: 'ni ni-single-02 text-yellow',
    component: SuperAdminSignup,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '/superuser/login',
    name: 'Super User Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth',
    invisible: true,
  },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   icon: 'ni ni-circle-08 text-pink',
  //   component: Register,
  //   layout: '/auth',
  //   invisible: true,
  // },
  // {
  //   path: '/index',
  //   name: 'Dashboard',
  //   icon: 'ni ni-tv-2 text-primary',
  //   component: Index,
  //   layout: '/admin',
  //   invisible: true,
  // },
  {
    path: '/home',
    name: 'Home',
    icon: 'fas fa-home text-primary',
    component: HomeScreen,
    layout: '/admin',
    invisible: false,
  },
  // {
  //   path: '/adminsList',
  //   name: 'Admins',
  //   icon: 'ni ni-tv-2 text-primary',
  //   component: AdminList,
  //   layout: '/admin',
  //   // invisible: (user && user !== '' && user.role !== 'superuser') || user.role === undefined ? true : false,
  //   invisible: user && user.role && user.role !== 'superuser'
  // },
  {
    path: '/users',
    name: 'Users',
    icon: 'fa fa-users text-primary',
    component: User,
    layout: '/admin',
    collapse: false,
  },
  {
    path: '/users',
    name: 'Devotees',
    // icon: 'ni ni-tv-2 text-primary',
    component: User,
    layout: '/admin',
  },
  {
    path: '/approved-users',
    name: "Approved TA/TMs",
    // icon: 'ni ni-tv-2 text-primary',
    component: ApprovedUserList,
    layout: '/admin',
  },
  {
    path: '/temple-admin',
    name: 'Temple Admins',
    // icon: 'ni ni-tv-2 text-primary',
    component: TempleAdmin,
    layout: '/admin',
  },
  {
    path: '/temple-members',
    name: 'Temple Members',
    // icon: 'ni ni-tv-2 text-primary',
    component: TempleMember,
    layout: '/admin',
  },
  {
    path: '/temples-without-admin',
    name: 'TMs w/o Temple Admin',
    // icon: 'ni ni-tv-2 text-primary',
    component: TempleMemberWithoutTAs,
    layout: '/admin',
  },
  {
    path: '/approved-users',
    name: 'Pre-Approved TA/TM',
    // icon: 'ni ni-tv-2 text-primary',
    component: ApprovedUserList,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/temple-admin',
    name: 'Temple Admins',
    // icon: 'ni ni-tv-2 text-primary',
    component: TempleAdmin,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/temple-members',
    name: 'Temple Members',
    // icon: 'ni ni-tv-2 text-primary',
    component: TempleMember,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/temples',
    name: 'Temples',
    icon: 'fa fa-synagogue text-primary',
    component: Temple,
    layout: '/admin',
  },
  {
    path: '/agent/list',
    name: 'Devotted Associate',
    icon: 'fa fa-ad text-primary',
    component: MarketingRep,
    layout: '/admin',
  },
  {
    path: '/agent/add',
    name: 'Add Marketing Reps',
    icon: 'ni ni-tv-2 text-primary',
    component: AddMarketingRep,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/icons',
    name: 'Icons',
    icon: 'ni ni-planet text-blue',
    component: Icons,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/maps',
    name: 'Maps',
    icon: 'ni ni-pin-3 text-orange',
    component: Maps,
    layout: '/admin',
    invisible: true,
  },
  // {
  //   path: '/user-profile',
  //   name: 'User Profile',
  //   icon: 'ni ni-single-02 text-yellow',
  //   component: Profile,
  //   layout: '/admin',
  //   invisible: true,
  // },
  {
    path: '/user/:id',
    name: 'User Profile',
    icon: 'ni ni-single-02 text-yellow',
    component: NProfile,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/tables',
    name: 'Tables',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Tables,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/posts',
    name: 'Posts',
    icon: 'fa fa-clipboard text-primary',
    component: Posts,
    layout: '/admin',
  },
  {
    path: '/events',
    name: 'Temple Events',
    icon: 'fa fa-calendar text-primary',
    component: Events,
    layout: '/admin',
  },
  {
    path: '/temple-updates',
    name: 'Temple Updates',
    icon: 'fa fa-pen-nib text-primary',
    component: TempleUpdates,
    layout: '/admin',
  },
  {
    path: '/business-store',
    name: 'Business Stores',
    icon: 'fa fa-store text-primary',
    component: Business,
    layout: '/admin',
  },
  {
    path: '/static-uploads',
    name: 'Static Uploads',
    icon: 'fa fa-upload',
    component: StaticUploads,
    layout: '/admin',
  },
  {
    path: '/references',
    name: 'Associate References',
    icon: 'fa fa-download',
    component: References,
    layout: '/admin'
  },
  {
    path: '/feedbacks',
    name: 'Write to Us',
    icon: 'fa fa-comment-alt text-pink',
    component: Feedbacks,
    layout: '/admin',
  },
  {
    path: '/ratings',
    name: 'Ratings',
    icon: 'fa fa-star text-info',
    component: Ratings,
    layout: '/admin',
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    icon: 'ni ni-key-25 text-info',
    component: Forgot,
    layout: '/auth',
    invisible: true
  },
  {
    path: '/verify-reset-attempt',
    name: 'Verify',
    icon: 'ni ni-key-25 text-info',
    component: Verify,
    layout: '/auth',
    invisible: true
  },
  {
    path: '/reset',
    name: 'Reset',
    icon: 'ni ni-key-25 text-info',
    component: Reset,
    layout: '/auth',
    invisible: true
  },
  {
    path: '/user-shared/collect/dharmas',
    name: 'Collect',
    icon: 'fa fa-book text-primary',
    component: DharmasCollect,
    layout: '/admin',
    collapse: true,
    views: [
      {
        path: '/user-shared/collect/dharmas',
        name: 'Dharams',
        icon: 'fa fa-podcast',
        component: DharmasCollect,
        layout: '/admin',
      },
      {
        path: '/user-shared/collect/health',
        name: 'Health Tips',
        icon: 'fa fa-medkit',
        component: HealthCollect,
        layout: '/admin',
      },
      {
        path: '/user-shared/collect/prayers',
        name: 'Prayers',
        icon: 'fa fa-book',
        component: PrayersCollect,
        layout: '/admin'
      },
      {
        path: '/user-shared/collect/quotes',
        name: 'Quotes',
        icon: 'fa fa-tags',
        component: QuotesCollect,
        layout: '/admin',
      }
    ]
  },
  {
    path: '/user-shared/collect/dharmas',
    name: 'Dharams',
    icon: 'fa fa-podcast',
    component: DharmasCollect,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/user-shared/collect/health',
    name: 'Health Tips',
    icon: 'fa fa-medkit',
    component: HealthCollect,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/user-shared/collect/prayers',
    name: 'Prayers',
    icon: 'fa fa-book',
    component: PrayersCollect,
    layout: '/admin',
    invisible: true,
  },
  {
    path: '/user-shared/collect/quotes',
    name: 'Quotes',
    icon: 'fa fa-tags',
    component: QuotesCollect,
    layout: '/admin',
    invisible: true,
  }
];

export default routes;
