import React from 'react';
import axios from 'axios';
import qs from 'qs';
import lodash from 'lodash';
import { Field, Form, Formik } from 'formik';
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { Scrollbars } from 'react-custom-scrollbars';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory,
{ PaginationProvider, PaginationListStandalone }
  from "react-bootstrap-table2-paginator";
import {
  Container, Row, Col,
  Card, CardHeader, CardFooter,
  Input, InputGroup, InputGroupAddon, InputGroupText,
  FormGroup,
  Button,
  Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledCollapse,
  Badge
} from 'reactstrap';

import DateRangePicker from 'components/Formik/DateRangePicker';
import Comment from 'components/Comment';
import columns from './column';
import { cleanObject } from 'utils';

import { AUTH_TOKEN } from '../../config'
import { useEffect } from 'react';

class PostList extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      formInitialValue: { category: '', report: '', likes: '', comments: '', INP: '', INC: '', active: '' },
      loading: true,
      tableData: [],
      modal: false,
      activeRow: null,
      categoryType: '',
      categoryLang: 'ENG',
      search: '',
      filters: {},
      page: 1,
      sizePerPage: 50,
      total: 0,
      sortField: undefined,
      sortOrder: null,
      isReadMore : false,
      categorySpiritual: [],
      categoryReligious: [],
      categoryWellness: [],
      categoryOther: [],
      selectedCategories: []
    };
  }
 

  componentWillMount() {
    const { location: { search } } = this.props;
    const filters = qs.parse(search, { ignoreQueryPrefix: true });

    this.setState({ filters });
    this.fetchCategoryTypes(this.state.categoryLang);
    this.fetchIfUserID();
    // this.fetchPosts();
  }
  fetchCategoryTypes = async (categoryLang) => {
    let lang = categoryLang !== "select" ? categoryLang : 'ENG'
    const url = `/users/post-category?type=${lang}`
    const response = await axios({
      method: 'GET',
      url,
      params: {
        apikey: AUTH_TOKEN
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    let data = await response.data.postCategorydoc
    this.setState({
      loading: false,
      categorySpiritual:  data[0].subCategory,
      categoryReligious: data[1].subCategory,
      categoryWellness: data[2].subCategory,
      categoryOther: data[3].subCategory,
    });
  }
  fetchIfUserID = async () => {
         // geting userId from url params
         const queryString = window.location.search;
         const urlParams = new URLSearchParams(queryString);
         const userId = urlParams.get('userId');
         
         if(userId?.length > 0) {
          const { search, filters, page, sizePerPage, sortField, sortOrder } = this.state;
    
          let url = '/posts';
          let query = { page, sizePerPage, mode: 'admin' };
          if (sortField && sortOrder) { query = { ...query, sortField, sortOrder } }
    
          // if (queryParams.user) {
          //   query = { ...query, userId: queryParams.user }
          // }
          const copyOfFilters = JSON.parse(JSON.stringify(filters))
          if (copyOfFilters.createdAt) {
            if (copyOfFilters.createdAt.toDate) {
              const toDate = new Date(copyOfFilters.createdAt.toDate)
              toDate.setDate(toDate.getDate() + 1)
              copyOfFilters.createdAt.toDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`
            } else if (copyOfFilters.createdAt.fromDate && !copyOfFilters.createdAt.toDate) {
              const todayDate = new Date()
              copyOfFilters.createdAt.toDate = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`
            }
          }
          if (copyOfFilters) {
            cleanObject(copyOfFilters);
            query = { ...query, filters: copyOfFilters }
          }
    
          if (search) { query = { ...query, search } }
    
          if (query) {
            const queryString = qs.stringify(query, { skipNulls: false });
            url = `${url}?${queryString}`;
          }
          
          const response = await axios({
            method: 'GET',
            url,
            params: {
              apikey: AUTH_TOKEN
            },
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
          
           // api post response
        let table = response.data.posts
    
          let filterdPosts = table.filter((el)=>{
            return el.userId?._id === userId
          })
          this.setState({
            loading: false,
            tableData: filterdPosts,
            total: filterdPosts.length
          });
        }
  }

  fetchPosts = async () => {
    try {
      // const { search, queryParams, filters, page, sizePerPage, sortField, sortOrder } = this.state;
      const { search, filters, page, sizePerPage, sortField, sortOrder } = this.state;

      let url = '/posts';
      let query = { page, sizePerPage, mode: 'admin' };
      if (sortField && sortOrder) { query = { ...query, sortField, sortOrder } }

      // if (queryParams.user) {
      //   query = { ...query, userId: queryParams.user }
      // }
      const copyOfFilters = JSON.parse(JSON.stringify(filters))
      if (copyOfFilters.createdAt) {
        if (copyOfFilters.createdAt.toDate) {
          const toDate = new Date(copyOfFilters.createdAt.toDate)
          toDate.setDate(toDate.getDate() + 1)
          copyOfFilters.createdAt.toDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`
        } else if (copyOfFilters.createdAt.fromDate && !copyOfFilters.createdAt.toDate) {
          const todayDate = new Date()
          copyOfFilters.createdAt.toDate = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`
        }
      }
      if (copyOfFilters) {
        cleanObject(copyOfFilters);
        query = { ...query, filters: copyOfFilters }
      }

      if (search) { query = { ...query, search } }

      if (query) {
        const queryString = qs.stringify(query, { skipNulls: false });
        url = `${url}?${queryString}`;
      }
      
      const response = await axios({
        method: 'GET',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if(this.state.selectedCategories.length > 0){
        this.setState({
          loading: false,
          tableData: response.data.posts.filter((item)=>{
            return this.state.selectedCategories.includes(item.subCategory[0].subCategoryName);
          }) || [],
          total: response.data.pagination.total || 0
        });
      } else {
        this.setState({
          loading: false,
          tableData: response.data.posts || [],
          total: response.data.pagination.total || 0
        });
      }
     
     
    } catch (error) {
      this.setState({ error, isLoading: false })
    }
  }

  handleTableChange = (type, { page, sizePerPage, filters, ...rest }) => {
    if (type === 'pagination') {
      this.setState({ sizePerPage, page }, this.fetchPosts)
    }

    if (type === 'sort') {
      this.setState({
        sortOrder: rest.sortOrder,
        sortField: rest.sortField
      }, this.fetchPosts)
    }
  }

  onSubmitFilterForm = (values) => {
    const filters = { ...values };
    this.setState({ filters, page: 1 }, this.fetchPosts)
  }

  onResetForm = () => {
    this.setState({ formInitialValue : {}, filters: {}, page: 1, tableData: [] });
    document.getElementById('DropList').value = 'select';
  }

  doSearch = (evt) => {
    this.setState({ search: evt.target.value, page: 1 }, this.fetchPosts);
  }

  hideAlert = () => {
    this.setState({ alert: null });
  };

  deletePost = async (post) => {
    try {
      const postId = post._id
      const url = `/posts/${postId}`
      const data = await axios({
        method: 'DELETE',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (data.data.success) {
        const message = data.data.msg
        const type = data.data.success ? "success" : "danger";
        this.notify(message, type, "tr");
        this.fetchPosts();
        this.hideAlert();
      }

    } catch (error) {
      this.setState({ error, isLoading: false })
    }
  };

  togglePublishStatus = async (post) => {
    try {
      const { _id: postId, published } = post
      const url = published ? `/posts/${postId}/unpublish` : `/posts/${postId}/publish`;
      const method = 'PUT';
      const data = await axios({
        method,
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (data.data.success){
        const message = data.data.message
        const type = data.data.success ? "success" : "danger";
        this.notify(message, type, "tr");
        this.fetchPosts();
        this.hideAlert();
      }

    } catch (error) {
      this.setState({ error, isLoading: false })
    }
  };

  toggleUserStatus = async (user) => {
    try {
      const { _id: userId, active } = user
      const url = `users/${userId}/active`;
      const method = active ? 'DELETE' : 'PUT';
      const data = await axios({
        method,
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (data.data.success) {
        const message = data.data.message
        const type = data.data.success ? "success" : "danger";
        this.notify(message, type, "tr");
        this.fetchPosts();
        this.hideAlert();
      }

    } catch (error) {
      this.setState({ error, isLoading: false })
    }
  }

  togglePublishStatusAlert = (post) => {
    let confirmBtnText = post.published ? 'Yes, In-Active it!' : 'Yes, Active it!';
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          showCancel
          confirmBtnText={confirmBtnText}
          confirmBtnBsStyle="warning"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          custom
          style={{ display: 'block', marginTop: '-100px' }}
          onConfirm={this.togglePublishStatus.bind(this, post)}
          onCancel={() => this.hideAlert()}
          btnSize=""
        />
      )
    });
  };

  toggleDisableUserAlert = (user) => {
    let confirmBtnText = user.active ? 'Yes, disable user!' : 'Yes, enable user!';
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          showCancel
          confirmBtnText={confirmBtnText}
          confirmBtnBsStyle="warning"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          custom
          style={{ display: 'block', marginTop: '-100px' }}
          onConfirm={this.toggleUserStatus.bind(this, user)}
          onCancel={() => this.hideAlert()}
          btnSize=""
        />
      )
    });
  }

  deletePostAlert = (post) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure?"
          custom
          style={{ display: 'block', marginTop: '-100px' }}
          onConfirm={this.deletePost.bind(this, post)}
          onCancel={() => this.hideAlert()}
          btnSize=""
        />
      )
    });
  };
  notify = (message, type = "success", place = "tr") => {
    let options = {
      place: place,
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
      message: (
        <div className="alert-text">
          <span data-notify="message">{message}</span>
        </div>
      ),
    };
    this.refs.notify.notificationAlert(options);
  };
  toggleModal = (modelName, row) => {
    this.setState(prevState => ({
      modal: {
        ...prevState.modal,
        [modelName]: !prevState.modal[modelName]
      },
      activeRow: row,
    }));
  }

  tableCallBack = (actionType, ...rest) => {
    switch (actionType) {
      case 'DELETE_POST':
        this.deletePostAlert(...rest);
        break;
      case 'PUBLISH_POST':
        this.togglePublishStatusAlert(...rest);
        break;
      case 'MODAL_SHOW':
        this.toggleModal(...rest);
        break;
      case 'DISABLE_USER':
        this.toggleDisableUserAlert(...rest);
        break;
      default:
        break;
    }
  }

  render() {
    
    const { tableData, activeRow, categorySpiritual, categoryWellness, categoryOther, categoryReligious, selectedCategories } = this.state;
    
    const paginationOption = {
      custom: true,
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
      totalSize: this.state.total,
    }
    const pagination = paginationFactory(paginationOption);
    return (
      <>
        {this.state.alert}

        <div className="rna-wrapper">
          <NotificationAlert ref="notify" />
        </div>
        {/* Page header */}
        <div className="header header-dark bg-info pb-6 pt-5 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
              </Row>
            </div>
          </Container>
        </div>

        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader >
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup className="mb-0">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Search by post title , description"
                            type="text"
                            onChange={evt => this.doSearch(evt)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <Col className="text-md-right" md={{ offset: 5, size: 1 }}>
                      <Button color="primary" size="sm" id="buttonTogglerFilters">
                        Filters
                      </Button>
                    </Col>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <UncontrolledCollapse defaultOpen toggler="#buttonTogglerFilters">
                        <Formik
                          initialValues={this.state.formInitialValue}
                          onSubmit={this.onSubmitFilterForm}
                          onReset={this.onResetForm}
                        >
                          {({ handleReset }) => {
                            return (
                              <Form>
                                <Row >
                                <Col className="mb-3" md="2">
                                    {/* <label className="form-control-label">
                                    Category Lang
                                    </label>
                                    <Field className="form-control" as="select" name="categoryLang" onChange = {(e)=>{
                                       this.fetchCategoryTypes(e.target.value);
                                    }}> */}
                                    <label class="form-control-label">Category Lang</label>
                                    <select id='DropList' name="categoryLang" class="form-control" onChange = {(e)=>{
                                       this.fetchCategoryTypes(e.target.value);
                                    }}><option value="select">select</option><option value="ENG">English</option><option value="HND">Hindi</option><option value="TEL">Telugu</option></select>
                                    {/* </Field> */}
                                  </Col>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                    Category
                                    </label>
                                    <Field className="form-control" as="select" name="category" onChange = {(e)=>{
                                       this.setState({categoryType: e.target.value})
                                    }}>
                                    <option value='select'>select</option>
                                      <option value='Spiritual'>Spiritual</option>
                                      <option value='Religious'>Religious</option>
                                      <option value='Health'>Wellness</option>
                                      <option value='Other'>Others</option>
                                    </Field>
                                  </Col>
                                  
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                    Inappropriate
                                    </label>
                                    <Field className="form-control" as="select" name="INP">
                                      <option value=''>select</option>
                                      <option value='10'>Up to 10</option>
                                      <option value='10+'>10+</option>
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                    Incorrect
                                    </label>
                                    <Field className="form-control" as="select" name="INC">
                                      <option value=''>select</option>
                                      <option value='10'>Up to 10</option>
                                      <option value='10+'>10+</option>
                                    </Field>
                                  </Col>

                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Likes
                                    </label>
                                    <Field className="form-control" as="select" name="likes">
                                      <option value=''>select</option>
                                      <option value='10'>Up to 10</option>
                                      <option value='10+'>10+</option>
                                    </Field>
                                  </Col>

                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Comments
                                    </label>
                                    <Field className="form-control" as="select" name="comments">
                                      <option value=''>select</option>
                                      <option value='10'>Up to 10</option>
                                      <option value='10+'>10+</option>
                                    </Field>
                                  </Col>

                                  <Col className="mb-3" md="3">
                                  <p className='form-control-label'>Selected Category  : {selectedCategories.filter((item,index) =>selectedCategories.indexOf(item) === index).map((item=>{
                                    return<Button style= {{backgroundColor:'#EF7B45',color:'#fff'}} size='sm'>{item}</Button>
                                  }))}</p>
                                  </Col>
                                  
                                  <Col className="mb-3" md="3">
                                    <label className="form-control-label">
                                      Date
                                    </label>
                                    <Row>
                                      <div className="col pr-1">
                                        <DateRangePicker
                                          name="createdAt"
                                          DATE_FORMAT='YYYY-MM-DD'
                                          placeholder1="start date"
                                          placeholder2="end date"
                                        />
                                      </div>
                                    </Row>
                                  </Col>

                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Status
                                    </label>
                                    <Field className="form-control" as="select" name="active">
                                      <option value=''>All</option>
                                      <option value='true'>Active</option>
                                      <option value='false'>In-Active</option>
                                    </Field>
                                  </Col>

                                  <Col className="mb-3 pb-1 align-self-end" md="2" >
                                    <Button type="submit">Go</Button>
                                    <Button onClick={()=>{
                                      handleReset()
                                      this.setState({selectedCategories:[]})
                                    }}>Reset</Button>
                                  </Col>
                                </Row>

                              </Form>
                            );
                          }}
                        </Formik>
                      </UncontrolledCollapse>
                    </div>
                  </div>
                </CardHeader>

                <PaginationProvider
                  pagination={pagination}
                >
                  {({
                    paginationProps,
                    paginationTableProps
                  }) => (
                      <>
                        <div className="table-responsive">
                          <Scrollbars style={{ height: '50vh' }}>
                            <BootstrapTable
                              keyField='_id'
                              remote
                              bootstrap4={true}
                              bordered={false}
                              data={tableData}
                              columns={columns(this.tableCallBack)}
                              onTableChange={this.handleTableChange}
                              {...paginationTableProps}
                              noDataIndication="no records"
                            />
                          </Scrollbars>
                        </div>

                        <CardFooter>
                          <PaginationListStandalone
                            {...paginationProps}
                          />
                        </CardFooter>
                      </>
                    )
                  }
                </PaginationProvider>
              </Card>
            </div>
          </Row>
        </Container>

        <Modal
          isOpen={this.state.modal['SHOW_COMMENT']}
          toggle={e => this.toggleModal('SHOW_COMMENT')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('SHOW_COMMENT')}>Show comments</ModalHeader>
          <ModalBody>
            <Scrollbars style={{ height: '30vh' }}>
              <Row>
                {activeRow?.comments.map(c => {
                  return (
                    <Col
                      key={c._id}
                      className="mt-2 mb-2"
                      md="6"
                      xs="12"
                    >
                      <Comment comment={c} />
                    </Col>
                  );
                })}
              </Row>
            </Scrollbars>
          </ModalBody>
          <ModalFooter>

            <Button color="secondary" onClick={e => this.toggleModal('SHOW_COMMENT')}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modal['SHOW_READMORE']}
          toggle={e => this.toggleModal('SHOW_READMORE')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('SHOW_READMORE')}>Show Description/Text</ModalHeader>
          <ModalBody>
            <Scrollbars style={{ height: '30vh' }}>
              <Row>
              {activeRow?.comments.map(c => {
                  return (
                    <Col
                      key={c._id}
                      className="mt-2 mb-2"
                      md="6"
                      xs="12"
                    >
                      {c.text}
                    </Col>
                  );
                })}
              </Row>
            </Scrollbars>
          </ModalBody>
          <ModalFooter>

            <Button color="secondary" onClick={e => this.toggleModal('SHOW_READMORE')}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {/* Spiritual  */}
        <Modal
          isOpen={this.state.categoryType === "Spiritual" ? true : false}
          toggle={e => this.setState({categoryType:'select'})}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.setState({categoryType:'select'})}>Select Spiritual</ModalHeader>
          <ModalBody>
          <Row>
            {categorySpiritual.map((item,index)=>{
              return <Button style={{marginBottom: '8px'}} color={selectedCategories.includes(item.subCategoryName)?'danger':'primary'} value={item.subCategoryName} onClick={(e)=>{
                if(selectedCategories.includes(item.subCategoryName)){
                  var filterdCategory = selectedCategories.filter((el)=>{
                    return el !== item.subCategoryName
                  })
                  this.setState({selectedCategories:[...filterdCategory]})
                } else {
                  this.setState({selectedCategories:[...selectedCategories,e.target.value]})
                }
               
              }} size="sm" key={index}>{item.subCategoryName}</Button >
            })}
              </Row>
          </ModalBody>
          <ModalFooter>

            <Button color="primary" onClick={e => this.setState({categoryType:'select'})}>
              Apply
            </Button>
          </ModalFooter>
        </Modal>
        {/* Religious  */}
        <Modal
          isOpen={this.state.categoryType === "Religious" ? true : false}
          toggle={e => this.setState({categoryType:'select'})}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.setState({categoryType:'select'})}>Select Religious</ModalHeader>
          <ModalBody>
          <Row>
          {categoryReligious.map((item,index)=>{
              return <Button style={{marginBottom: '8px'}} color={selectedCategories.includes(item.subCategoryName)?'danger':'primary'} value={item.subCategoryName} onClick={(e)=>{
                if(selectedCategories.includes(item.subCategoryName)){
                  var filterdCategory = selectedCategories.filter((el)=>{
                    return el !== item.subCategoryName
                  })
                  this.setState({selectedCategories:[...filterdCategory]})
                } else {
                  this.setState({selectedCategories:[...selectedCategories,e.target.value]})
                }
               
              }} size="sm" key={index}>{item.subCategoryName}</Button >
            })}
              </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={e =>this.setState({categoryType:'select'})}>
            Apply
            </Button>
          </ModalFooter>
        </Modal>
        {/* Health  */}
        <Modal
          isOpen={this.state.categoryType === "Health" ? true : false}
          toggle={e => this.setState({categoryType:'select'})}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.setState({categoryType:'select'})}>Select Wellness</ModalHeader>
          <ModalBody>
          <Row>
          {categoryWellness.map((item,index)=>{
              return <Button key={index} style={{marginBottom: '8px'}} color={selectedCategories.includes(item.subCategoryName)?'danger':'primary'} value={item.subCategoryName} onClick={(e)=>{
                if(selectedCategories.includes(item.subCategoryName)){
                  var filterdCategory = selectedCategories.filter((el)=>{
                    return el !== item.subCategoryName
                  })
                  this.setState({selectedCategories:[...filterdCategory]})
                } else {
                  this.setState({selectedCategories:[...selectedCategories,e.target.value]})
                }
               
              }} size="sm">{item.subCategoryName}</Button >
            })}
              </Row>
          </ModalBody>
          <ModalFooter>

            <Button color="primary" onClick={e => this.setState({categoryType:'select'})}>
            Apply
            </Button>
          </ModalFooter>
        </Modal>
        {/* Spiritual  */}
        <Modal
          isOpen={this.state.categoryType === "Other" ? true : false}
          toggle={e => this.setState({categoryType:'select'})}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.setState({categoryType:'select'})}>Select Other</ModalHeader>
          <ModalBody>
          <Row>
          {categoryOther.map((item,index)=>{
              return <Button style={{marginBottom: '8px'}} color={selectedCategories.includes(item.subCategoryName)?'danger':'primary'} value={item.subCategoryName} onClick={(e)=>{
                if(selectedCategories.includes(item.subCategoryName)){
                  var filterdCategory = selectedCategories.filter((el)=>{
                    return el !== item.subCategoryName
                  })
                  this.setState({selectedCategories:[...filterdCategory]})
                } else {
                  this.setState({selectedCategories:[...selectedCategories,e.target.value]})
                }
               
              }} size="sm" key={index}>{item.subCategoryName}</Button >
            })}
              </Row>
          </ModalBody>
          <ModalFooter>

            <Button color="primary" onClick={e => this.setState({categoryType:'select'})}>
            Apply
            </Button>
          </ModalFooter>
        </Modal>


      </>
    );
  }
}

export default PostList;
