import React from 'react';
import NotificationAlert from 'react-notification-alert';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import {
  Card,
  Container,
  Modal,
  CardBody,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
} from 'reactstrap';

import { AUTH_TOKEN } from '../../config'

class DisableMarketingRep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disable_reason: '',
      showAlert: false,
    };
  }

  handleUpdate = async (e, repId) => {
    e.preventDefault();
    const { disable_reason } = this.state;
    //  set loading state to true
    this.setState({ isLoading: true });
    let params = {
      disable_reason,
    };
    
    const rep2 = await axios({
      method: 'PUT',
      url: `/agents/${repId}/disable`,
      params: {
        apikey: AUTH_TOKEN
      },
      data: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })

    this.notify(
      'tr',
      rep2.data.msg || rep2.data.message,
      rep2.data.success ? 'success' : 'danger'
    );
    if (rep2.data.success) {
      this.setState({
        isLoading: false,
      });
      window.location.reload();
    }
  };

  handleOnChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  notify = (place, message, type) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    const { rep } = this.props;
    return (
      <>
        <Container className="mt--7" fluid>
          <NotificationAlert ref="notificationAlert" />
          <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={this.props.disable}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary shadow border-0">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                >
                  <span aria-hidden={true}>×</span>
                </button>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <large>Reason for Disable Marketing Rep</large>
                  </div>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <i class="fas fa-user"></i> */}
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Reason for Disable"
                        name="disable_reason"
                        type="text"
                        value={this.state.disable_reason}
                        onChange={e => this.handleOnChange(e)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="danger"
                      type="button"
                      onClick={e => this.handleUpdate(e, rep._id)}
                    >
                      Disable User
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}
export default withRouter(DisableMarketingRep);
