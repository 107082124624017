export const CATEGORY = {
  'I want to become an Associate': 1,
  'make me temple admin': 2,
  'make me Temple Member': 3,
  1: ' I want to become a Devotted Associate',
  2: 'make me temple admin',
  3: 'make me Temple Member'
}

export const CATEGORY_OPTIONS = [
  { label: 'All', value: '' },
  { label: '1-I want to become a Devotted Associate', value: '1' },
  { label: '2-make me temple admin', value: '2' },
  { label: '3-make me Temple Member', value: '3' }
]

export const FeedbackMessages = {
  'I want to become an Associate': 'Namasthe ! Thank you for your feedback. We may contact you if any further information is required - Devotted.',
  'make me temple admin': {
    'approve': 'You have been approved as Temple Admin. Please logout and login again.',
    'reply': 'Thank you for your request. We will contact you soon.'
  },
  'make me Temple Member': `
  Please follow the steps to become a Temple Member:
  Logout
  Clear “Cache”, delete “Data” from mobile settings
  Register again as “Temple Member”
  `,
  1: 'Namasthe ! Thank you for your feedback. We may contact you if any further information is required - Devotted.',
  2: {
    'approve': 'You have been approved as Temple Admin. Please logout and login again.',
    'reply': 'Thank you for your request. We will contact you soon.'
  },
  3: `
  Please follow the steps to become a Temple Member:
  Logout
  Clear “Cache”, delete “Data” from mobile settings
  Register again as “Temple Member”
  `
}
