import React from 'react';
import moment from 'moment';

import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
  Media, Button
} from 'reactstrap';
import ReadMoreAndLess from 'react-read-more-less';
import ImagePreview from 'components/ImagePreview';

import { CATEGORY } from 'utils/enums/category';

const columns = (callBack) => [
  {
    dataField: 'acknowledged',
    text: 'Action',
    formatter: (cell, row) => {
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light action-button-div"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={e => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-h action-button" />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-arrow"
            right
          >
            <DropdownItem
              onClick={e => callBack('MODAL_SHOW', 'details', row)}
            >
              Open Request
            </DropdownItem>
            {
              ['I want to become an Associate', '6'].includes(row.category) && !row.acknowledged &&
              <DropdownItem
                onClick={() => callBack('ACKNOWLEDGE', row._id)}
              >
                Hire Associate
            </DropdownItem>}

            {/* {row.acknowledged &&
              <DropdownItem
                onClick={() => callBack('REVOKE', row._id)}
              >
                Revoke
            </DropdownItem>
            } */}
            {/* {
              <DropdownItem
                onClick={e => callBack('MODAL_SHOW', 'sendMessage', row)}
              >
                Reply(SMS)
            </DropdownItem>
            }
            {
              <DropdownItem
                onClick={e => callBack('MODAL_SHOW', 'sendEmail', row)}
              >
                Reply(Email)
            </DropdownItem>
            } */}
            <DropdownItem
              onClick={e => callBack('MODAL_SHOW', 'DELETE_MODAL', row)}
            >
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  },
  {
    dataField: 'createdAt',
    text: 'Date',
    sort: true,
    formatter: (cell) => {
      return (
        <div style={{ width: '100px', whiteSpace: 'pre-line' }} className="mb-0">{moment(cell).format('MMMM Do YYYY, h:mm a')}</div>
      )
    }
  },
  {
    text: 'User Name',
    sort: true,
    formatter: (cell, row) => {
      if (row.userId) {
        return (
          <>
            <h4 className="mb-0">{row.userId.name || ''}</h4>
            <h5 className="mb-0">{row.userId.mobileNumber || ''}</h5>
            <h5 className="mb-0">{row.userId.email || ''}</h5>
          </>
        )
      }
      return (
        <>
          <h4 className="mb-0">{row.name || ''}</h4>
          <h5 className="mb-0">{row.mobileNumber || ''}</h5>
          <h5 className="mb-0">{row.email || ''}</h5>
        </>
      )
    }
  },
  {
    dataField: 'category',
    text: 'Category',
    formatter: (cell) =>  cell === '1' ? 'Associate' : cell === '2' ? 'Admin' : cell === '3' ? 'Member' : cell === '4' ? 'Commercial' : cell === '5' ? 'Other' : cell
  },
  {
    dataField: 'comment',
    text: 'Comment',
    formatter: (cell) => {
      return (
        cell && (
          <div style={{ whiteSpace: 'normal' }} >
            {/* <ReadMoreAndLess
              className="read-more-content mb-0 text-sm"
              charLimit={100}
              readMoreText=" more"
              readLessText=" less"
              style={{
                whiteSpace: 'normal'
              }}
            >
              {cell}
            </ReadMoreAndLess> */}
            {cell.slice(0,25)} {cell.length > 25 ? <p><Button color="primary" size="sm" onClick = {e=>callBack('MODAL_SHOW', 'SHOW_READMORE', {comments:[{text:cell}]})}>Read More</Button ></p> : '' }
          </div>
        )
      )
    }
  },
  {
    dataField: 'attachments',
    text: 'Images',
    sort: true,
    formatter: (cell) => {
      const assets = cell.length > 0 ? cell.map(img => img.url) : [];
      return (
        <Media className="align-items-center">
          <ImagePreview
            type="group"
            imageStyle={{ height: 48 }}
            assets={assets}
          />
        </Media>
      )
    }
  },
  {
    dataField: 'acknowledged',
    text: 'Associate Hired',
    formatter: (cell, row) => {
      return (
        ['I want to become an Associate', '6'].includes(row.category) ? (
          cell ? (
            <span className='badge badge-pill badge-success'>Hired</span>
          ) : (
              <span className="badge bage-pill badge-info">
                Request Pending
              </span>
            )
        ) : '-'
      )
    }
  },

];


export default columns;

