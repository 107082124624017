import React from 'react';
import axios from 'axios';
import lodash from 'lodash';
import qs from 'qs';
import { Field, Form, Formik } from 'formik';
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Input,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  UncontrolledCollapse,
  FormGroup
} from 'reactstrap';

import { cleanObject } from 'utils';

import AddMarketingRep from './AddMarketingRep';
import EditMarketingRep from './EditMarketingRep';
import DisableMarketingRep from './DisbaleForm.jsx';


import AgentTables from './AgentTables';
import { AUTH_TOKEN } from '../../config'

class MarketingRep extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      formInitialValue: { state: '', city: '', address: '', isActive : '' },
      search: '',
      filters: {},
      activeUsers: { records: [], total: 0 },
      inactiveUsers: { records: [], total: 0 },
      disableUsers: { records: [], total: 0 },
      sendMessageModal: false,
      modalState: false,
      modal: false,
      rep: {},
      edit: false,
      disable: false,
      messageText: " If we will receive any complaints on your actions or behavior next time, we will have to terminate you. Please contact us.",
      activeUser: null,
      statusActiveModal: false,
      statusInActiveModal: false
    };
  }

  toggleActiveModal = (user) => {

    this.setState(prevState => ({
      statusActiveModal: !prevState.statusActiveModal,
      activeUserId: user._id
    }))

  }

  toggleInActiveModal = (user) => {

    this.setState(prevState => ({
      statusInActiveModal: !prevState.statusInActiveModal,
      activeUserId: user._id
    }))

  }

  toggleModal = (user) => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      activeUserId: user._id
    }));
  }

  componentWillMount() {
    // this.fetchActiveUsers()
    //  fetch marketing reps
    // this.refreshTables();
  }

  refreshTables = () => {
    this.fetchActiveUsers();
  }

  notify = (messageText = 'success', type = 'success', place = 'tr') => {
    const options = {
      place,
      type,
      autoDismiss: 7,
      icon: "ni ni-bell-55",
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {messageText}
          </span>
        </div>
      ),
    }
    this.refs.notify.notificationAlert(options);
  };

  fetchActiveUsers = async (page = 1, sizePerPage = 50, sortField = null, sortOrder = null) => {
    try {
      let url = '/agents/';
      const { filters, search } = this.state;
      let query = { page, sizePerPage };
      if (sortField && sortOrder) { query = { ...query, sortField, sortOrder } }
      if (filters) {
        cleanObject(filters);
        query = { ...query, filters: { ...filters }, }
      }
      if (search) { query = { ...query, search } }
      if (query) {
        const queryString = qs.stringify(query, { skipNulls: false });
        url = `${url}?${queryString}`;
      }

      const data = await axios({
        method: 'GET',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      const records = data.data.allMarketingReps;
      const total = data.data.pagination?.total;
      this.setState({
        activeUsers: { records, total },
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        error,
        isLoading: false,
      })
    }
  }


  doSearch = (event) => {
    this.setState({ search: event.target.value }, this.refreshTables)
  }

  onSubmitFilterForm = (values) => {
    let filters = { ...values };
    console.log(values['isActive'])
    if (values && values['isActive'] === 'true') {
      filters = {
        ...filters,
        active: true
      }
    }
    if(values && values['isActive'] === 'false') {
      filters = {
        ...filters,
        active: false
      }
    }
    delete filters['isActive']
    this.setState({ filters }, this.refreshTables);
  };

  onResetForm = () => {
    this.setState({ filters: {}, activeUsers: {} });
  }

  handleSpecificRep = async (user, name) => {
    try {
      const url = `/agents/get/${user._id}`;
      const data = await axios({
        method: 'GET',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      if (name === 'edit') {
        this.setState({
          rep: data.data.agent,
          edit: true,
          isLoading: false,
        });
      } else {
        this.setState({
          rep: data.data.agent,
          isLoading: false,
          disable: true,
        });
      }
    } catch (error) {
      this.setState({ error, isLoading: false, })
    }
  };

  handleSendMessage = async () => {
    try {
      if (this.state.messageText) {
        const {
          activeUser
        } = this.state
        this.setState({
          isLoading: true
        })
        const url = `/agents/send-message/${activeUser[0].mobileNumber}`
        const data = await axios({
          method: 'POST',
          url,
          params: {
            apikey: AUTH_TOKEN
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          data: {
            message: this.state.messageText
          }
        })
        this.setState({
          isLoading: false
        })
        if (data.status) {
          this.notify('SMS sent successfully.', 'success')
          this.setState({
            sendMessageModal: false
          })
        } else {
          this.notify('Something went wrong', 'warning');
        }
      } else {
        this.notify('Please enter a message', 'warning');
      }
    } catch (e) {
      this.setState({ error: e, isLoading: false, })
      this.notify(e.message, 'warning');
    }
  }

  handleStatusChange = async (status) => {

    if (!this.state.activeUserId || this.state.activeUserId === null) return;
    const url = `/agents/${this.state.activeUserId}/active`;
    let method = 'DELETE';
    if (status) method = 'PUT';

    await axios({
      method,
      url,
      params: {
        apikey: AUTH_TOKEN
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    if (status) {
      this.setState({ statusActiveModal: false, activeUserId: null });
    } else {
      this.setState({ statusInActiveModal: false, activeUserId: null });
    }
    this.refreshTables();

  }

  tableCallBack = (actionType, ...rest) => {
    switch (actionType) {
      case 'IN_ACTIVE_USER':
        this.toggleInActiveModal(...rest)
        break;
      case 'ACTIVE_USER':
        this.toggleActiveModal(...rest)
        break;
      case 'EDIT_USER':
        this.handleSpecificRep(...rest)
        break;
      case 'DISABLE_USER':
        this.handleSpecificRep(...rest)
        break;
      case 'SEND_MESSAGE':
        this.setState({
          sendMessageModal: true,
          activeUser: { ...rest }
        })
        break
      default:
        break;
    }
  }

  render() {
    const { activeUsers } = this.state;
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notify" />
        </div>
        <AddMarketingRep open={this.state.modalState} rep={this.state.rep} onClosed={() => this.setState({ modalState: false })} />
        <EditMarketingRep edit={this.state.edit} rep={this.state.rep} onClosed={() => this.setState({ edit: false })} />
        <DisableMarketingRep disable={this.state.disable} rep={this.state.rep} />

        <Container className="mt--9" fluid>
          <Row className="mb-4">
            <div className="col">
              <Button size="sm" onClick={() => this.setState({ modalState: !this.state.modalState })}>
                Add New Associate
              </Button>
            </div>
          </Row>

          <Row>
            <div className="col">
              <Card >
                <CardHeader >
                  <Row>
                    <Col md="6">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Agent Name, Phone number"
                          type="text"
                          onChange={evt => this.doSearch(evt)}
                        />
                      </InputGroup>
                    </Col>
                    <Col className="text-md-right" md={{ offset: 5, size: 1 }}>
                      <Button color="primary" size="sm" id="buttonTogglerFilters">
                        Filters
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col md="12">
                      <UncontrolledCollapse defaultOpen toggler="#buttonTogglerFilters">
                        <Formik
                          initialValues={this.state.formInitialValue}
                          onSubmit={this.onSubmitFilterForm}
                          onReset={this.onResetForm}
                        >
                          {({ handleReset }) => {
                            return (
                              <Form>
                                <Row>
                                  {/* <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      State
                                    </label>
                                    <Field className="form-control" as="input" name="state" />
                                  </Col>

                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      City
                                    </label>
                                    <Field className="form-control" as="input" name="city" />
                                  </Col> */}

                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Address
                                    </label>
                                    <Field className="form-control" as="input" name="address" />
                                  </Col>
                                  <Col className="mb-3" md="3">
                                    <label className="form-control-label">
                                      Status
                                    </label>
                                    <Field className="form-control" as="select" name="isActive">
                                      <option value="all">All</option>
                                      <option value="true">Active</option>
                                      <option value="false">In-Active</option>
                                    </Field>
                                  </Col>

                                  {/* <Col className="mb-3" md="3">
                                    <label className="form-control-label">
                                      Date
                                    </label>
                                    <Row>
                                      <div className="col pr-1">
                                        <DateRangePicker
                                          name="createdAt"
                                          DATE_FORMAT='YYYY-MM-DD'
                                          placeholder1="start date"
                                          placeholder2="end date"
                                        />
                                      </div>
                                    </Row>
                                  </Col> */}

                                  <Col className="mb-3 pb-1 align-self-end" md="2" >
                                    <Button type="submit">Go</Button>
                                    <Button onClick={handleReset} >Clear</Button>
                                  </Col>
                                </Row>

                              </Form>
                            );
                          }}
                        </Formik>
                      </UncontrolledCollapse>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </div>
          </Row>

          {/* Table */}
          <AgentTables
            {...activeUsers}
            fetchTableRecord={this.fetchActiveUsers}
            tableCallBack={this.tableCallBack}
            headerTitle="Associates"
          />

          <Row>
            <Col md={12}>
              <p className="h5 mb-1">Note:</p>
              <p className="mb-0">
                <small>
                  <strong>Inactivate:</strong>
                  Temporarily disable the user for some time
                </small>
              </p>
              <p className="mb-0">
                <small>
                  <strong>Terminate: </strong>
                  Permanently disable the user - This is just for our future records
                </small>
              </p>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={this.state.sendMessageModal}
          toggle={() => {
            this.setState({
              sendMessageModal: !this.state.sendMessageModal,
              messageText: ''
            })
          }}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleModal}>
            Modify User Status
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="exampleFormControlTextarea1"
              >
                Message text
              </label>
              <Input
                rows="3"
                type="textarea"
                name='messageText'
                required
                readOnly={true}
                value=" If we will receive any complaints on your actions or behavior next time, we will have to terminate you. Please contact us."
                onChange={e => this.setState({ messageText: e.target.value })}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.handleSendMessage}>
              Send
            </Button>
            <Button color="secondary" onClick={() => {
              this.setState({
                sendMessageModal: !this.state.sendMessageModal,
                messageText: " If we will receive any complaints on your actions or behavior next time, we will have to terminate you. Please contact us."
              })
            }}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.statusInActiveModal}
          toggle={() => {
            this.setState({
              statusInActiveModal: !this.state.statusInActiveModal
            })
          }}
          className={this.props.className}
        >
          <ModalHeader toggle={() => {
            this.setState({
              statusInActiveModal: !this.state.statusInActiveModal
            })
          }}>
            In-activate agent
          </ModalHeader>
          <ModalBody>
            <strong>Are you sure you want to in-activate agent?</strong>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => this.handleStatusChange(false)}>
              Yes
            </Button>
            <Button color="secondary" onClick={() => {
              this.setState({
                statusInActiveModal: !this.state.statusInActiveModal
              })
            }}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.statusActiveModal}
          toggle={() => {
            this.setState({
              statusInActiveModal: !this.state.statusActiveModal
            })
          }}
          className={this.props.className}
        >
          <ModalHeader toggle={() => {
            this.setState({
              statusActiveModal: !this.state.statusActiveModal
            })
          }}>
            Activate agent
          </ModalHeader>
          <ModalBody>
            <strong>Are you sure you want to Activate agent?</strong>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => this.handleStatusChange(true)}>
              Yes
            </Button>
            <Button color="secondary" onClick={() => {
              this.setState({
                statusActiveModal: !this.state.statusActiveModal
              })
            }}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

      </>
    );
  }
}
export default MarketingRep;
