import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { useField, useFormikContext } from "formik";
import { Row, Col } from 'reactstrap';

const tableCss = `
.react-datepicker-wrapper{
  display:flex
}
`

function DateRangePicker({ ...props }) {

  const { setFieldValue, values } = useFormikContext();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [field] = useField(props);

  useEffect(() => {
    const dateRangeValues = values?.[props.name];
    if (!dateRangeValues?.[props.fromDate]) {
      setStartDate(null)
    }
    if (!dateRangeValues?.[props.toDate]) {
      setEndDate(null)
    }
  }, [values, props])

  function onChangeMin(date) {
    const { fromDate, toDate } = props;
    const { name } = field;
    const dateString = moment(date).format(props.DATE_FORMAT);

    const val = { [fromDate]: dateString, [toDate]: values[name] && values[name][toDate] }
    setFieldValue(name, val)
    setStartDate(date);
  }

  function onChangeMax(date) {
    const { fromDate, toDate } = props;
    const { name } = field;
    const dateString = moment(date).format(props.DATE_FORMAT)
    const val = { [fromDate]: values[name] && values[name][fromDate], [toDate]: dateString, }
    setFieldValue(name, val)
    setEndDate(date)
  }

  return (
    <>
      <style>{tableCss}</style>
      <Row>
        <Col md={6} className="pr-0">
          <DatePicker
            selectsStart
            className="form-control"
            name={[field.name][props.fromDate]}
            placeholderText={props.placeholder1}
            dateFormat="dd-MM-yyyy"
            onChange={onChangeMin}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
          />
        </Col>
        <Col md={6} className="pl-0">
          <DatePicker
            selectsEnd
            className="form-control"
            name={[field.name][props.toDate]}
            placeholderText={props.placeholder2}
            dateFormat="dd-MM-yyyy"
            onChange={onChangeMax}
            selected={endDate}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
        </Col>
      </Row>
    </>
  )
}

DateRangePicker.defaultProps = {
  DATE_FORMAT: 'dd-MM-yyyy',
  placeholder1: 'select date',
  placeholder2: 'select date',
  fromDate: 'fromDate',
  toDate: 'toDate',
};

export default DateRangePicker
