import React from 'react';
import NotificationAlert from 'react-notification-alert';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import {
  Card,
  Container,
  Modal,
  CardBody,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
} from 'reactstrap';

// core components
import NHeader from 'components/Headers/NHeader.jsx';
import { AUTH_TOKEN } from '../../config'

class AddMarketingRep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      mobileNumber: '',
      email: '',
      address: '',
      aadhar_card: '',
      driving_licence: '',
      refrence_mno: '',
      admin: '',
      showAlert: false,
      notes: ""
    };
  }

  componentWillMount() {
    if (this.props.edit) {
      this.setState({
        name: this.props.rep.name,
        mobileNumber: this.props.rep.mobileNumber,
        email: this.props.rep.email,
        address: this.props.rep.address,
        aadhar_card: this.props.rep.aadhar_card,
        driving_licence: this.props.rep.driving_licence,
        refrence_mno: this.props.rep.refrence_mno,
      });
    }
  }

  handleSubmit = async e => {
    e.preventDefault();
    const {
      name,
      mobileNumber,
      email,
      address,
      aadhar_card,
      driving_licence,
      refrence_mno,
      notes
    } = this.state;
    //  set loading state to true
    this.setState({ isLoading: true });
    let user = localStorage.getItem('user');
    let params = {
      name,
      mobileNumber,
      email,
      address,
      aadhar_card,
      driving_licence,
      refrence_mno,
      admin: user._id,
      notes
    };

    const rep = await axios({
      method: 'POST',
      url: 'agents/register',
      params: {
        apikey: AUTH_TOKEN
      },
      data: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })

    this.notify(
      'tr',
      rep.data.msg || rep.data.message,
      rep.data.success ? 'success' : 'danger'
    );
    if (rep.data.success) {
      this.setState({
        isLoading: false,
      });
      window.location.reload();
    }

    //  call create marketing rep api
  };

  handleUpdate = async (e, repId) => {
    e.preventDefault();
    const {
      name,
      mobileNumber,
      email,
      address,
      aadhar_card,
      driving_licence,
      refrence_mno,
      notes
    } = this.state;
    //  set loading state to true
    this.setState({ isLoading: true });
    let user = localStorage.getItem('user');
    let params = {
      name,
      mobileNumber,
      email,
      address,
      aadhar_card,
      driving_licence,
      refrence_mno,
      admin: user._id,
      notes
    };

    const rep2 = await axios({
      method: 'PUT',
      url: `/agents/${repId}`,
      params: {
        apikey: AUTH_TOKEN
      },
      data: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })

    this.notify(
      'tr',
      rep2.data.msg || rep2.data.message,
      rep2.data.success ? 'success' : 'danger'
    );
    if (rep2.data.success) {
      this.setState({
        isLoading: false,
      });
      window.location.reload();
    }
  };

  handleOnChange = event => {

    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name === 'refrence_mno' || name === 'mobileNumber') {
      if ((event.target.value === '') || (/^[0-9\b]+$/.test(event.target.value) && parseInt(event.target.value, 10) <= 9999999999)) {
        this.setState({
          [name]: value,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }

  };

  notify = (place, message, type) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };
  render() {
    const {
      name,
      mobileNumber,
      email,
      address,
      aadhar_card,
      driving_licence,
      refrence_mno,
      notes
    } = this.state;
    const { edit, rep } = this.props;
    return (
      <>
        <NHeader />
        <Container className="mt--7" fluid>
          <NotificationAlert ref="notificationAlert" />
          <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={this.props.open}
            onClosed={this.props.onClosed}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary shadow border-0">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={this.props.onClosed}
                >
                  <span aria-hidden={true}>×</span>
                </button>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <label>Add New Devotted Associate</label>
                  </div>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Name"
                        name="name"
                        type="text"
                        value={name}
                        onChange={e => this.handleOnChange(e)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        name="email"
                        type="email"
                        value={email}
                        onChange={this.handleOnChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-phone"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Mobile Number"
                        name="mobileNumber"
                        type="number"
                        value={mobileNumber}
                        onChange={this.handleOnChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <i className="fas fa-phone"></i> */}
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Rep Address"
                        name="address"
                        type="text"
                        onChange={this.handleOnChange}
                        value={address}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <i className="fas fa-phone"></i> */}
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Driving License"
                        name="driving_licence"
                        type="text"
                        value={driving_licence}
                        onChange={this.handleOnChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <i className="fas fa-phone"></i> */}
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Aadhar Card"
                        name="aadhar_card"
                        type="text"
                        value={aadhar_card}
                        onChange={this.handleOnChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-phone"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Reference Number"
                        name="refrence_mno"
                        type="number"
                        value={refrence_mno}
                        onChange={this.handleOnChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                  <label>Notes</label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="notes"
                        name="notes"
                        type="textarea"
                        value={notes}
                        onChange={this.handleOnChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={e =>
                        edit
                          ? this.handleUpdate(e, rep._id)
                          : this.handleSubmit(e)
                      }
                    >
                      {edit ? 'Update Associate' : 'Add Associate'}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}
export default withRouter(AddMarketingRep);
