import React from 'react';

// reactstrap components
import { Badge, Container, Row, Col } from 'reactstrap';

class NUserHeader extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props);
  }
  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
          //   style={{
          //     minHeight: "600px",
          //     backgroundImage:
          //       "url(" + require("assets/img/theme/profile-cover.jpg") + ")",
          //     backgroundSize: "cover",
          //     backgroundPosition: "center top"
          //   }}
        >
          {/* Mask */}
          <span className="mask bg-gradient-default opacity-8" />
          {/* Header container */}
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col className="col-auto">
                <h1 className="display-2 text-white">{this.props.user.name}</h1>
                {this.props.user.name && this.props.user.active ? (
                  <Badge className="bg-white badge-lg" pill color="success">
                    Active
                  </Badge>
                ) : (
                  <Badge className="bg-white badge-lg" pill color="danger">
                    In-Active
                  </Badge>
                )}
                {this.props.user &&
                this.props.user.templeMember &&
                this.props.user.templeMember[0] &&
                this.props.user.templeMember[0].isAdmin ? (
                  <Badge className="bg-white badge-lg ml-3" pill color="primary">
                    Admin
                  </Badge>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default NUserHeader;
