import React from 'react';
import moment from 'moment';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Badge
} from 'reactstrap';

const columns = (callBack) => [
  {
    dataField: 'action',
    isDummyField: true,
    text: 'Action',
    formatter: (cell, row) => {
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light action-button-div"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={e => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-h action-button" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right >
            {!row.disable && row.active &&
              <DropdownItem onClick={() => callBack('EDIT_USER', row, 'edit')}>
                Edit
            </DropdownItem>
            }
            {row.active &&
              <DropdownItem onClick={() => callBack('IN_ACTIVE_USER', row, 'active')}>
                Make In-active
            </DropdownItem>
            }
            {!row.active &&
              <DropdownItem onClick={() => callBack('ACTIVE_USER', row, 'inactive')}>
                Make active
             </DropdownItem>
            }
            <DropdownItem onClick={() => callBack('SEND_MESSAGE', row, 'send_message')}>
                Send message
             </DropdownItem>
            {/* {!row.disable &&
              <DropdownItem
                onClick={() => callBack('DISABLE_USER', row, 'disable')}>
                Disable User
            </DropdownItem>
            } */}
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  },
  {
    dataField: 'active',
    text: 'Status',
    sort: true,
    formatter: (cell,row) => {
      return (
        <span style={{color: `${cell === true ? 'black' : 'red'}`,fontWeight:'600'}}>{cell === true ? 'ACTIVE' : 'INACTIVE'}</span>
      )
    }
  },
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
    align: 'left',
  },
  {
    dataField: 'role',
    text: 'Role',
    sort: true
  },
  {
    dataField: 'mobileNumber',
    text: 'Mobile Number',
    formatter: cell => {
      return `(+91) ${cell}`
    }
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: true,
  },
  {
    dataField: 'aadhar_card',
    text: 'Aadhar Card',
    sort: false,
  },
  {
    dataField: 'driving_licence',
    text: 'Driving License',
    sort: true,
  },
  {
    dataField: 'address',
    text: 'Address',
    sort: true,
    formatter: cell => {
      return (
        <div style={{ width: '100px', whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
  {
    dataField: 'createdAt',
    text: 'Registered Date',
    sort: true,
    formatter: (cell) => {
      const registeredDate = moment(cell).format('MMMM Do YYYY, h:mm a');
      return (registeredDate)
    }
  },
  {
    dataField: 'notes',
    text: 'Notes'
  }
];

export default columns