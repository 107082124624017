import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';

import {
  Badge, Media,
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';

import { LANGUAGE } from 'utils/enums/language';
import ImagePreview from 'components/ImagePreview';

const dateToAge = (date) => {
  var replaceStr = date.replace("-",",").replace("-",",")
  var splitSrt = replaceStr.split(",")
  var dateFromArray = ''+splitSrt[1]+'/'+splitSrt[0]+'/'+splitSrt[2]
  var dob = new Date(dateFromArray);
  //calculate month difference from current date in time
  var month_diff = Date.now() - dob.getTime();
  
  //convert the calculated difference in date format
  var age_dt = new Date(month_diff); 
  
  //extract year from date    
  var year = age_dt.getUTCFullYear();
  
  //now calculate the age of the user
  var age = Math.abs(year - 1970);
  if(!age){
    return ''
  }
  //display the calculated age
  else {
    return age
  }
  
}

const columns = (callBack) => [
  {
    dataField: 'action',
    isDummyField: true,
    text: 'Action',
    formatter: (cell, row) => {
      const userUrl = `/admin/user/${row._id}`;
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light action-button-div"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={e => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-h action-button" />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-arrow"
            right
          >
            <DropdownItem>
              <Link to={userUrl}>View Details</Link>
            </DropdownItem>
            {/* <DropdownItem
              onClick={e => callBack('MODAL_SHOW', 'description', row)}
            >
              Delete
              </DropdownItem> */}
            {row.active &&
              <DropdownItem
                onClick={e => callBack('MODAL_SHOW', 'DELETE_MODAL', row)}
              >
                Inactivate
              </DropdownItem>}
            {!row.active &&
              <DropdownItem
                onClick={e => callBack('MODAL_SHOW', 'ENABLE_MODAL', row)}
              >
                Activate
              </DropdownItem>}
            {
              row.email ? 
              <DropdownItem
                onClick={e => callBack('MODAL_SHOW', 'sendEmail', row)}
              >
                Send Email
              </DropdownItem> : <DropdownItem
                onClick={e => callBack('MODAL_SHOW', 'sendMessage', row)}
              >
                Send SMS
              </DropdownItem>
            }
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  },
  {
    dataField: 'active',
    text: 'Status',
    formatter: (cell) => {
      return (
        <>
          <span style={{color: `${cell === true ? 'black' : 'red'}`,fontWeight:'600'}}>{cell === true ? 'ACTIVE' : 'INACTIVE'}</span>
        </>
      )
    }
  },
  {
    dataField: 'createdAt',
    text: 'Registered Date',
    sort: true,
    formatter: (cell) => {
      let registeredDate = cell ?  new Date(cell) : '';
      if (typeof registeredDate === 'object' && registeredDate !== null && 'toDateString' in registeredDate){
        registeredDate = registeredDate.toDateString()
        let dateFormat = registeredDate.split(" ")
        if(dateFormat.length === 4) {
          return (dateFormat[2] + '-' + dateFormat[1] + '-' + dateFormat[3])
        } else {
          return ''
        }
      } else {
        return ''
      }
      
    }
  },
  {
    dataField: 'language',
    text: 'Language',
    sort: true,
    formatter: (cell) => LANGUAGE[cell] || cell
  },
  {
    dataField: 'name',
    text: 'Name',
    classes: 'pt-0',
    sort: true,
    formatter: (cell, row) => {
      return (
        <>
          <Media className="align-items-center">
            <ImagePreview
              imageStyle={{ height: 48 }}
              image={row.profilePhoto || require('assets/img/theme/bootstrap.jpg')}
            />
            <div className="ml-3">
              <span className="mb-0 text-sm">
                {cell}
              </span>
            </div>
          </Media>
        </>
      )
    }
  },
  {
    dataField: 'dob',
    text: 'DOB',
  },
  {
    dataField: 'dob',
    text: 'Age',
    sort: true,
    formatter: (cell) => dateToAge(cell)
  },
  {
    dataField: 'gender',
    text: 'Gender',
  },
  {
    dataField: 'mobileNumber',
    text: 'Mobile Number',
  },
  {
    dataField: 'email',
    text: 'Email',
    sort: true,
  },
  {
    dataField: 'location',
    text: 'Location',
    sort: true,
    formatter: cell => {
      return (
        <div style={{ width: '240px', whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
  {
    dataField: 'city',
    sort: true,
    text: 'City',
  },
  {
    dataField: 'state',
    sort: true,
    text: 'State',
  },
  {
    dataField: 'country',
    sort: true,
    text: 'Country',
  },



  {
    dataField: 'userType',
    text: 'Type',
    formatter: (cell) => {
      return (
        <>
          {true === (cell && cell.religious) ? 'Religious' : ''}
          {true === (cell && cell.spiritual && cell.religious) ?
            ',' : ''}
          {true === (cell && cell.spiritual) ? 'Spiritual' : ''}
        </>
      )
    }
  },
  {
    dataField: 'favorite_temple',
    text: 'Fav Temples (Count)',
    formatter: (cell, row) => {
      return (
        <Link
      className="font-weight-bold"
      to={{
        pathname: '/admin/temples',
        search: `?user=${row._id}`
      }}
    >

{cell && cell.length ?  <Button
        color="primary"
        size="sm"
        // href={cell?.length ? `/admin/temple-updates/?temple=${row._id}` : '#'}
      >
      {cell.length}
      </Button>  : ''}
       
    </Link >
      )
    }
  },
  {
    dataField: 'user_post',
    text: 'User Post (Count)',
    formatter: (cell, row) => {
      return (
    <Link
          className="font-weight-bold"
          to={{
            pathname: '/admin/posts',
            search: `?userId=${row._id}`
          }}
        >
          {cell && cell.length ?  <Button
        color="primary"
        size="sm"
        // href={cell?.length ? `/admin/temple-updates/?temple=${row._id}` : '#'}
      >
      {cell.length}
      </Button>  : ''}
          
        </Link >
        
      )
    }
  },
  
];

export default columns