export const RATING_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'Below 1', value: '0.0' },
  { label: 'Between 1 to 2', value: '1' },
  { label: 'Between 2 to 3', value: '2' },
  { label: 'Between 3 to 4', value: '3' },
  { label: 'Between 4 to 5', value: '4' },
  { label: 'Exact 5', value: '5' },
]

export const GET_RATING_VALUES = {
  '0': { from: 0, to: 1 },
  '1': { from: 1, to: 2 },
  '2': { from: 2, to: 3 },
  '3': { from: 3, to: 4 },
  '4': { from: 4, to: 5 },
  '5': { from: 5, to: 5 },
}