import axios from 'axios'
import { AUTH_TOKEN } from '../../config'

export const ForgotPassword = async (email) => {
  return await axios({
    method: 'POST',
    url: '/admin-user/forgot',
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    data: {
      email
    },
    params: {
      apikey: AUTH_TOKEN
    }
  })
}

export const ResetPassword = async (email, password, token) => {
  return await axios({
    method: 'POST',
    url: '/admin-user/reset',
    data: {
      email,
      password,
      token
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    params: {
      apikey: AUTH_TOKEN
    }
  })
}

export const VerifyResetAttempt = async (token) => {
  return axios({
    method: 'GET',
    url: '/admin-user/verify-reset',
    params: {
      token,
      apikey: AUTH_TOKEN
    },
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
}