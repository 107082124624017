import React from 'react';
import moment from 'moment';

import ReadMoreAndLess from 'react-read-more-less';

const columns = (callBack) => [
  {
    dataField: 'acknowledged',
    text: 'Actions',
    formatter: (cell, row) => {
      return (
        <>
          {(!row.reviewStatus || row.reviewStatus === 'Pending') &&
            <button
              className="btn btn-success btn-sm"
              onClick={() => callBack('MODAL_SHOW', 'APPROVE', row._id)}
            >
              Approve
            </button>}

          {(!row.reviewStatus || row.reviewStatus === 'Pending') &&
            <button
              className="btn btn-danger btn-sm"
              onClick={() => callBack('MODAL_SHOW', 'REJECT', row._id)}
            >
              Reject
            </button>
          }
          {(!row.reviewStatus || row.reviewStatus === 'Pending') &&
            <button
              className="btn btn-warning btn-sm"
              onClick={e => callBack('MODAL_SHOW', 'REVIEW', row._id)}
            >
              Review
            </button>
          }
          {(!row.reviewStatus || row.reviewStatus === 'Pending') &&
            <button
              className="btn btn-info btn-sm"
              onClick={e => callBack('MODAL_SHOW', 'DELETE', row._id)}
            >
              Delete
            </button>}
          <button
            className="btn btn-primary btn-sm"
            onClick={e => callBack('MODAL_SHOW', 'EDIT', row)}
          >
            Edit
              </button>
        </>
      )
    }
  },
  {
    dataField: 'updatedAt',
    text: 'Created Date',
    sort: true,
    formatter: (cell) => {
      return (
        <div style={{ width: '100px', whiteSpace: 'pre-line' }} className="mb-0">{moment(cell).format('MMMM Do YYYY, h:mm a')}</div>
      )
    }
  },
  {
    dataField: 'user[0].name',
    text: 'User Name',
    sort: true,
    formatter: (cell) => {
      return (
        <div style={{ width: '100px', whiteSpace: 'pre-line' }}>{cell || 'N/A'}</div>
      )
    }
  },
  {
    dataField: 'questions',
    text: 'Question',
    formatter: (cell) => {
      return (
        <div style={{ width: '200px', whiteSpace: 'pre-line' }}>
          <ReadMoreAndLess
            className="read-more-content mb-0 text-sm"
            charLimit={100}
            readMoreText=" more"
            readLessText=" less"
            style={{
              whiteSpace: 'normal'
            }}
          >
            {cell || 'N/A'}
          </ReadMoreAndLess>
        </div>
      )
    }
  },
  {
    dataField: 'answers',
    text: 'Answers',
    formatter: (cell) => {
      return (
        <div style={{ width: '200px', whiteSpace: 'pre-line' }}>
          <ReadMoreAndLess
            className="read-more-content mb-0 text-sm"
            charLimit={100}
            readMoreText=" more"
            readLessText=" less"
            style={{
              whiteSpace: 'normal'
            }}
          >
            {cell || 'N/A'}
          </ReadMoreAndLess>
        </div>
      )
    }
  },
  {
    dataField: 'language',
    text: 'Language',
    formatter: (cell) => {
      return (
        <div style={{ width: '200px', whiteSpace: 'pre-line' }}>{cell || 'N/A'}</div>
      )
    }
  },
  {
    dataField: 'reviewStatus',
    text: 'Status',
    formatter: (cell) => {
      let elm = <></>
      if (cell === 'Pending'){
        elm = <span className='badge badge-pill badge-info'>{cell}</span>
      } else if (cell === 'Approved') {
        elm = <span className='badge badge-pill badge-success'>{cell}</span>
      } else if (cell === 'Rejected') {
        elm = <span className='badge badge-pill badge-danger'>{cell}</span>
      } else if (cell === 'Reviewing') {
        elm = <span className='badge badge-pill badge-warning'>{cell}</span>
      }
      return elm
    }
  },

];


export default columns;

