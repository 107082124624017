import React from 'react';
import moment from 'moment';

import { Media, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge,Button } from 'reactstrap';
import ImagePreview from 'components/ImagePreview';


const columns = (callBack) => [
  {
    dataField: 'action',
    isDummyField: true,
    text: 'Action',
    formatter: (cell, row) => {
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light action-button-div"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={e => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-h action-button" />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-arrow"
            right
          >
            {
              row.isActive ? (
                <DropdownItem
                  onClick={e => callBack('MODAL_SHOW', 'DELETE_MODAL', row)}
                >
                  Inactivate
                </DropdownItem>
              ) : (
                <DropdownItem
                  onClick={e => callBack('MODAL_SHOW', 'PUBLISH_MODAL', row)}
                >
                  Activate
                </DropdownItem>
              )
            }
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  },
  {
    dataField: 'isActive',
    text: 'Status',
    sort: true,
    formatter: (cell, row) => {
      return (
        <span style={{ color: `${row.isActive === true ? 'black' : 'red'}`, fontWeight: '600' }}>{row.isActive === true ? 'ACTIVE' : 'INACTIVE'}</span>
      )
    }
  },
  {
    dataField: 'createdAt',
    text: 'Date time',
    sort: true,
    formatter: (cell) => {
      return (
        <>
          <div style={{ width: '100px', whiteSpace: 'pre-line' }} className="mb-0">{moment(cell).format('MMMM Do YYYY, h:mm a')}</div>
        </>
      )
    }
  },
  {
    dataField: 'templeId.name',
    text: 'Temple Name',
    sort: false,
    formatter: (cell) => {
      return (
        <>
          <div style={{ width: '200px', whiteSpace: 'pre-line' }} className="mb-0">{cell}</div>
        </>
      )
    }
  },
  {
    dataField: 'updateName',
    text: 'Update',
    formatter: (cell) => {
      return (
        <>
          <div style={{ width: '200px', whiteSpace: 'pre-line' }} className="mb-0">{cell}</div>
        </>
      )
    }
  },
  {
    dataField: 'address',
    text: 'Location',
    sort: true,
    formatter: (cell) => {
      return (
        <>
          <div style={{ width: '200px', whiteSpace: 'pre-line' }} className="mb-0">{cell}</div>
        </>
      )
    }
  },
  {
    dataField: 'reportsINP',
    text: 'INP',
    sort: true,
    formatter: (cell) => {
      return (
        <Button
        style= {{backgroundColor:'#EF7B45',color:'#fff'}}
          size="sm"
          disabled={!cell?.length}
          onClick={e => e.preventDefault()}
        >
          {cell.length > 10 ? '10+' : cell.length}
        </Button >
      )
    }
  },
  {
    dataField: 'reportsINC',
    text: 'INC',
    sort: true,
    formatter: (cell) => {
      return (
        <Button
          color="success"
          size="sm"
          disabled={!cell?.length}
          onClick={e => e.preventDefault()}
        >
          {cell.length > 10 ? '10+' : cell.length}
        </Button >
      )
    }
  },
  {
    dataField: 'assets',
    text: 'Temple Update Images',
    classes: 'p-2',
    formatter: (cell, row) => {
      const assets = cell.length > 0 ? cell.map(img => img.url) : [];
      return (
        <Media className="align-items-center">
          <ImagePreview
            type="group"
            imageStyle={{ height: 48 }}
            assets={assets}
          />
        </Media>
      )
    }
  },

];

export default columns;
