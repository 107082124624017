import React from 'react';
import moment from 'moment';

import ReadMoreAndLess from 'react-read-more-less';

const columns = (callBack) => [
  {
    dataField: 'created',
    text: 'Created Date',
    sort: true,
    formatter: (cell) => {
      return (
        <div style={{ width: '100px', whiteSpace: 'pre-line' }} className="mb-0">{moment(cell).format('MMMM Do YYYY, h:mm a')}</div>
      )
    }
  },
  {
    dataField: 'user[0].name',
    text: 'User Name',
    sort: true,
    formatter: (cell) => {
      return (
        <div style={{ width: '100px', whiteSpace: 'pre-line' }}>{cell || 'N/A'}</div>
      )
    }
  },
  {
    dataField: 'comment',
    text: 'Comments',
    formatter: (cell) => {
      return (
        <div style={{ width: '200px', whiteSpace: 'pre-line' }}>
          <ReadMoreAndLess
            className="read-more-content mb-0 text-sm"
            charLimit={100}
            readMoreText=" more"
            readLessText=" less"
            style={{
              whiteSpace: 'normal'
            }}
          >
            {cell || 'N/A'}
          </ReadMoreAndLess>
        </div>
      )
    }
  },
  {
    dataField: 'rating',
    text: 'Appearance',
    formatter: (cell) => {
      let elm = <></>
      if (cell[0][1] === '1') {
        elm = <span className='badge badge-pill badge-danger'>{1}</span>
      } else if (cell[0][1] === '2') {
        elm = <span className='badge badge-pill badge-warning'>{2}</span>
      } else if (cell[0][1] === '3') {
        elm = <span className='badge badge-pill badge-info'>{3}</span>
      } else if (cell[0][1] === '4' || cell[0][1] === '5') {
        elm = <span className='badge badge-pill badge-success'>{cell[0][1]}</span>
      }
      return elm
    }
  },
  {
    dataField: 'rating',
    text: 'Content',
    formatter: (cell) => {
      let elm = <></>
      if (cell[0][2] === '1') {
        elm = <span className='badge badge-pill badge-danger'>{1}</span>
      } else if (cell[0][2] === '2') {
        elm = <span className='badge badge-pill badge-warning'>{2}</span>
      } else if (cell[0][2] === '3') {
        elm = <span className='badge badge-pill badge-info'>{3}</span>
      } else if (cell[0][2] === '4' || cell[0][2] === '5') {
        elm = <span className='badge badge-pill badge-success'>{cell[0][2]}</span>
      }
      return elm
    }
  },
  {
    dataField: 'rating',
    text: 'Navigation',
    formatter: (cell) => {
      let elm = <></>
      if (cell[0][3] === '1') {
        elm = <span className='badge badge-pill badge-danger'>{1}</span>
      } else if (cell[0][3] === '2') {
        elm = <span className='badge badge-pill badge-warning'>{2}</span>
      } else if (cell[0][3] === '3') {
        elm = <span className='badge badge-pill badge-info'>{3}</span>
      } else if (cell[0][3] === '4' || cell[0][3] === '5') {
        elm = <span className='badge badge-pill badge-success'>{cell[0][3]}</span>
      }
      return elm
    }
  },
  {
    dataField: 'rating',
    text: 'Performance',
    formatter: (cell) => {
      let elm = <></>
      if (cell[0][4] === '1') {
        elm = <span className='badge badge-pill badge-danger'>{1}</span>
      } else if (cell[0][4] === '2') {
        elm = <span className='badge badge-pill badge-warning'>{2}</span>
      } else if (cell[0][4] === '3') {
        elm = <span className='badge badge-pill badge-info'>{3}</span>
      } else if (cell[0][4] === '4' || cell[0][4] === '5') {
        elm = <span className='badge badge-pill badge-success'>{cell[0][4]}</span>
      }
      return elm
    }
  },
  {
    dataField: 'rating',
    text: 'Others',
    formatter: (cell) => {
      let elm = <></>
      if (cell[0][5] === '1') {
        elm = <span className='badge badge-pill badge-danger'>{1}</span>
      } else if (cell[0][5] === '2') {
        elm = <span className='badge badge-pill badge-warning'>{2}</span>
      } else if (cell[0][5] === '3') {
        elm = <span className='badge badge-pill badge-info'>{3}</span>
      } else if (cell[0][5] === '4' || cell[0][5] === '5') {
        elm = <span className='badge badge-pill badge-success'>{cell[0][5]}</span>
      }
      return elm
    }
  },
];


export default columns;

