import React from 'react';
import NotificationAlert from 'react-notification-alert';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import {
  Card,
  Container,
  Modal,
  CardBody,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
} from 'reactstrap';
import { AUTH_TOKEN } from '../../config'

class EditMarketingRep extends React.Component {
  constructor(props) {
    super(props);
    const {
      rep
    } = props
    this.state = {
      repId: rep._id || '',
      name: rep.name || '',
      mobileNumber: rep.mobileNumber || '',
      email: rep.email || '',
      address: rep.address || '',
      aadhar_card: rep.aadhar_card || '',
      driving_licence: rep.driving_licence || '',
      refrence_mno: rep.refrence_mno || '',
      admin: rep.admin || '',
      showAlert: false,
      notes: rep.notes || ''
    };
  }

  static getDerivedStateFromProps(props, state){
    const {
      rep: {
        name,
        mobileNumber,
        email,
        address,
        aadhar_card,
        driving_licence,
        refrence_mno,
        _id,
        notes
      }
    } = props;
    if (_id !== state.repId) {
      return {
        name,
        mobileNumber,
        email,
        address,
        aadhar_card,
        driving_licence,
        refrence_mno,
        repId: _id,
        notes
      }
    }
    return state
  }

  handleSubmit = async e => {
    e.preventDefault();
    const {
      name,
      mobileNumber,
      email,
      address,
      aadhar_card,
      driving_licence,
      refrence_mno,
      notes
    } = this.state;
    //  set loading state to true
    this.setState({ isLoading: true });
    let user = localStorage.getItem('user');
    let params = {
      name,
      mobileNumber,
      email,
      address,
      aadhar_card,
      driving_licence,
      refrence_mno,
      admin: user._id,
      notes
    };
    
    const rep = await axios({
      method: 'PUT',
      url: `/agents/register`,
      params: {
        apikey: AUTH_TOKEN
      },
      data: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })

    this.notify(
      'tr',
      rep.data.msg || rep.data.message,
      rep.data.success ? 'success' : 'danger'
    );
    if (rep.data.success) {
      this.setState({
        isLoading: false,
      });
      window.location.reload();
    }

    //  call create marketing rep api
  };

  handleUpdate = async (e, repId) => {
    e.preventDefault();
    const {
      name,
      mobileNumber,
      email,
      address,
      aadhar_card,
      driving_licence,
      refrence_mno,
      notes
    } = this.state;
    //  set loading state to true
    this.setState({ isLoading: true });
    let user = localStorage.getItem('user');
    const { rep } = this.props;
    let params = {
      name: name || rep.name,
      mobileNumber: mobileNumber || rep.mobileNumber,
      email: email || rep.email,
      address: address || rep.address,
      aadhar_card: aadhar_card || rep.aadhar_card,
      driving_licence: driving_licence || rep.driving_licence,
      refrence_mno: refrence_mno || rep.refrence_mno,
      admin: user._id,
      notes
    };
    
    const rep2 = await axios({
      method: 'PUT',
      url: `/agents/${repId}`,
      params: {
        apikey: AUTH_TOKEN
      },
      data: params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })

    this.notify(
      'tr',
      rep2.data.msg || rep2.data.message,
      rep2.data.success ? 'success' : 'danger'
    );
    if (rep2.data.success) {
      this.setState({
        isLoading: false,
      });
      window.location.reload();
    }
  };

  handleOnChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  notify = (place, message, type) => {
    let options = {
      place: place,
      message: (
        <div className="alert-text">
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  render() {
    const {
      name,
      mobileNumber,
      email,
      address,
      aadhar_card,
      driving_licence,
      refrence_mno,
      notes
    } = this.state;
    const { edit, rep } = this.props;
    return (
      <>
        <Container className="mt--7" fluid>
          <NotificationAlert ref="notificationAlert" />
          <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={this.props.edit}
            onClosed={this.props.onClosed}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary shadow border-0">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={this.props.onClosed}

                >
                  <span aria-hidden={true}>×</span>
                </button>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <label>Edit Associate</label>
                  </div>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Name"
                        name="name"
                        type="text"
                        onChange={e => this.handleOnChange(e)}
                        value={name}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Agent Email"
                        name="email"
                        type="email"
                        value={email}
                        onChange={this.handleOnChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-phone"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Mobile Number"
                        name="mobileNumber"
                        type="number"
                        value={mobileNumber}
                        onChange={this.handleOnChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <i className="fas fa-phone"></i> */}
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Rep Address"
                        name="address"
                        type="text"
                        onChange={this.handleOnChange}
                        value={address}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <i className="fas fa-phone"></i> */}
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Driving License"
                        name="driving_licence"
                        type="text"
                        value={driving_licence}
                        onChange={this.handleOnChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <i className="fas fa-phone"></i> */}
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Aadhar Card"
                        name="aadhar_card"
                        type="text"
                        value={aadhar_card}
                        onChange={this.handleOnChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-phone"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Reference Number"
                        name="refrence_mno"
                        type="number"
                        value={refrence_mno}
                        onChange={this.handleOnChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                  <label>Notes</label>
                    <InputGroup className="input-group-alternative">
                      <Input
                        placeholder="notes"
                        name="notes"
                        type="textarea"
                        value={notes}
                        onChange={this.handleOnChange}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={e => this.handleUpdate(e, rep?._id)}
                    >
                      {edit ? 'Update Rep' : 'Add Rep'}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}
export default withRouter(EditMarketingRep);
