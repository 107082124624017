import axios from 'axios';
import React, { Component } from 'react';
import {
  Card,
  Container,
  Row,
  Input,
  Button
} from "reactstrap";

import NHeader from "../components/Headers/NHeader";
import { AUTH_TOKEN } from '../config'

class StaticUploads extends Component {

  state = {
    selectedFile: null,
    type: ''
  };

  // On file select (from the pop up) 
  onFileChange = event => {

    // Update the state 
    this.setState({ selectedFile: event.target.files[0] });

  };

  // On file upload (click the upload button) 
  onFileUpload = async () => {
    try {
      const {
        selectedFile,
        type
      } = this.state
      const acceptedTypes = [
        ".csv",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ]
      if (selectedFile && type && acceptedTypes.includes(this.state.selectedFile.type)) {
        // Create an object of formData 
      const formData = new FormData();
  
      // Update the formData object 
      formData.append(
        "myFile",
        this.state.selectedFile,
        this.state.selectedFile.name
      );
  
      // Details of the uploaded file 
      console.log(this.state.selectedFile);
  
      // Request made to the backend api 
      // Send formData object 
      axios.post("misc/static-upload", formData, {
        params: {
          apikey: AUTH_TOKEN,
          type
        },
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(response => {
        const { data } = response;
        if (data.success) {
          alert('File uploaded successfully!');
          window.location.reload();
        } else {
          alert(data.message);
        }
      });
      } else {
        alert('Please and file and choose type to proceed.')
      }
    } catch (e) {
      alert('File could not be uploaded')
    }
  };

  // File content to be displayed after 
  // file upload is complete 
  fileData = () => {

    if (this.state.selectedFile) {

      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {this.state.selectedFile.name}</p>
          <p>File Type: {this.state.selectedFile.type}</p>
          <p>
            Last Modified:{" "}
            {this.state.selectedFile.lastModifiedDate.toDateString()}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  };

  render() {

    return (
      <>
        <NHeader />
        <Container>
          <Row>
            <div className="col">
              <Card className="shadow">
                <div style={{ padding: '5%' }}>
                  <h3>
                    Upload excel files for static data upload
                  </h3>
                  <div>
                    <label>* Choose static data type</label>
                    <Input
                      type="select"
                      required
                      value={this.state.type}
                      onChange={e => {
                        this.setState({
                          type: e.target.value
                        })
                      }}
                    >
                      <option value="">--Select Type--</option>
                      <option value="dharmas">Dharmas</option>
                      <option value="prayers">Prayers</option>
                      <option value="quotes">Quotes</option>
                      <option value="healthtips">Health Tips</option>
                    </Input>
                    <br />
                    <label>* Choose file</label>
                    <Input type="file" onChange={this.onFileChange} />
                    <br />
                    <Button onClick={this.onFileUpload}>
                      Upload!
                    </Button>
                  </div>
                  {this.fileData()}
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </>

    );
  }
}

export default StaticUploads;
