import React from 'react';
import moment from 'moment';

import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Media,
} from 'reactstrap';

import ReadMoreAndLess from 'react-read-more-less';
import ImagePreview from 'components/ImagePreview';


const columns = (callBack) => [
  {
    dataField: 'action',
    isDummyField: true,
    text: 'Action',
    formatter: (cell, row) => {
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light action-button-div"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={e => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-h action-button" />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-arrow"
            right
          >
            {
              row.isActive ?
                <DropdownItem
                  onClick={() => callBack('MODAL_ACTION', 'DELETE_MODAL', row)}
                >
                  Inactivate
                </DropdownItem>:
                <DropdownItem
                  onClick={() => callBack('MODAL_ACTION', 'ENABLE_MODAL', row)}
                >
                  Activate
                </DropdownItem>
            }
            {
              <DropdownItem
                onClick={e => callBack('MODAL_ACTION', 'SEND_MESSAGE', row)}
              >
                Send reminder (SMS)
            </DropdownItem>
            }
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  },
  {
    dataField: 'isActive',
    text: 'Status',
    formatter: (cell) => {
      return (
        <>
         <span style={{color: `${cell === true ? 'black' : 'red'}`,fontWeight:'600'}}>{cell === true ? 'ACTIVE' : 'INACTIVE'}</span>
        </>
      )
    }
  },
  {
    dataField: 'createdAt',
    text: 'Approved Date',
    sort: true,
    formatter: (cell) => {
      let registeredDate = cell ?  new Date(cell) : '';
      if (typeof registeredDate === 'object' && registeredDate !== null && 'toDateString' in registeredDate){
        registeredDate = registeredDate.toDateString()
        let dateFormat = registeredDate.split(" ")
        if(dateFormat.length === 4) {
          return (dateFormat[2] + '-' + dateFormat[1] + '-' + dateFormat[3])
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  },
  {
    dataField: 'type',
    text: 'Type',
    formatter: (cell,row) => {
      return (
        <>
          <p className="mb-0 text-sm">
          {row.isAdmin ?
            <label>
              <span className="badge badge-pill badge-success">
                Admin
            </span>
            </label> :  <label>
              <span className="badge badge-pill badge-dark">
                Member
            </span>
            </label>
          }
          </p>
        </>
      )
    }
  },
  {
    dataField: 'role',
    text: 'Role',
    formatter: (cell, row) => {
      return (
        <>
          <h5 className="mb-0">{cell}</h5>
          {/* {row.isAdmin &&
            <label>
              <span className="badge badge-pill badge-success">
                Admin
            </span>
            </label>
          } */}
        </>
      )
    }
  },
  {
    dataField: 'rc',
    text: 'Rem Count',
    sort: true,
    formatter: (cell) => {
      return (
        <>
          <h5>{cell}</h5>
        </>
      )
    }
  },
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
    formatter: (cell) => {
      return (
        <>
          <h5>{cell}</h5>
        </>
      )
    }
  },
  {
    dataField: 'mobileNumber',
    text: 'Mobile Number',
    sort: true,
    formatter: (cell) => {
      return (
        <>
          <h5>{cell}</h5>
        </>
      )
    }
  },
  {
    dataField: 'photos',
    text: 'Photo',
    classes: 'pt-1 pb-0',
    formatter: (cell) => {
      const assets = cell && cell[0] && cell[0].files && cell[0].files.length > 0 ? cell[0].files.map(img => img) : [];
      return (
        <Media className="align-items-center">
          <ImagePreview
            type="group"
            imageStyle={{ height: 48 }}
            assets={assets}
          />
        </Media>
      )
    }
  },
  {
    dataField: 'idProof',
    text: 'Id Proof',
    classes: 'pt-1 pb-0',
    formatter: (cell) => {
      const assets = cell && cell[0] && cell[0].files && cell[0].files.length > 0 ? cell[0].files.map(img => img) : [];
      return (
        <Media className="align-items-center">
          <ImagePreview
            type="group"
            imageStyle={{ height: 48 }}
            assets={assets}
          />
        </Media>
      )
    }
  },
  {
    dataField: 'attachments',
    text: 'Attachments',
    classes: 'p-2',
    formatter: (cell) => {
      const assets = cell && cell[0] && cell[0].files && cell[0].files.length > 0 ? cell[0].files.map(img => img) : [];
      return (
        <Media className="align-items-center">
          <ImagePreview
            type="group"
            imageStyle={{ height: 48 }}
            assets={assets}
          />
        </Media>
      )
    }
  },

  {
    dataField: 'temple',
    text: 'Temple Name',
    sort: true,
    formatter: (cell) => {
      return (
        <>
          <p
            className="mb-0 text-sm"
            style={{
              whiteSpace: 'normal'
            }}
          >
            <strong style={{ fontWeight: 700 }}>{cell?.name}</strong>
          </p>
          {/* <div style={{ whiteSpace: 'normal' }}>
              {cell?.name}
            
          </div> */}
        </>
      )
    }
  },
  {
    dataField: 'temple',
    text: 'Location',
    sort: true,
    formatter: (cell) => {
      return (
        <>
          <div style={{ width: '240px', whiteSpace: 'pre-line' }}>
              {cell?.address}
            
          </div>
        </>
      )
    }
  },
  {
    dataField: 'temple',
    text: 'City',
    sort: true,
    formatter: (cell) => {
      return (
        <>
          <div style={{ whiteSpace: 'normal' }}>
              {cell?.city}
            
          </div>
        </>
      )
    }
  },
  {
    dataField: 'temple',
    text: 'State',
    sort: true,
    formatter: (cell) => {
      return (
        <>
          <div style={{ whiteSpace: 'normal' }}>
              {cell?.state}
            
          </div>
        </>
      )
    }
  },
  {
    dataField: 'temple',
    text: 'Country',
    sort: true,
    formatter: (cell) => {
      return (
        <>
          <div style={{ whiteSpace: 'normal' }}>
              {cell?.country}
            
          </div>
        </>
      )
    }
  },
  {
    dataField: 'source',
    text: 'Source',
    formatter: (cell) => {
      return (
        <>
          <p className="mb-0 text-sm">
            {
              cell && (
                <b>{ cell === 'admin' ? 'Admin' : cell.charAt(0).toUpperCase() + cell.slice(1) }</b>
              )
            }
          </p>
        </>
      )
    }
  },
  {
    dataField: 'approvedBy',
    text: 'Approved By',
    formatter: (cell) => {
      return (
        <>
          <p className="mb-0 text-sm">
            <b>{cell?.name}</b>
          </p>
          <p className="mb-0 text-sm">
            {cell?.mobileNumber}
          </p>
        </>
      )
    }
  },

];

export default columns