import React from "react";
import NotificationAlert from "react-notification-alert";
import qs from 'qs';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col
} from "reactstrap";

import axios from 'axios';

import { AUTH_TOKEN } from '../../config'

const NotificationOptions = {
  place: "tr",
  message: (
    <div className="alert-text">
      <span data-notify="message">
        Invalid OTP
      </span>
    </div>
  ),
  type: 'warning',
  icon: "ni ni-bell-55",
  autoDismiss: 7
};

class OtpScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      OTP: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(e) {
    if ((e.target.value === '') || (/^[0-9\b]+$/.test(e.target.value) && parseInt(e.target.value, 10) <= 999999)) {
      const target = e.target;
      const value = target.value;
      const name = target.name;
  
      this.setState({
        [name]: value
      });
    } else {

    }
  }

  async handleSubmit(event) {
    try {
      event.preventDefault();
      
      const { OTP } = this.state;
      this.setState({ isLoading: true });
      const currentLoc = this.props.location.pathname;
      const { location: { search } } = this.props;
      const filters = qs.parse(search, { ignoreQueryPrefix: true });
      const url = currentLoc.includes("superuser") ? "/super/verifyOtp" : "admin-user/verifyOtp";
      const data = { otp: OTP, email: filters.email };
      
      const response = await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        data,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      });
      //  set loading to false
      this.setState({ isLoading: false });
      console.log(response.data);
      if (response.data.success && response.data.token) {
        this.setState({ email: "", password: "" });
        // setter
        localStorage.setItem("user", JSON.stringify(response.data.adminUser));
        localStorage.setItem("token", response.data.token);
        this.props.history.push("/admin/home");
      } else {
        console.log("called");
        this.refs.notificationAlert.notificationAlert(NotificationOptions);
      }
    } catch (error) {
      console.log(error);
      this.refs.notificationAlert.notificationAlert(NotificationOptions);
    }
  }

  render() {
    const { OTP } = this.state;
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Enter OTP</small>
              </div>
              <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={this.handleChange}
                      value={OTP}
                      placeholder="Enter OTP"
                      type="number"
                      name="OTP"
                    />
                  </InputGroup>
                </FormGroup>
                {/* <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div> */}
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="submit"
                  >
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          {/* <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row> */}
        </Col>
      </>
    );
  }
}

export default OtpScreen;
