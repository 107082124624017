import React, { Component } from 'react';
import axios from 'axios';
import qs from 'qs';
import lodash from 'lodash';
import { Formik, Form, Field } from 'formik';
import NotificationAlert from "react-notification-alert";
import { Scrollbars } from 'react-custom-scrollbars';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory,
{ PaginationProvider, PaginationListStandalone }
  from "react-bootstrap-table2-paginator";
import {
  Container, Row, Col,
  Card, CardHeader, CardFooter,
  Input, InputGroup, InputGroupAddon, InputGroupText,
  FormGroup,
  Button,
  Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledCollapse,
} from 'reactstrap';

import DateRangePicker from 'components/Formik/DateRangePicker';
import columns from 'views/events/column';

import { AUTH_TOKEN } from '../../config'

function clean(obj) {
  for (var propName in obj) {
    console.log("propName, obj[propName] ----> ", propName, obj[propName])
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
      delete obj[propName];
    }
  }
  return obj
}

class TempleUpdatesList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formInitialValue: { address: '', locality: '', isActive: '', report: '', INC: '', INP: '' },
      loading: true,
      error: null,
      modal: false,
      activeUser: null,
      templeList: [],
      search: '',
      filters: {},
      page: 1,
      sizePerPage: 50,
      total: 0,
      sortField: undefined,
      sortOrder: null
    };
  }

  componentDidMount() {
    const { location: { search } } = this.props;
    const filters = qs.parse(search, { ignoreQueryPrefix: true });
    this.setState({ filters })
    // this.fetchTableData()
  }

  fetchTableData = async () => {
    try {
      const { search, page, sizePerPage, sortField, sortOrder, filters } = this.state;
      let url = '/events';
      let query = { page, sizePerPage };

      if (sortField && sortOrder) { query = { ...query, sortField, sortOrder } }
      const copyOfFilters = JSON.parse(JSON.stringify(filters))
      if (copyOfFilters.createdAt) {
        if (copyOfFilters.createdAt.toDate) {
          const toDate = new Date(copyOfFilters.createdAt.toDate);
          toDate.setDate(toDate.getDate() + 1)
          copyOfFilters.createdAt.toDate = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`

        } else if (copyOfFilters.createdAt.fromDate && !copyOfFilters.createdAt.toDate) {
          const todayDate = new Date()
          copyOfFilters.createdAt.toDate = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`
        }
      }
      copyOfFilters.filters = clean(copyOfFilters.filters);
      if (copyOfFilters) {
        query = { ...query, isActive: copyOfFilters.isActive, filters }
      }
      if (search) { query = { ...query, search: search } }
      query = clean(query);
      query.filters = clean(query.filters);
      url = `${url}?${qs.stringify(query)}`;
      console.log("query--",query)
      const response = await axios({
        method: 'GET',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      this.setState({
        loading: false,
        templeList: response.data.events || [],
        total: response.data.pagination.total,
      })

    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  }

  doSearch = (evt) => {
    this.setState({ page: 1, search: evt.target.value }, this.fetchTableData)
    // const searchText = ;
    // this.debounceFn(searchText);
  }

  handleTableChange = (type, { page, sizePerPage, filters, ...rest }) => {
    if (type === 'pagination') {
      this.setState({ sizePerPage, page }, this.fetchTableData)
    }

    if (type === 'sort') {
      this.setState({ sortOrder: rest.sortOrder, sortField: rest.sortField }, this.fetchTableData)
    }
  }

  onSubmitFilterForm = (values) => {
    const filters = { ...values };
    console.log(filters)
    this.setState({ filters }, this.fetchTableData)
  }

  onResetForm = () => {
    this.setState({ filters: {}, templeList: [] })
  }

  handleDeleteRecord = async () => {
    try {
      const { activeUser } = this.state;
      if (!activeUser || activeUser === null) return;
      this.setState({ loading: true })
      const id = activeUser._id;
      const url = `/events/${id}/disable`;

      await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      this.notify('Yes, Inactivate it!', 'success');
      this.setState({
        loading: false,
        modal: false,
        activeUser: null,
      }, this.fetchTableData)

    } catch (error) {
      this.setState({ isLoading: false, modal: false, activeUser: null });
      this.notify('Something went wrong', 'warning');
    }
  }
  handlePublishRecord = async () => {
    try {
      const { activeUser } = this.state;
      if (!activeUser || activeUser === null) return;
      this.setState({ loading: true })
      const id = activeUser._id;
      const url = `/events/${id}/enable`;

      await axios({
        method: 'POST',
        url,
        params: {
          apikey: AUTH_TOKEN
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      this.notify('Yes, Activate it!', 'success');
      this.setState({
        loading: false,
        modal: false,
        activeUser: null,
      }, this.fetchTableData)

    } catch (error) {
      this.setState({ isLoading: false, modal: false, activeUser: null });
      this.notify('Something went wrong', 'warning');
    }
  }

  toggleModal = (modelName, user) => {
    this.setState(prevState => ({
      modal: {
        ...prevState.modal,
        [modelName]: !prevState.modal[modelName]
      },
      activeUser: user,
    }))
  }

  notify(messageText = 'success', type = 'success', place = 'tr') {
    const options = {
      place,
      type,
      autoDismiss: 7,
      icon: "ni ni-bell-55",
      message: (
        <div className="alert-text">
          <span data-notify="message">
            {messageText}
          </span>
        </div>
      ),
    }
    this.refs.notify.notificationAlert(options);
  };

  tableCallBack = (actionType, ...rest) => {
    switch (actionType) {
      case 'MODAL_SHOW':
        this.toggleModal(...rest)
        break;
      default:
        break;
    }
  }

  render() {

    const paginationOption = {
      custom: true,
      totalSize: this.state.total,
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
    }
    const pagination = paginationFactory(paginationOption);

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notify" />
        </div>
        {/* Page header */}
        <div className="header header-dark bg-info pb-6 pt-5 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
              </Row>
            </div>
          </Container>
        </div>

        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader >
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup className="mb-0">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Search by event name, temple name, location "
                            type="text"
                            onChange={evt => this.doSearch(evt)}
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                    <Col className="text-md-right" md={{ offset: 5, size: 1 }}>
                      <Button color="primary" size="sm" id="buttonTogglerFilters">
                        Filters
                      </Button>
                    </Col>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <UncontrolledCollapse defaultOpen toggler="#buttonTogglerFilters">
                        <Formik
                          initialValues={this.state.formInitialValue}
                          onSubmit={this.onSubmitFilterForm}
                          onReset={this.onResetForm}
                        >
                          {({ handleReset }) => {
                            return (
                              <Form>
                                <Row>
                                  {/* <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Locality
                                    </label>
                                    <Field className="form-control" as="input" name="locality" placeholder="locality" />
                                  </Col> */}
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Inappropriate
                                    </label>
                                    <Field className="form-control" as="select" name="INP">
                                      <option value=''>select</option>
                                      <option value='10'>Up to 10</option>
                                      <option value='10+'>10+</option>
                                    </Field>
                                  </Col>
                                  <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Incorrect
                                    </label>
                                    <Field className="form-control" as="select" name="INC">
                                      <option value=''>select</option>
                                      <option value='10'>Up to 10</option>
                                      <option value='10+'>10+</option>
                                    </Field>
                                  </Col>
                                  {/* <Col className="mb-3" md="2">
                                    <label className="form-control-label">
                                      Address
                                    </label>
                                    <Field className="form-control" as="input" name="address" placeholder="address" />
                                  </Col> */}

                                  <Col className="mb-3" md="3">
                                    <label className="form-control-label">
                                      Date
                                    </label>
                                    <Row>
                                      <div className="col pr-1">
                                        <DateRangePicker
                                          name="createdAt"
                                          DATE_FORMAT='YYYY-MM-DD'
                                          placeholder1="start date"
                                          placeholder2="end date"
                                        />
                                      </div>
                                    </Row>
                                  </Col>
                                  <Col className="mb-3" md="3">
                                    <label className="form-control-label">
                                      Status
                                    </label>
                                    <Field className="form-control" as="select" name="isActive" component="select">
                                      <option value="">All</option>
                                      <option value="true">Active</option>
                                      <option value="false">In-Active</option>
                                    </Field>
                                  </Col>
                                  <Col className="mb-3 pb-1 align-self-end" md="2" >
                                    <Button type="submit">Go</Button>
                                    <Button onClick={handleReset}>Reset</Button>
                                  </Col>
                                </Row>

                              </Form>
                            );
                          }}
                        </Formik>
                      </UncontrolledCollapse>
                    </div>
                  </div>
                </CardHeader>

                <PaginationProvider
                  pagination={pagination}
                >
                  {({
                    paginationProps,
                    paginationTableProps
                  }) => (
                    <>
                      <div className="table-responsive">
                        <Scrollbars style={{ height: '50vh' }}>
                          <BootstrapTable
                            keyField='_id'
                            remote
                            bootstrap4={true}
                            bordered={false}
                            data={this.state.templeList}
                            columns={columns(this.tableCallBack)}
                            onTableChange={this.handleTableChange}
                            {...paginationTableProps}
                          />
                        </Scrollbars>
                      </div>

                      <CardFooter>
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </CardFooter>
                    </>
                  )
                  }
                </PaginationProvider>
              </Card>
            </div>
          </Row>
        </Container>

        <Modal
          isOpen={this.state.modal['DELETE_MODAL']}
          toggle={e => this.toggleModal('DELETE_MODAL')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('DELETE_MODAL')}>Inactivate Event</ModalHeader>
          <ModalBody>Are you sure you want to Inactivate this event?</ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.handleDeleteRecord}>
              Inactivate
            </Button>
            <Button color="secondary" onClick={e => this.toggleModal('DELETE_MODAL')}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modal['PUBLISH_MODAL']}
          toggle={e => this.toggleModal('PUBLISH_MODAL')}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.toggleModal('PUBLISH_MODAL')}>Activate Event</ModalHeader>
          <ModalBody>Are you sure you want to Activate?</ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.handlePublishRecord}>
              Activate
            </Button>
            <Button color="secondary" onClick={e => this.toggleModal('PUBLISH_MODAL')}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

export default TempleUpdatesList
