import React from 'react';
import moment from 'moment';

const columns = (callBack) => [
  {
    dataField: 'createdAt',
    text: 'Added On',
    sort: true,
    formatter: (cell) => {
      return (
        <div style={{ width: '100px', whiteSpace: 'pre-line' }} className="mb-0">{moment(cell).format('MMMM Do YYYY, h:mm a')}</div>
      )
    }
  },
  {
    dataField: 'agent[0].name',
    text: 'Agent',
    formatter: (cell) => {
      return (
        <div style={{ width: '100px', whiteSpace: 'pre-line' }}>{cell || 'N/A'}</div>
      )
    }
  },
  {
    dataField: 'temple[0].name',
    text: 'Temple',
    formatter: (cell) => {
      return (
        <div style={{ width: '200px', whiteSpace: 'pre-line' }}>{cell || 'N/A'}</div>
      )
    }
  },
  {
    dataField: 'temple[0].address',
    text: 'Temple Address',
    formatter: (cell) => {
      return (
        <div style={{ width: '200px', whiteSpace: 'pre-line' }}>{cell || 'N/A'}</div>
      )
    }
  },
  {
    dataField: 'references',
    text: 'References',
    formatter: (cell) => {
      return cell.map(c => (
      <p>{c.name} ({c.phone})</p>
      ))
    }
  }
];


export default columns;

