import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Row,
  CardTitle,
  Button,
  Col,
  Spinner,
} from "reactstrap";
import Axios from "axios";

import "./InfoCard.css";
import { AUTH_TOKEN } from "../../config";

function InfoCard(props) {
  const response = { total: 0, today: 0, hours: 0 };
  const [data, setData] = useState(response);
  const [loader, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchCountByType = useCallback(async () => {
    const url = `/misc/dashboard-count?type=${props.apiType}`;
    console.log("url....", url);
    // const response = await Axios.get(url).then(res => res.data).catch(error => error.message);
    // if (response.data) {
    //   setData(response.data)
    // }
    Axios({
      method: "GET",
      url,
      params: {
        apikey: AUTH_TOKEN,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        console.log("fghjg", res.data.data);
        setLoading(false);
        setData(res.data.data);
      })
      .catch((err) => {
        setError(true);
      });
  }, [props.apiType]);

  useEffect(() => {
    fetchCountByType();
  }, [fetchCountByType]);

  return (
    <>
      <Card className={`${props.bgColor} home-card`}>
        {loader ? (
          <Spinner animation="border" variant="light" />
        ) : error ? (
          <p>Error...</p>
        ) : (
          <CardBody>
            <Row>
              <Col sm="9">
                <CardTitle tag="h5" className="text-uppercase text-white mb-2">
                  {props.title}
                </CardTitle>
              </Col>
              <Col sm="3">
                <span
                  className={`h2 font-weight-bold mb-0 text-white ${
                    data.total ? "values" : ""
                  }`}
                  onClick={data.total ? props.onClickTotal : () => {}}
                >
                  {data.total}
                </span>
              </Col>
            </Row>
            <Row>
              {props.monthlyText && (
                <div className="col-sm-3">
                  <Button
                    className="px-0 text-white"
                    color="link"
                    style={{ cursor: "auto" }}
                  >
                    {props.monthlyText}
                  </Button>
                  <br />
                  <div
                    className={`h2 font-weight-bold mb-0 text-white ${
                      data.monthly ? "values" : ""
                    }`}
                    onClick={data.monthly ? props.onClickMonthly : () => {}}
                  >
                    {data.monthly || 0}
                  </div>
                </div>
              )}
              {props.weeklyText && (
                <div className="col-sm-3">
                  <Button
                    className="px-0 text-white"
                    color="link"
                    style={{ cursor: "auto" }}
                  >
                    {props.weeklyText}
                  </Button>
                  <br />
                  <div
                    className={`h2 font-weight-bold mb-0 text-white ${
                      data.weekly ? "values" : ""
                    }`}
                    onClick={data.weekly ? props.onClickWeekly : () => {}}
                  >
                    {data.weekly || 0}
                  </div>
                </div>
              )}
              {props.todayText && (
                <div className="col-sm-3">
                  <Button
                    className="px-0 text-white"
                    color="link"
                    style={{ cursor: "auto" }}
                  >
                    {props.todayText}
                  </Button>
                  <br />
                  <div
                    className={`h2 font-weight-bold mb-0 text-white ${
                      data.today ? "values" : ""
                    }`}
                    onClick={data.today ? props.onClickToday : () => {}}
                  >
                    {data.today || 0}
                  </div>
                </div>
              )}
              {props.hoursText && (
                <div className="col-sm-3">
                  <Button
                    className="px-0 text-white"
                    color="link"
                    style={{ cursor: "auto" }}
                  >
                    {props.hoursText}
                  </Button>
                  <br />
                  <div
                    className={`h2 font-weight-bold mb-0 text-white ${
                      data.hours ? "values" : ""
                    }`}
                    onClick={data.hours ? props.onClickHours : () => {}}
                  >
                    {data.hours || 0}
                  </div>
                </div>
              )}
            </Row>
          </CardBody>
        )}
      </Card>
    </>
  );
}

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  apiType: PropTypes.string.isRequired,
  totalText: PropTypes.string,
  todayText: PropTypes.string,
  hoursText: PropTypes.string,
  onClickTotal: PropTypes.func,
  onClickToday: PropTypes.func,
  onClickHours: PropTypes.func,
};

InfoCard.defaultProps = {
  bgColor: "bg-gradient-primary",
  total: false,
  today: false,
  hours: false,
};

export default InfoCard;
