import React from 'react';
import moment from 'moment';

import { Media, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import ImagePreview from 'components/ImagePreview';
import ReadMoreAndLess from 'react-read-more-less';


const columns = (callBack) => [
  {
    dataField: 'action',
    isDummyField: true,
    text: 'Action',
    formatter: (cell, row) => {
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light action-button-div"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={e => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-h action-button" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right >
            <DropdownItem onClick={e => callBack('PUBLISH_POST', row)}>
              {row.published ? 'Inactive' : 'Active'}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  },
  {
    dataField: 'createdAt',
    text: 'Date time',
    sort: true,
    formatter: (cell) => {
      return (
        <div style={{ width: '100px', whiteSpace: 'pre-line' }} className="mb-0">{moment(cell).format('MMMM Do YYYY, h:mm a')}</div>
      )
    }
  },
  {
    dataField: 'published',
    text: 'Status',
    sort: true,
    formatter: (cell) => {
      return (
        <span style={{color: `${cell === true ? 'black' : 'red'}`,fontWeight:'600'}}>{cell === true ? 'ACTIVE' : 'INACTIVE'}</span>
      )
    }
  },
  {
    dataField: 'userId.name',
    text: 'User name'
  },
  {
    dataField: 'title',
    text: 'Title',
  },
  {
    dataField: 'subCategory[0].subCategoryName',
    text: 'Sub-Category',
  },
  {
    dataField: 'text',
    text: 'Description/Text',
    formatter: (cell,row) => {
      return (
        <div style={{ width: '200px', whiteSpace: 'pre-line' }}>

          {/* <ReadMoreAndLess
            className="read-more-content"
            charLimit={12}
            readMoreText=" more"
            readLessText=" less"
          >
            {cell}
          </ReadMoreAndLess> */}
          {cell.slice(0,25)} {cell.length > 25 ? <p><Button color="primary" size="sm" onClick = {e=>callBack('MODAL_SHOW', 'SHOW_READMORE', {comments:[{text:cell}]})}>Read More</Button ></p> : '' }
        </div>
      )
    }
  },
  {
    dataField: 'reportsINP',
    text: 'INP',
    sort: true,
    formatter: (cell) => {
      return (
        <Button
        style= {{backgroundColor:'#EF7B45',color:'#fff'}}
          size="sm"
          disabled={!cell?.length}
          onClick={e => e.preventDefault()}
        >
          {cell.length > 10 ? '10+' : cell.length}
        </Button >
      )
    }
  },
  {
    dataField: 'reportsINC',
    text: 'INC',
    sort: true,
    formatter: (cell) => {
      return (
        <Button
          color="success"
          size="sm"
          disabled={!cell?.length}
          onClick={e => e.preventDefault()}
        >
          {cell.length > 10 ? '10+' : cell.length}
        </Button >
      )
    }
  },
  {
    dataField: 'likes',
    text: 'Likes count',
    sort: true,
    formatter: (cell) => {
      return (
        <Button
          color="primary"
          size="sm"
          disabled={!cell?.length}
          onClick={e => e.preventDefault()}
        >
          {cell.length > 10 ? '10+' : cell.length}
        </Button >
      )
    }
  },
  {
    dataField: 'comments',
    text: 'comments count',
    sort: true,
    formatter: (cell, row) => {
      return (
        <Button
          color="primary"
          size="sm"
          disabled={!cell?.length}
          onClick={e => callBack('MODAL_SHOW', 'SHOW_COMMENT', row)}
        >
          {cell.length > 10 ? '10+' : cell.length}
        </Button>
      )
    }
  },
  {
    dataField: 'assets',
    text: 'assets',
    sort: true,
    formatter: (cell) => {
      const assets = cell.length > 0 ? cell.map(img => img.url) : [];

      return (
        <Media className="align-items-center">
          <ImagePreview
            type="group"
            imageStyle={{ height: 48 }}
            assets={assets}
          />
        </Media>
      )
    }
  },

];

export default columns;
