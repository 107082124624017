module.exports = {
  home: {
    taTm: {
      approvedTempleAdmins: {
        base: '/admin/approved-users',
        total:  {
          isAdmin: 'false'
        },
        monthly: {
          isAdmin: 'false'
        },
        weekly: {
          isAdmin: 'false'
        },
        today: {
          isAdmin: 'false'
        },
        hourly: {
          isAdmin: 'false'
        }
      },
      registeredTempleAdmins: {
        base: '/admin/temple-admin',
        total:  {
          isAdmin: true
        },
        monthly: {
          isAdmin: true
        },
        weekly: {
          isAdmin: true
        },
        today: {
          isAdmin: true
        },
        hourly: {
          isAdmin: true
        }
      },
      registeredTempleMembers: {
        base: '/admin/temple-members',
        total:  {
          isAdmin: false
        },
        monthly: {
          isAdmin: false
        },
        weekly: {
          isAdmin: false
        },
        today: {
          isAdmin: false
        },
        hourly: {
          isAdmin: false
        }
      }
    },
    usersRegistered: {
      devotees: {
        base: '/admin/users',
        total:  {
          'userType.religious': true,
          'userType.spiritual': false
        },
        monthly: {
          'userType.religious': true,
          'userType.spiritual': false
        },
        weekly: {
          'userType.religious': true,
          'userType.spiritual': false
        },
        today: {
          'userType.religious': true,
          'userType.spiritual': false
        },
        hourly: {
          'userType.religious': true,
          'userType.spiritual': false
        }
      },
      spiritual: {
        base: '/admin/users',
        total:  {
          'userType.religious': false,
          'userType.spiritual': true
        },
        monthly: {
          'userType.religious': false,
          'userType.spiritual': true
        },
        weekly: {
          'userType.religious': false,
          'userType.spiritual': true
        },
        today: {
          'userType.religious': false,
          'userType.spiritual': true
        },
        hourly: {
          'userType.religious': false,
          'userType.spiritual': true
        }
      },
      both: {
        base: '/admin/users',
        total:  {
          'userType.religious': true,
          'userType.spiritual': true
        },
        monthly: {
          'userType.religious': true,
          'userType.spiritual': true
        },
        weekly: {
          'userType.religious': true,
          'userType.spiritual': true
        },
        today: {
          'userType.religious': true,
          'userType.spiritual': true
        },
        hourly: {
          'userType.religious': true,
          'userType.spiritual': true
        }
      }
    },
    laguageUsers: {
      english: {
        base: '/admin/users',
        total:  {
          language: 'english'
        },
        monthly: {
          language: 'english'
        },
        weekly: {
          language: 'english'
        },
        today: {
          language: 'english'
        },
        hourly: {
          language: 'english'
        }
      },
      hindi: {
        base: '/admin/users',
        total:  {
          language: 'hindi'
        },
        monthly: {
          language: 'hindi'
        },
        weekly: {
          language: 'hindi'
        },
        today: {
          language: 'hindi'
        },
        hourly: {
          language: 'hindi'
        }
      },
      telugu: {
        base: '/admin/users',
        total:  {
          language: 'telugu'
        },
        monthly: {
          language: 'telugu'
        },
        weekly: {
          language: 'telugu'
        },
        today: {
          language: 'telugu'
        },
        hourly: {
          language: 'telugu'
        }
      }
    },
    updateEventsPosts: {
      updates: {
        base: '/admin/temple-updates',
        total:  {},
        monthly: {},
        weekly: {},
        today: {},
        hourly: {}
      },
      events: {
        base: '/admin/events',
        total:  {},
        monthly: {},
        weekly: {},
        today: {},
        hourly: {}
      },
      posts: {
        base: '/admin/posts',
        total:  {},
        monthly: {},
        weekly: {},
        today: {},
        hourly: {}
      }
    },
    templesFeedback: {
      temples: {
        base: '/admin/temples',
        total:  {},
        monthly: {},
        weekly: {},
        today: {},
        hourly: {}
      },
      feedback: {
        base: '/admin/feedbacks',
        total:  {},
        monthly: {},
        weekly: {},
        today: {},
        hourly: {}
      },
      phonegoogle: {
        base: '/admin/temples',
        total:  {
          contactNumber: { "$nin": [ null, "" ]  }
        },
        monthly: {
          contactNumber: { "$nin": [ null, "" ]  }
        },
        weekly: {
          contactNumber: { "$nin": [ null, "" ]  }
        },
        today: {
          contactNumber: { "$nin": [ null, "" ]  }
        },
        hourly: {
          contactNumber: { "$nin": [ null, "" ]  }
        }
      }
    }
  }
}