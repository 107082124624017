import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';

import {
  Badge, Media,
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from 'reactstrap';

import { LANGUAGE } from 'utils/enums/language';
import ImagePreview from 'components/ImagePreview';

const dateToAge = (date) => {
  var replaceStr = date.replace("-",",").replace("-",",")
  var splitSrt = replaceStr.split(",")
  var dateFromArray = ''+splitSrt[1]+'/'+splitSrt[0]+'/'+splitSrt[2]
  var dob = new Date(dateFromArray);
  //calculate month difference from current date in time
  var month_diff = Date.now() - dob.getTime();
  
  //convert the calculated difference in date format
  var age_dt = new Date(month_diff); 
  
  //extract year from date    
  var year = age_dt.getUTCFullYear();
  
  //now calculate the age of the user
  var age = Math.abs(year - 1970);
  if(!age){
    return ''
  }
  //display the calculated age
  else {
    return age
  }
  
}

const columns = (callBack) => [
  {
    dataField: 'action',
    isDummyField: true,
    text: 'Action',
    formatter: (cell, row) => {
      const userUrl = `/admin/user/${row.userId}`;
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light action-button-div"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={e => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-h action-button" />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-arrow"
            right
          >
            <DropdownItem>
              <Link to={userUrl}>View Details</Link>
            </DropdownItem>
            {/* <DropdownItem
              onClick={e => callBack('MODAL_SHOW', 'description', row)}
            >
              Delete
              </DropdownItem> */}
            {row.active &&
              <DropdownItem
                onClick={e => callBack('MODAL_SHOW', 'DELETE_MODAL', row)}
              >
                Inactivate
              </DropdownItem>}
            {!row.active &&
              <DropdownItem
                onClick={e => callBack('MODAL_SHOW', 'ENABLE_MODAL', row)}
              >
                Activate
              </DropdownItem>}
            {
              row.approved ? 
              <DropdownItem
                onClick={e => callBack('MODAL_SHOW', 'DELETE_MODAL_APPROVED', row)}
              >
                Disapproved
              </DropdownItem> : <DropdownItem
                onClick={e => callBack('MODAL_SHOW', 'ENABLE_MODAL_APPROVED', row)}
              >
                Approved
              </DropdownItem>
            }
          </DropdownMenu>
        </UncontrolledDropdown>
      )
    }
  },
  {
    dataField: 'active',
    text: 'Status',
    formatter: (cell) => {
      return (
        <>
          <span style={{color: `${cell === true ? 'black' : 'red'}`,fontWeight:'600'}}>{cell === true ? 'ACTIVE' : 'INACTIVE'}</span>
        </>
      )
    }
  },
  {
    dataField: 'approved',
    sort: true,
    text: 'Approved',
    formatter: cell => {
      return (
        <div style={{ whiteSpace: 'pre-line' }}>
          {cell === true ? <span className='badge badge-pill badge-success'>Yes</span> : <span className='badge badge-pill badge-danger'>No</span>}
        </div>
      )
    }
  },
  {
    dataField: 'createdAt',
    text: 'Date',
    sort: true,
    formatter: (cell) => {
      let registeredDate = cell ?  new Date(cell) : '';
      if (typeof registeredDate === 'object' && registeredDate !== null && 'toDateString' in registeredDate){
        registeredDate = registeredDate.toDateString()
        let dateFormat = registeredDate.split(" ")
        if(dateFormat.length === 4) {
          return (dateFormat[2] + '-' + dateFormat[1] + '-' + dateFormat[3])
        } else {
          return ''
        }
      } else {
        return ''
      }
      
    }
  },
  {
    dataField: 'language',
    text: 'Language',
    sort: true,
    formatter: (cell) => LANGUAGE[cell] || cell
  },
  {
    dataField: 'name',
    sort: true,
    text: 'Store Name',
    formatter: cell => {
      return (
        <div style={{whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
  {
    dataField: 'phone',
    sort: true,
    text: 'Store Phone Number',
    formatter: cell => {
      return (
        <div style={{whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
  {
    dataField: 'ownerName',
    sort: true,
    text: 'Owner Name',
    formatter: cell => {
      return (
        <div style={{whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
  {
    dataField: 'ownerPhone',
    sort: true,
    text: 'Owner Phone Number',
    formatter: cell => {
      return (
        <div style={{whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
  {
    dataField: 'user[0].name',
    text: 'Name',
    classes: 'pt-0',
    sort: true,
  },
  {
    dataField: 'user[0].gender',
    text: 'Gender',
    classes: 'pt-0',
    sort: true,
  },
  // {
  //   dataField: 'addtionalmages',
  //   text: 'Images',
  //   classes: 'pt-0',
  //   sort: true,
  //   formatter: (cell, row) => {
  //     return (
  //       <>
  //         <Media className="align-items-center">
  //           <ImagePreview
  //             imageStyle={{ height: 48 }}
  //             image={cell || require('assets/img/theme/bootstrap.jpg')}
  //           />
  //         </Media>
  //       </>
  //     )
  //   }
  // },
  {
    dataField: 'user[0].mobileNumber',
    text: 'Mobile Number',
  },
  {
    dataField: 'user[0].email',
    text: 'Email',
    sort: true,
  },
  // {
  //   dataField: 'location',
  //   text: 'Location',
  //   sort: true,
  //   formatter: cell => {
  //     return (
  //       <div style={{ width: '240px', whiteSpace: 'pre-line' }}>
  //         {cell}
  //       </div>
  //     )
  //   }
  // },
  {
    dataField: 'city',
    sort: true,
    text: 'City',
    formatter: cell => {
      return (
        <div style={{ width: '240px', whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
  {
    dataField: 'state',
    sort: true,
    text: 'State',
    formatter: cell => {
      return (
        <div style={{ width: '240px', whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
  {
    dataField: 'country',
    sort: true,
    text: 'Country',
    formatter: cell => {
      return (
        <div style={{whiteSpace: 'pre-line' }}>
          {cell}
        </div>
      )
    }
  },
];

export default columns;