import React, { Component } from 'react'
import {
  Row, Card, CardHeader, CardFooter,
} from 'reactstrap';

import { Scrollbars } from 'react-custom-scrollbars';
import paginationFactory,
{
  PaginationProvider, PaginationListStandalone
} from "react-bootstrap-table2-paginator";

import BootstrapTable from "react-bootstrap-table-next";

import columns from './column';

export class PureAgentTables extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      page: 1,
      sizePerPage: 50,
      sortField: undefined,
      sortOrder: null,
    };
  }

  handleTableChange = (type, { page, sizePerPage, ...rest }) => {

    if (type === 'pagination') {
      this.setState({
        sizePerPage,
        page
      }, () => this.props.fetchTableRecord(page, sizePerPage, this.state.sortField, this.state.sortOrder))
    }

    if (type === 'sort') {
      this.setState({
        sortOrder: rest.sortOrder,
        sortField: rest.sortField
      }, () => this.props.fetchTableRecord(this.state.page, this.state.sizePerPage, rest.sortField, rest.sortOrder))
    }
  }

  render() {
    const { records = [], total = 0 } = this.props;
    const paginationOption = {
      custom: true,
      totalSize: total,
      page: this.state.page,
      sizePerPage: this.state.sizePerPage,
    }
    const pagination = paginationFactory(paginationOption);
    return (
      <>
        <Row className="mt-4">
          <div className="col">
            <Card className="shadow">
              <CardHeader>
                <h3 className="mb-0">{this.props.headerTitle}</h3>
              </CardHeader>
              <PaginationProvider
                pagination={pagination}
              >
                {({
                  paginationProps,
                  paginationTableProps
                }) => (
                    <>
                      <div className="py-4 table-responsive">
                        <Scrollbars style={{ height: '50vh' }}>
                          <BootstrapTable
                            remote
                            keyField='_id'
                            data={records}
                            columns={columns(this.props.tableCallBack)}
                            bootstrap4={true}
                            bordered={false}
                            onTableChange={this.handleTableChange}
                            noDataIndication="no records"
                            {...paginationTableProps}
                          />
                        </Scrollbars>
                      </div>

                      <CardFooter>
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </CardFooter>
                    </>
                  )
                }
              </PaginationProvider>
            </Card>
          </div>
        </Row>
      </>
    )
  }
}

export default PureAgentTables
